.divider_bg {
    position: relative;
    z-index: 1;
    width: calc(100% - 260px);
    height: $_80px;
    @include margin-top ($_95px);
}
.slide_content {
    .slide_content_wrapper{
        position: relative;
        overflow: hidden;
        height: auto;
        transition: all 0.5s ease-in-out;
        margin-bottom: $_0px;
    
        .slide_content_inner{
            position: relative;
            padding: 20px 30px 20px 30px;
            z-index: 1;
            transition: all 0.5s ease-in-out;  
            .meta{
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }
    }
}
/*-- Theme Slider --*/
.theme_slider{
    position: relative;
    @include padding($_190px $_0px $_0px $_0px);
    .swiper {
        @include padding-bottom($_60px);
    }
    .swiper-slide {
        @include padding-right($_155px);
        .slider {
            background-repeat: repeat;
            background-size: cover;
            background-position: left center;
            // height: calc(100vh - 70px);
            transition: all 0.5s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: end;
            padding: 100px 0px;
            .slide_content {
                max-width: 310px;
                @include margin-right(-120px);
                transform-style: preserve-3d;
                .slide_content_wrapper {
                    transform: scale(0.8);
                    transition: all 0.5s ease-in-out;
                    .slide_content_inner{
                        .meta {
                            .category {
                                font-size: $_11px;
                                font-weight: 600;
                            }
                        }
                        h4 {
                            position: relative;
                            @include rfs(100px, padding-top);
                            @include rfs(100px, margin-bottom);
                            &::before {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 1px;
                                @include rfs(100px, height);
                                background-color: $gray-500;
                            }
                        }
			p{
				font-size: $_22px;
                                font-weight: 600;
			}
                    }
                }
            }
        }
    }
    
    .swiper-slide-active {
        .slider{
            .slide_content{
                .slide_content_wrapper{
                    transform: scale(1);
                    transition-delay: 0.5s;
                }
            }
        }
    }
}

// Slider Second Style
.theme_slider_2 {
    position: relative;
    .swiper-slide {
        .slider {
            background-repeat: repeat;
            background-size: cover;
            background-position: left center;
            width: 100%;
            height: 100%;
            transition: all 0.5s ease-in-out;
            display: flex;
            justify-content: start;

            .slide_content {
                max-width: 100%; 
                transition: all 0.5s ease-in-out;
                .slide_content_wrapper {
                    display: flex;
                    align-items: center;
                    @include padding($_40px $_40px);
                    transition: all 0.5s ease-in-out;
                    .slide_content_inner{
                        text-align: center;
                        .meta {
                            font-size: $_14px;
                            font-weight: 500;
                            text-transform: uppercase;
                            letter-spacing: $_3px;
                            opacity: 0;
                            visibility: hidden;
                            transform: translateY(-20px);
                            transition: all 0.5s ease-in-out;
                        }
                        h4 {
                            position: relative;
                            font-family: $font-family-second;
                            @include font-size($_38px);
                            font-weight: $font-weight-normal;
                            @include rfs(250px, padding-top);
                            @include rfs(100px, margin-bottom);
                            opacity: 0;
                            visibility: hidden;
                            transform: skew(50deg, 0deg);
                            transition: all 0.5s ease-in-out;
                            &::before {
                                content: '';
                                position: absolute;
                                left: 50%;
                                @include rfs(50px, top);
                                width: 1px;
                                @include rfs(140px, height);
                                background-color: $gray-500;
                                transform: scale(0);
                                transform-origin: center;
                                transition: all 0.5s ease-in-out;
                            }
                        }
                        p{
                                font-size: $_22px;
                                font-weight: 600;
                        }
                    }
                }
                .details_link {                  
                    @include font-size($_14px);
                    @include rfs($_16px, line-height);
                    opacity: 0;
                    visibility: hidden;
                    transform: translateY(20px);  
                    transition: all 0.5s ease-in-out;
                    a {
                        justify-content: center;
                    }
                }
            }
        }
        &:nth-child(odd) {
            .slider {
                justify-content: end;
            }
        }
        &-active {
            .slider{
                .slide_content{
                    max-width: 50%;
                    .slide_content_wrapper {
                        .slide_content_inner {
                            .meta{
                                opacity: 1;
                                visibility: visible;
                                transform: translateY(0px);
                                transition-delay: 1.5s;
                            }
                            h4{
                                opacity: 1;
                                visibility: visible;
                                transform: skew(0deg, 0deg);
                                transition-delay: 1s;
                                &::before {
                                    transform: scale(1);
                                }
                            }
                            .details_link{
                                opacity: 1;
                                visibility: visible;
                                transform: translateY(0px);
                                transition-delay: 1.5s;
                            }
                        }
                    }
                }
            }
        }
    }
    .swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .swiper-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
        width: 16px;
    }
}

// Slider Third Style
.bg-dark {
    .theme_slider_3 {
        video{
            filter: brightness(40%);
        }
    }
}
.bg-dark {
    &.bg-white {
        .theme_slider_3 {
            video{
                filter: brightness(250%);
            }
        }
   }
    
}


.theme_slider_3 {
    position: relative;
    .swiper-slide {
        .slider {
            background-repeat: norepeat;
            background-size: fit;
            background-position: left center;
            width: 100%;
            height: 100%;
            transition: all 0.5s ease-in-out;
            display: flex;
            justify-content: start;
            align-items: center;
            @include padding-top(300px);
            @include padding-bottom(400px);
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.5);
            }

            .slide_content {
                max-width: 100%; 
                transition: all 0.5s ease-in-out;
                .slide_content_wrapper {
                    display: flex;
                    align-items: center;
                    @include padding($_0px $_0px);
                    transition: all 0.5s ease-in-out;
                    .slide_content_inner{
                        text-align: left;
                        .meta {
                            font-size: $_14px;
                            font-weight: 500;
                            text-transform: uppercase;
                            letter-spacing: $_3px;
                            opacity: 0;
                            visibility: hidden;
                            transform: translateY(-20px);
                            transition: all 0.5s ease-in-out;
                        }
                        h4 {
                            position: relative;
                            font-family: $font-family-second;
                            @include font-size(50px);
                            @include rfs(50px, line-height);
                            font-weight: 600;
                            @include rfs(25px, margin-bottom);
                            opacity: 0;
                            visibility: hidden;
                            transform: skew(50deg, 0deg);
                            transition: all 0.5s ease-in-out;
                        }
                        p{
                                font-size: $_25px;
                                font-weight: 300;
				                line-height: $_35px; 
                    }

			
                    }
                }

                .details_link {                  
                    @include font-size($_24px);
                    @include rfs($_32px, line-height);
                    font-weight: $font-weight-normal;
                    text-transform: none;
                    opacity: 0;
                    visibility: hidden;
                    transform: translateY(20px);  
                    transition: all 0.5s ease-in-out;
                    a {
                        color: $white;
                        .link_text {
                            cursor: auto;
                        }
                        .link_icon {
                            color: $olive;
                        }
                        
                    }
                }
            }
            .card {
                min-height: 15em;
                background-color: transparent;
                .card-img-top{
	               max-width: 5em;
    		       margin: auto;
    		       padding-top: 2em;	
			     }
			     .card-title{
			          text-align: center;
			     }
            }     
        }
        &:nth-child(odd) {
            .slider {
                justify-content: end;
            }
        }
        &-active {
            .slider{
                .slide_content{
                    .slide_content_wrapper {
                        .slide_content_inner {
                            .meta{
                                opacity: 1;
                                visibility: visible;
                                transform: translateY(0px);
                                transition-delay: 1.5s;
                            }
                            h4{
                                opacity: 1;
                                visibility: visible;
                                transform: skew(0deg, 0deg);
                                transition-delay: 1s;
                                &::before {
                                    transform: scale(1);
                                }
                            }
                            .details_link{
                                opacity: 1;
                                visibility: visible;
                                transform: translateY(0px);
                                transition-delay: 1.5s;
                            }
                        }
                    }
                }
            }
        }
    }

    .swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .swiper-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
        width: 16px;
    }
    .swiper-button-prev, .swiper-rtl .swiper-button-next {
        @include rfs(70px, left);
    }
    .swiper-button-next, .swiper-rtl .swiper-button-prev {
        @include rfs(70px, right);
    }
    .swiper-button-next:after, .swiper-button-prev:after {
        content: none;
    }
    .details_link{
        &.swiper-button-prev,
        &.swiper-button-next {
            a {
                .link_icon {
                    .line {
                        width: $_90px;
                    }
                    .circle {
                        width: $_60px;
                        height: $_60px;
                    }
                }
            }
        }
        &.l-dir a .link_icon .dot {
            border-top: 9px solid transparent;
            border-bottom: 9px solid transparent;
            border-right: 18px solid #a6a182;
        }
        &.r-dir a .link_icon .dot {
            border-top: 9px solid transparent;
            border-bottom: 9px solid transparent;
            border-left: 18px solid #a6a182;
        }
    }
}

// Slider Fourth Style
.theme_slider_4 {
    position: relative;
    .swiper-slide {
        .slider {
            background-repeat: repeat;
            background-size: cover;
            background-position: left center;
            width: 100%;
            height: 100%;
            transition: all 0.5s ease-in-out;
            display: flex;
            justify-content: start;
            align-items: center;
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.5);
            }

            .slide_content { 
                transition: all 0.5s ease-in-out;
                @include padding(0px 50px);
                .slide_content_wrapper {
                    display: flex;
                    align-items: center;
                    @include padding($_0px $_0px);
                    transition: all 0.5s ease-in-out;
                    .slide_content_inner{
                        max-width: 80%;
                        @include padding(330px 0px 300px 0px);
                        text-align: left;
                        .meta {
                            font-size: $_14px;
                            font-weight: 500;
                            text-transform: uppercase;
                            letter-spacing: $_3px;
                            opacity: 0;
                            visibility: hidden;
                            transform: translateY(-20px);
                            transition: all 0.5s ease-in-out;
                        }
                        h4 {
                            position: relative;
                            font-family: $font-family-second;
                            @include font-size(105px);
                            @include rfs(92px, line-height);
                            font-weight: $font-weight-normal;
                            @include rfs(25px, margin-bottom);
                            opacity: 0;
                            visibility: hidden;
                            transform: skew(50deg, 0deg);
                            transition: all 0.5s ease-in-out;
                        }
                    }
                    .mask-content {
                        position: absolute;
                        right: 0;
                        @include font-size(650px);
                        @include rfs(490px, line-height);
                        font-weight: $font-weight-bold;
                        text-transform: uppercase;
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                        color: $white;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        opacity: 0;
                        visibility: hidden;
                        transform: scale(0);
                        transition: all 0.5s ease-in-out;
                    }
                }
                .details_link {                    
                    @include font-size($_24px);
                    @include rfs($_32px, line-height);
                    font-weight: $font-weight-normal;
                    text-transform: capitalize;
                    opacity: 0;
                    visibility: hidden;
                    transform: translateY(20px);  
                    transition: all 0.5s ease-in-out;
                    a {
                        color: $white;
                        .link_text {
                            cursor: auto;
                        }
                        .link_icon {
                            color: $olive;
                        }
                        
                    }
                }
            }
        }
        &:nth-child(odd) {
            .slider {
                justify-content: end;
            }
        }
        &-active {
            .slider{
                .slide_content{
                    .slide_content_wrapper {
                        .slide_content_inner {
                            .meta{
                                opacity: 1;
                                visibility: visible;
                                transform: translateY(0px);
                                transition-delay: 1.5s;
                            }
                            h4{
                                opacity: 1;
                                visibility: visible;
                                transform: skew(0deg, 0deg);
                                transition-delay: 1s;
                                &::before {
                                    transform: scale(1);
                                }
                            }
                            .details_link{
                                opacity: 1;
                                visibility: visible;
                                transform: translateY(0px);
                                transition-delay: 1.5s;
                            }
                        }
                        .mask-content {
                            opacity: 1;
                            visibility: visible;
                            transform: scale(1);
                            transition-delay: 1s;
                        }
                    }
                }
            }
        }
    }
    .swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .swiper-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
        width: 16px;
    }
    .swiper-button-prev, .swiper-rtl .swiper-button-next {
        @include rfs(70px, left);
    }
    .swiper-button-next, .swiper-rtl .swiper-button-prev {
        @include rfs(70px, right);
    }
    .swiper-button-next:after, .swiper-button-prev:after {
        content: none;
    }
    .details_link{
        &.swiper-button-prev,
        &.swiper-button-next {
            a {
                .link_icon {
                    .line {
                        width: $_90px;
                    }
                    .circle {
                        width: $_60px;
                        height: $_60px;
                    }
                }
            }
        }
        &.l-dir a .link_icon .dot {
            border-top: 9px solid transparent;
            border-bottom: 9px solid transparent;
            border-right: 18px solid #a6a182;
        }
        &.r-dir a .link_icon .dot {
            border-top: 9px solid transparent;
            border-bottom: 9px solid transparent;
            border-left: 18px solid #a6a182;
        }
    }

    // Swiper Pagination
    .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: 90px;
    }
}

// Slider Fifth Style
.theme_slider_5 {
    position: relative;
    z-index: 1;
    .slider {
        background-repeat: repeat;
        background-size: cover;
        background-position: left center;
        width: 100%;
        height: 100%;
        transition: all 0.5s ease-in-out;
        display: flex;
        justify-content: start;
        align-items: center;

        .slide_content {
            position: relative;
            z-index: 1;
            max-width: 85%;
            padding: 150px 0px;
            margin: 0 auto;
            text-align: center;
            transition: all 0.5s ease-in-out;
            .slide_content_inner {
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: calc(100% + 40px);
                    width: 1px;
                    height: 85px;
                    background-color: #d9d9d9;
                    transform: rotate(20deg);
                    transform-origin: top;
                    animation: lineBlink 10s linear infinite;
                }

            }
            .shape {
                position: relative;
                width: 130px;
                height: 135px;
                margin: 0 auto;
                z-index: 1;
                .star1 {
                    position: absolute;
                    left: 0;
                    top: 12px;
                    animation: blink 3s linear infinite;
                    &-dark {
                        position: absolute;
                        left: 0;
                        top: 12px;
                        animation: blink 3s linear infinite;
                        display: none;
                    }
                }
                .star2 {
                    position: absolute;
                    right: 0;
                    top: 0;
                    animation: blink 3.5s linear infinite;
                    &-dark {
                        position: absolute;
                        right: 0;
                        top: 0;
                        animation: blink 3.5s linear infinite;
                        display: none;
                    }
                }
                .star3 {
                    animation: blink 5s linear infinite;
                    &-dark {
                        animation: blink 5s linear infinite;
                        display: none;
                        margin: 0 auto;
                    }
                }
            }
            @keyframes blink {
                0% {
                    transform: scale(1);
                }
                50% {
                    transform: scale(0.6);
                    opacity: 0;
                }
                100% {
                    transform: scale(1);
                }
            }
            
            @keyframes lineBlink {
                0% {
                    height: 85px;
                }
                50% {
                    height: 30px;
                }
                100% {
                    height: 85px;
                }
            }
            h4 {
                position: relative;
                font-family: $font-family-second;
                @include font-size(70px);
                @include rfs(80px, line-height);
                font-weight: $font-weight-normal;
                @include rfs(25px, margin-top);
                @include rfs(25px, margin-bottom);
                transition: all 0.5s ease-in-out;
            }
            .details_link {              
                font-family: $font-family-second;    
                @include font-size($_24px);
                @include rfs($_32px, line-height);
                font-weight: $font-weight-normal;
                text-transform: capitalize;
                transition: all 0.5s ease-in-out;
                a {
                    color: $white;
                    justify-content: center;
                    .link_text {
                        cursor: auto;
                    }
                    .link_icon {
                        color: $olive;
                    }
                    
                }
            }
        }
    }
}

// Slider Sixth Style
.theme_slider_6 {
    position: relative;
    padding: 130px 0px;
    .slider {
        .slide_content {
            transition: all 0.5s ease-in-out;
            .slide_content_wrapper {
                display: flex;
                align-items: center;
                @include padding($_0px $_0px);
                transition: all 0.5s ease-in-out;
                overflow: visible;
                .slide_content_inner{
                    text-align: center;
                    @include rfs(800px, max-width);
                    margin: 50px auto;
                    .meta {
                        font-size: $_14px;
                        font-weight: 500;
                        text-transform: uppercase;
                        letter-spacing: $_3px;
                    }
                    h4 {
                        position: relative;
                        font-family: $font-family-second;
                        @include font-size(105px);
                        @include rfs(92px, line-height);
                        font-weight: $font-weight-normal;
                        @include rfs(25px, margin-bottom);
                    }
                }
            }
            .details_link {                  
                @include font-size($_24px);
                @include rfs($_32px, line-height);
                font-weight: $font-weight-normal;
                text-transform: capitalize;
                a {
                    display: block;
                    color: $white;
                    .link_text {
                        cursor: auto;
                    }
                    .link_icon {
                        color: $olive;
                    }
                    
                }
            }
        }
    }
    .swiper-slide {
        .slider {
            @include rfs(285px, max-width);
            height: auto;
            margin: 0 auto;
            transition: all 0.5s ease-in-out;
            a {
                display: block;
                filter: brightness(40%);
                &:hover {
                    filter: brightness(1);
                }
            }
        }
    }
    .swiper_theme_slider_6 {
        .swiper-slide {
            &.swiper-slide-visible {
                .slider {
                    margin-top: 40px;
                }
                &.swiper-slide-next {
                    .slider {
                        margin-top: 0px;
                    }
                }
            }
        }
        &.second_row {
            padding-top: 40px;
            .swiper-slide {
                &.swiper-slide-visible {
                    .slider {
                        margin-top: -40px;
                    }
                    &.swiper-slide-next {
                        .slider {
                            margin-top: 0px;
                        }
                    }
                }
            }
        }
    }
    .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: 55px;
    }
    .swiper-button-prev, .swiper-rtl .swiper-button-next {
        @include rfs(70px, left);
    }
    .swiper-button-next, .swiper-rtl .swiper-button-prev {
        @include rfs(70px, right);
    }
    .swiper-button-next:after, .swiper-button-prev:after {
        content: none;
    }
    .details_link{
        &.swiper-button-prev,
        &.swiper-button-next {
            a {
                .link_icon {
                    .line {
                        width: $_90px;
                    }
                    .circle {
                        width: $_60px;
                        height: $_60px;
                    }
                }
            }
        }
        &.l-dir a .link_icon .dot {
            border-top: 9px solid transparent;
            border-bottom: 9px solid transparent;
            border-right: 18px solid #a6a182;
        }
        &.r-dir a .link_icon .dot {
            border-top: 9px solid transparent;
            border-bottom: 9px solid transparent;
            border-left: 18px solid #a6a182;
        }
    }
}

// Swiper Pagination & Navigation Customization
.swiper-horizontal>.swiper-pagination-bullets, 
.swiper-pagination-bullets.swiper-pagination-horizontal, 
.swiper-pagination-custom, 
.swiper-pagination-fraction {
    bottom: -10px;
}
.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, 
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: $_0px $_15px;
}
.swiper-pagination-bullet {
    width: $_11px;
    height: $_11px;
    background-color: $gray-600;
    opacity: 1;
    &.swiper-pagination-bullet-active {
        background-color: $olive;
    }
}


.swiper-navigation{
    --button-width: 44px;
    --button-height: 44px;
    position: absolute;
    right: 0px;
    bottom: 0;
    width: var(--button-width);
    height: calc(var(--button-height) * 2);
    overflow: hidden;
    z-index: 9;
    transition: all 1s ease-in-out;
    .swiper-button-next, .swiper-button-prev {
        font-size: $_20px;
        color: $black;
        background: $white;
        position: absolute;
        top: 100%;
        width: var(--button-width);
        height: var(--button-height);
        margin-top: 0px;
        transition: all 0.5s ease-in-out;
        &:hover {
            background: $olive;
            color: $white;
        }
    }
    .swiper-button-prev, .swiper-rtl .swiper-button-next {
        left: auto;
        right: 0;
    }
    .swiper-button-next, .swiper-rtl .swiper-button-prev {
        right: 0;
        left: auto;
        margin-top: 1px;
    }
    .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
        opacity: 1;
        background: rgba($white, 0.65);
    }
    .swiper-button-next:after, .swiper-button-prev:after {
        font-size: 20px;
        display: none;
    }
}
.swiper:hover {
    .swiper-navigation {
        .swiper-button-prev {
            top: 0;
        }
        .swiper-button-next {
            top: var(--button-height);
        }
    }
}

.mt-lg-minus {
    @include margin-top(-170px);
    z-index: 1;
    .gutter-90 {
        &:nth-child(1),
        &:nth-child(2) {
            .grid-item {
                padding-top: 0;
            }
        }
    }
}

.rotate-logo {    
    position: absolute;
    left: 50%;
    bottom: 88px;
    display: inline-block;
    z-index: 2;
    .rotate-bg {
        display: inline-block;
    }
    .rotate-text {
        position: absolute;
        left: 8px;
        top: 8px;
        transform-origin: center;
        animation: rotateLogo 20s linear infinite;
    }
}

@keyframes rotateLogo {
    100% {
        transform: rotate(360deg);
    }
}


video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.overlay {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
