// px to rem convert
$_0px:	    0rem;

$_1px:	    0.0625rem;
$_2px:	    0.125rem;
$_3px:	    0.1875rem;
$_4px:	    0.25rem;
$_5px:	    0.3125rem;
$_6px:	    0.375rem;
$_7px:	    0.4375rem;
$_8px:	    0.5rem;
$_9px:	    0.5625rem;
$_10px:	    0.625rem;

$_11px:	    0.6875rem;
$_12px:	    0.75rem;
$_13px:	    0.8125rem;
$_14px:	    0.875rem;
$_15px:	    0.9375rem;
$_16px:	    1rem;
$_17px:	    1.0625rem;
$_18px:	    1.125rem;
$_19px:	    1.1875rem;
$_20px:	    1.25rem;

$_21px:	    1.3125rem;
$_22px:	    1.375rem;
$_23px:	    1.4375rem;
$_24px:	    1.5rem;
$_25px:	    1.5625rem;
$_26px:	    1.625rem;
$_27px:	    1.6875rem;
$_28px:	    1.75rem;
$_29px:	    1.8125rem;
$_30px:	    1.875rem;

$_31px:	    1.9375rem;
$_32px:	    2rem;
$_33px:	    2.0625rem;
$_34px:	    2.125rem;
$_35px:	    2.1875rem;
$_36px:	    2.25rem;
$_37px:	    2.3125rem;
$_38px:	    2.375rem;
$_39px:	    2.4375rem;
$_40px:	    2.5rem;

$_41px:	    2.5625rem;
$_42px:	    2.625rem;
$_43px:	    2.6875rem;
$_44px:	    2.75rem;
$_45px:	    2.8125rem;
$_46px:	    2.875rem;
$_47px:	    2.9375rem;
$_48px:	    3rem;
$_49px:	    3.0625rem;
$_50px:	    3.125rem;

$_51px:	    3.1875rem;
$_52px:	    3.25rem;
$_53px:	    3.3125rem;
$_54px:	    3.375rem;
$_55px:	    3.4375rem;
$_56px:	    3.5rem;
$_57px:	    3.5625rem;
$_58px:	    3.625rem;
$_59px:	    3.6875rem;
$_60px:	    3.75rem;

$_61px:	    3.8125rem;
$_62px:	    3.875rem;
$_63px:	    3.9375rem;
$_64px:	    4rem;
$_65px:	    4.0625rem;
$_66px:	    4.125rem;
$_67px:	    4.1875rem;
$_68px:	    4.25rem;
$_69px:	    4.3125rem;
$_70px:	    4.375rem;

$_71px:	    4.4375rem;
$_72px:	    4.5rem;
$_73px:	    4.5625rem;
$_74px:	    4.625rem;
$_75px:	    4.6875rem;
$_76px:	    4.75rem;
$_77px:	    4.8125rem;
$_78px:	    4.875rem;
$_79px:	    4.9375rem;
$_80px:	    5rem;

$_81px:	    5.063rem;
$_82px:	    5.125rem;
$_83px:	    5.188rem;
$_84px:	    5.25rem;
$_85px:	    5.313rem;
$_86px:	    5.375rem;
$_87px:	    5.438rem;
$_88px:	    5.5rem;
$_89px:	    5.5625rem;
$_90px:	    5.625rem;

$_91px:	    5.6875rem;
$_92px:	    5.75rem;
$_93px:	    5.8125rem;
$_94px:	    5.875rem;
$_95px:	    5.9375rem;
$_96px:	    6rem;
$_97px:	    6.0625rem;
$_98px:	    6.125rem;
$_99px:	    6.1875rem;
$_100px:	6.25rem;


$_128px:	    8rem;
$_130px:	    8.25rem;
$_140px:	    8.75rem;
$_150px:	    9.375rem;
$_155px:	    9.688rem;
$_175px:	    10.9375rem;
$_176px:	    11rem;
$_190px:	    11.875rem;
$_192px:	    12rem;

$_200px:	    12.5rem;
$_210px:	    13.125rem;
$_240px:	    15rem;
$_280px:	    17.5rem;
$_291px:        18.188rem;

$_320px:	    20rem;
$_370px:	    23.125rem;

$_400px:	    25rem;

$_500px:	    31.25rem;
$_592px:	    37rem;

$_600px:	    37.5rem;
$_680px:	    42.5rem;