/*---------- Button Section ------------*/
.btn_group{
    position: relative;
    z-index: 1;
    display: block;
}

.btn{
    position: relative;
    width: auto;
    display: inline-block;
    font-family: $font-family-base;
    @include font-size($_12px);
    line-height: $_24px;
    font-weight: $font-weight-bold;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    outline: none;
    @include padding($_12px $_47px);
    background-color: $black;
    color: $white;
    border: none;
    border-radius: $_0px;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: all 0.5s ease-in-out;
    &:before {    
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 9px;
        left: 9px;
        background-image: url(/assets/img/btn-texture-black.png);
        border: 1px solid $black;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        z-index: -1;
        transition: left 0.3s ease-out 0.3s, top 0.3s ease-out 0.3s;
    }
    &:hover {
        background-color: rgba($black, 0.6);
        color: $white;
        border-color: transparent;
        &:before {
            top: 0;
            left: 0;
            //transition: top 0.15s ease-in 0.3s, left 0.3s ease-in 0.5s;
        }
    }
    &.white {
        background-color: $white;
        color: $black;
        &:before {    
            background-image: url(/assets/img/btn-texture-white.png);
            border-color: $white;
        }
        &:hover {
            background-color: rgba($white, 0.6);
            color: $black;
        }
    }    
    &.gray {
        background-color: $gray-500;
        color: $white;
        &:before {    
            background-image: url(/assets/img/btn-texture-gray.png);
            border-color: $gray-500;
        }
        &:hover {
            background-color: rgba($gray-500, 0.6);
            color: $white;
        }
    }
    &.olive {
        background-color: $olive;
        color: $white;
        &:before {    
            background-image: url(/assets/img/btn-texture-olive.png);
            border-color: $olive;
        }
        &:hover {
            background-color: rgba($olive, 0.6);
            color: $white;
        }
    }
}

// Second Effect
.btn_2 {
    position: relative;
    width: auto;
    display: inline-block;
    font-family: $font-family-base;
    @include font-size($_12px);
    line-height: $_24px;
    font-weight: $font-weight-bold;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    outline: none;
    @include padding($_12px $_47px);
    background-color: $black;
    color: $white;
    border: none;
    border-radius: $_0px;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;    
    box-sizing: border-box;
    &::before,
    &::after {
        box-sizing: inherit;
        position: absolute;
        content: '';
        border: 1px solid transparent;
        width: 0;
        height: 0;
    }
    &::before {
        top: 0;
        left: 0;
    }
    &::after {
        bottom: 0;
        right: 0;
    }

    &:hover {
        &::before,
        &::after {
            width: 100%;
            height: 100%;
        }
        &::before {
            border-top-color: $olive;
            border-right-color: $olive;
            transition: width 0.3s ease-out, height 0.3s ease-out 0.3s;
        }
        &::after {
            border-bottom-color: $olive;
            border-left-color: $olive;
            transition: border-color 0s ease-out 0.6s, width 0.3s ease-out 0.6s, height 0.3s ease-out 1s;
        }
    }
}



// External Link
.details_link {
    font-size: $_11px; 
    font-weight: $font-weight-semibold;
    line-height: $_22px;
    text-transform: uppercase;
    color: $olive; 
    margin-bottom: 0px;
    transition-delay: 0.9s;
    
    a {
        color: $olive;
        display: flex;
        align-items: center;
        .link_text {
            margin-right: $_30px;
        }
        .link_icon{
            position: relative;
            display: inline-flex;
            align-items: center;
            .line {
                position: absolute;
                right: 50%;
                top: 49.4%;
                width: $_35px;
                height: $_1px;
                display: inline-block;
                background-color: $olive;
                z-index: 1;
                transform-origin: right;
                transition: all 0.5s ease-in-out;
            }
            .circle {
                position: relative;
                width: $_36px;
                height: $_36px;
                border-radius: 100%;
                display: inline-block;
                border: 1px solid $olive;
            }
            .dot {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: $_6px;
                height: $_6px;
                border-radius: 100%;
                display: inline-block;
                background-color: $olive;
            }
        }      
        &:hover {
            .link_icon {
                .line {
                    animation: scaleAnim1 2s infinite linear;
                }
                .circle {            
                    animation: rotateAnim 7s infinite linear;
                    border-style: dashed;
                }
                .dot {
                    animation: scaleAnim2 2s infinite ease-in-out;
                }
            }
        }
    }
    &.l-dir {
        a {
            .link_icon{
                .line {
                    right: auto;
                    left: calc(50% + 5px);
                    transform-origin: left;
                }
                .dot {
                    width: 0px;
                    height: 0px;
                    border-radius: 0;
                    display: inline-block;
                    background-color: transparent;
                    border-top: 5px solid transparent;
                    border-bottom: 5px solid transparent;
                    border-left: 0px solid transparent;
                    border-right: 10px solid $olive;
                    animation: none;
                }
            }
        }
    }
    &.r-dir {
        a {
            .link_icon{
                .line {
                    left: auto;
                    right: calc(50% + 5px);
                    transform-origin: right;
                }
                .dot {
                    width: 0px;
                    height: 0px;
                    border-radius: 0;
                    display: inline-block;
                    background-color: transparent;
                    border-top: 5px solid transparent;
                    border-bottom: 5px solid transparent;
                    border-left: 10px solid $olive;
                    border-right: 0px solid transparent;
                    animation: none;
                }
            }
        }
    }
}

@keyframes scaleAnim1 {
    0% {
        transform: scaleX(1);
    }
    50% {
        transform: scaleX(0);
    }
    100% {
        transform: scaleX(1);
    }
}
@keyframes rotateAnim {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes scaleAnim2 {
    0% {
        transform: translate(-50%, -50%) scale(1);
    }
    50% {
        transform: translate(-50%, -50%) scale(0.8);
        background: $white;
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}