@charset "UTF-8";
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
#01  Fonts Import
#02  Bootstrap Import
#03  Plugins
#04  Other CSS
    4-1. Button
    4-2. Menu
    4-3. Slider
    4-4. Funfact
    4-5. Projects
    4-6. Testimonial
    4-7. Sidebar
    4-8. Blog
    4-9. Footer
    4-10. ABout
    4-11. Iconbox
    4-12. Team
    4-13. Shop
    4-14. Cart
    4-15. Checkout
    4-16. Global
    4-17. Responsive
    4-18. Light Color File
-----------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
@import url("/assets/fonts/bootstrap-icons/font/bootstrap-icons.css");
@import url("/assets/js/plugins/menu/ma5-menu.css");
@import url("/assets/js/plugins/select2/css/select2.css");
@import url("/assets/js/plugins/jquery_ui/style.css");
@import url("/assets/js/plugins/swiper/swiper-bundle.min.css");
@import url("/assets/js/plugins/cursor-effect/cursor-effect.css");
@import url("/assets/js/plugins/aos/aos.css");
@import url("/assets/js/plugins/fancybox/jquery.fancybox.css");
@font-face {
  font-family: "saol_displayregular";
  src: url("/assets/fonts/SaolDisplay/saoldisplay-regular-webfont.woff2") format("woff2"), url("/assets/fonts/SaolDisplay/saoldisplay-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.bg-dark-100 {
  background: #1b1b1b;
}

.bg-dark-200 {
  background: #212121;
}

.bg-dark-300 {
  background: #2e2e2e;
}

.bg-dark-400 {
  background: #545454;
}

.bg-dark-500 {
  background: #262626;
}

.bg-gray-100 {
  background: #f2f2f2;
}

.bg-gray-200 {
  background: #f8f8f8;
}

.bg-gray-300 {
  background: #e6e6e6;
}

.bg-gray-400 {
  background: #b2b2b2;
}

.bg-gray-500 {
  background: #54595f;
}

.bg-gray-600 {
  background: #999999;
}

.bg-light {
  background: #f8f8f8;
}

.bg-olive {
  background: #a6a182;
}

.text-dark-100 {
  color: #1b1b1b;
}

.text-dark-200 {
  color: #212121;
}

.text-dark-300 {
  color: #2e2e2e;
}

.text-dark-400 {
  color: #545454;
}

.text-dark-500 {
  color: #262626;
}

.text-gray-100 {
  color: #f2f2f2;
}

.text-gray-200 {
  color: #f8f8f8;
}

.text-gray-300 {
  color: #e6e6e6;
}

.text-gray-400 {
  color: #b2b2b2;
}

.text-gray-500 {
  color: #54595f;
}

.text-gray-600 {
  color: #999999;
}

.text-light {
  color: #f8f8f8;
}

.text-olive {
  color: #a6a182;
}

/*!
 * Bootstrap  v5.2.3 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --appgenio-dark: #1b1b1b;
  --appgenio-black: #000000;
  --appgenio-white: #ffffff;
  --appgenio-gray: #f2f2f2;
  --appgenio-gray-100: #f2f2f2;
  --appgenio-gray-200: #f8f8f8;
  --appgenio-gray-300: #e6e6e6;
  --appgenio-gray-400: #b2b2b2;
  --appgenio-gray-500: #54595f;
  --appgenio-gray-600: #999999;
  --appgenio-gray-700: #495057;
  --appgenio-gray-800: #343a40;
  --appgenio-gray-900: #212529;
  --appgenio-dark: #1b1b1b;
  --appgenio-black: #000000;
  --appgenio-white: #ffffff;
  --appgenio-gray: #f2f2f2;
  --appgenio-dark-rgb: 27, 27, 27;
  --appgenio-black-rgb: 0, 0, 0;
  --appgenio-white-rgb: 255, 255, 255;
  --appgenio-gray-rgb: 242, 242, 242;
  --appgenio-white-rgb: 255, 255, 255;
  --appgenio-black-rgb: 0, 0, 0;
  --appgenio-body-color-rgb: 33, 37, 41;
  --appgenio-body-bg-rgb: 255, 255, 255;
  --appgenio-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --appgenio-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --appgenio-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --appgenio-body-font-family: Montserrat, sans-serif;
  --appgenio-body-font-size: 1rem;
  --appgenio-body-font-weight: 400;
  --appgenio-body-line-height: 1.5;
  --appgenio-body-color: #212529;
  --appgenio-body-bg: #ffffff;
  --appgenio-border-width: 1px;
  --appgenio-border-style: solid;
  --appgenio-border-color: #e6e6e6;
  --appgenio-border-color-translucent: rgba(0, 0, 0, 0.175);
  --appgenio-border-radius: 0.375rem;
  --appgenio-border-radius-sm: 0.25rem;
  --appgenio-border-radius-lg: 0.5rem;
  --appgenio-border-radius-xl: 1rem;
  --appgenio-border-radius-2xl: 2rem;
  --appgenio-border-radius-pill: 50rem;
  --appgenio-link-color: #0d6efd;
  --appgenio-link-hover-color: #0a58ca;
  --appgenio-code-color: #d63384;
  --appgenio-highlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--appgenio-body-font-family);
  font-size: var(--appgenio-body-font-size);
  font-weight: var(--appgenio-body-font-weight);
  line-height: var(--appgenio-body-line-height);
  color: var(--appgenio-body-color);
  text-align: var(--appgenio-body-text-align);
  background-color: var(--appgenio-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--appgenio-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--appgenio-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--appgenio-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--appgenio-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--appgenio-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--appgenio-body-bg);
  background-color: var(--appgenio-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #999999;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #999999;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid var(--appgenio-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #999999;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --appgenio-gutter-x: 1.5rem;
  --appgenio-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--appgenio-gutter-x) * 0.5);
  padding-left: calc(var(--appgenio-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --appgenio-gutter-x: 1.5rem;
  --appgenio-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--appgenio-gutter-y));
  margin-right: calc(-0.5 * var(--appgenio-gutter-x));
  margin-left: calc(-0.5 * var(--appgenio-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--appgenio-gutter-x) * 0.5);
  padding-left: calc(var(--appgenio-gutter-x) * 0.5);
  margin-top: var(--appgenio-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --appgenio-gutter-x: 0;
}

.g-0,
.gy-0 {
  --appgenio-gutter-y: 0;
}

.g-1,
.gx-1 {
  --appgenio-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --appgenio-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --appgenio-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --appgenio-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --appgenio-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --appgenio-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --appgenio-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --appgenio-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --appgenio-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --appgenio-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --appgenio-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --appgenio-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --appgenio-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --appgenio-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --appgenio-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --appgenio-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --appgenio-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --appgenio-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --appgenio-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --appgenio-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --appgenio-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --appgenio-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --appgenio-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --appgenio-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --appgenio-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --appgenio-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --appgenio-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --appgenio-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --appgenio-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --appgenio-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --appgenio-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --appgenio-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --appgenio-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --appgenio-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --appgenio-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --appgenio-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --appgenio-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --appgenio-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --appgenio-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --appgenio-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --appgenio-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --appgenio-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --appgenio-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --appgenio-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --appgenio-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --appgenio-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --appgenio-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --appgenio-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --appgenio-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --appgenio-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --appgenio-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --appgenio-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --appgenio-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --appgenio-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --appgenio-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --appgenio-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --appgenio-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --appgenio-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --appgenio-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --appgenio-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --appgenio-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --appgenio-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --appgenio-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --appgenio-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --appgenio-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --appgenio-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --appgenio-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --appgenio-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --appgenio-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --appgenio-gutter-y: 3rem;
  }
}
.table {
  --appgenio-table-color: var(--appgenio-body-color);
  --appgenio-table-bg: transparent;
  --appgenio-table-border-color: var(--appgenio-border-color);
  --appgenio-table-accent-bg: transparent;
  --appgenio-table-striped-color: var(--appgenio-body-color);
  --appgenio-table-striped-bg: rgba(0, 0, 0, 0.05);
  --appgenio-table-active-color: var(--appgenio-body-color);
  --appgenio-table-active-bg: rgba(0, 0, 0, 0.1);
  --appgenio-table-hover-color: var(--appgenio-body-color);
  --appgenio-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--appgenio-table-color);
  vertical-align: top;
  border-color: var(--appgenio-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--appgenio-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--appgenio-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --appgenio-table-accent-bg: var(--appgenio-table-striped-bg);
  color: var(--appgenio-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --appgenio-table-accent-bg: var(--appgenio-table-striped-bg);
  color: var(--appgenio-table-striped-color);
}

.table-active {
  --appgenio-table-accent-bg: var(--appgenio-table-active-bg);
  color: var(--appgenio-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --appgenio-table-accent-bg: var(--appgenio-table-hover-bg);
  color: var(--appgenio-table-hover-color);
}

.table-primary {
  --appgenio-table-color: #000000;
  --appgenio-table-bg: #cfe2ff;
  --appgenio-table-border-color: #bacbe6;
  --appgenio-table-striped-bg: #c5d7f2;
  --appgenio-table-striped-color: #000000;
  --appgenio-table-active-bg: #bacbe6;
  --appgenio-table-active-color: #000000;
  --appgenio-table-hover-bg: #bfd1ec;
  --appgenio-table-hover-color: #000000;
  color: var(--appgenio-table-color);
  border-color: var(--appgenio-table-border-color);
}

.table-secondary {
  --appgenio-table-color: #000000;
  --appgenio-table-bg: #ebebeb;
  --appgenio-table-border-color: #d4d4d4;
  --appgenio-table-striped-bg: #dfdfdf;
  --appgenio-table-striped-color: #000000;
  --appgenio-table-active-bg: #d4d4d4;
  --appgenio-table-active-color: #000000;
  --appgenio-table-hover-bg: #d9d9d9;
  --appgenio-table-hover-color: #000000;
  color: var(--appgenio-table-color);
  border-color: var(--appgenio-table-border-color);
}

.table-success {
  --appgenio-table-color: #000000;
  --appgenio-table-bg: #d1e7dd;
  --appgenio-table-border-color: #bcd0c7;
  --appgenio-table-striped-bg: #c7dbd2;
  --appgenio-table-striped-color: #000000;
  --appgenio-table-active-bg: #bcd0c7;
  --appgenio-table-active-color: #000000;
  --appgenio-table-hover-bg: #c1d6cc;
  --appgenio-table-hover-color: #000000;
  color: var(--appgenio-table-color);
  border-color: var(--appgenio-table-border-color);
}

.table-info {
  --appgenio-table-color: #000000;
  --appgenio-table-bg: #cff4fc;
  --appgenio-table-border-color: #badce3;
  --appgenio-table-striped-bg: #c5e8ef;
  --appgenio-table-striped-color: #000000;
  --appgenio-table-active-bg: #badce3;
  --appgenio-table-active-color: #000000;
  --appgenio-table-hover-bg: #bfe2e9;
  --appgenio-table-hover-color: #000000;
  color: var(--appgenio-table-color);
  border-color: var(--appgenio-table-border-color);
}

.table-warning {
  --appgenio-table-color: #000000;
  --appgenio-table-bg: #fff3cd;
  --appgenio-table-border-color: #e6dbb9;
  --appgenio-table-striped-bg: #f2e7c3;
  --appgenio-table-striped-color: #000000;
  --appgenio-table-active-bg: #e6dbb9;
  --appgenio-table-active-color: #000000;
  --appgenio-table-hover-bg: #ece1be;
  --appgenio-table-hover-color: #000000;
  color: var(--appgenio-table-color);
  border-color: var(--appgenio-table-border-color);
}

.table-danger {
  --appgenio-table-color: #000000;
  --appgenio-table-bg: #f8d7da;
  --appgenio-table-border-color: #dfc2c4;
  --appgenio-table-striped-bg: #eccccf;
  --appgenio-table-striped-color: #000000;
  --appgenio-table-active-bg: #dfc2c4;
  --appgenio-table-active-color: #000000;
  --appgenio-table-hover-bg: #e5c7ca;
  --appgenio-table-hover-color: #000000;
  color: var(--appgenio-table-color);
  border-color: var(--appgenio-table-border-color);
}

.table-light {
  --appgenio-table-color: #000000;
  --appgenio-table-bg: #f2f2f2;
  --appgenio-table-border-color: #dadada;
  --appgenio-table-striped-bg: #e6e6e6;
  --appgenio-table-striped-color: #000000;
  --appgenio-table-active-bg: #dadada;
  --appgenio-table-active-color: #000000;
  --appgenio-table-hover-bg: #e0e0e0;
  --appgenio-table-hover-color: #000000;
  color: var(--appgenio-table-color);
  border-color: var(--appgenio-table-border-color);
}

.table-dark {
  --appgenio-table-color: #ffffff;
  --appgenio-table-bg: #212529;
  --appgenio-table-border-color: #373b3e;
  --appgenio-table-striped-bg: #2c3034;
  --appgenio-table-striped-color: #ffffff;
  --appgenio-table-active-bg: #373b3e;
  --appgenio-table-active-color: #ffffff;
  --appgenio-table-hover-bg: #323539;
  --appgenio-table-hover-color: #ffffff;
  color: var(--appgenio-table-color);
  border-color: var(--appgenio-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #999999;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #b2b2b2;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #ffffff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #999999;
  opacity: 1;
}
.form-control:disabled {
  background-color: #f8f8f8;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #f8f8f8;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #ececec;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.375rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.375rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #b2b2b2;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #f8f8f8;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e6e6e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e6e6e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #54595f;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #54595f;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #f8f8f8;
  border: 1px solid #b2b2b2;
  border-radius: 0.375rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #ffffff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.375rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #ffffff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.375rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --appgenio-btn-padding-x: 0.75rem;
  --appgenio-btn-padding-y: 0.375rem;
  --appgenio-btn-font-family: ;
  --appgenio-btn-font-size: 1rem;
  --appgenio-btn-font-weight: 400;
  --appgenio-btn-line-height: 1.5;
  --appgenio-btn-color: #212529;
  --appgenio-btn-bg: transparent;
  --appgenio-btn-border-width: 1px;
  --appgenio-btn-border-color: transparent;
  --appgenio-btn-border-radius: 0.375rem;
  --appgenio-btn-hover-border-color: transparent;
  --appgenio-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --appgenio-btn-disabled-opacity: 0.65;
  --appgenio-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--appgenio-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--appgenio-btn-padding-y) var(--appgenio-btn-padding-x);
  font-family: var(--appgenio-btn-font-family);
  font-size: var(--appgenio-btn-font-size);
  font-weight: var(--appgenio-btn-font-weight);
  line-height: var(--appgenio-btn-line-height);
  color: var(--appgenio-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--appgenio-btn-border-width) solid var(--appgenio-btn-border-color);
  border-radius: var(--appgenio-btn-border-radius);
  background-color: var(--appgenio-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--appgenio-btn-hover-color);
  background-color: var(--appgenio-btn-hover-bg);
  border-color: var(--appgenio-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--appgenio-btn-color);
  background-color: var(--appgenio-btn-bg);
  border-color: var(--appgenio-btn-border-color);
}
.btn:focus-visible {
  color: var(--appgenio-btn-hover-color);
  background-color: var(--appgenio-btn-hover-bg);
  border-color: var(--appgenio-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--appgenio-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--appgenio-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--appgenio-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--appgenio-btn-active-color);
  background-color: var(--appgenio-btn-active-bg);
  border-color: var(--appgenio-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--appgenio-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--appgenio-btn-disabled-color);
  pointer-events: none;
  background-color: var(--appgenio-btn-disabled-bg);
  border-color: var(--appgenio-btn-disabled-border-color);
  opacity: var(--appgenio-btn-disabled-opacity);
}

.btn-dark {
  --appgenio-btn-color: #ffffff;
  --appgenio-btn-bg: #1b1b1b;
  --appgenio-btn-border-color: #1b1b1b;
  --appgenio-btn-hover-color: #ffffff;
  --appgenio-btn-hover-bg: #3d3d3d;
  --appgenio-btn-hover-border-color: #323232;
  --appgenio-btn-focus-shadow-rgb: 61, 61, 61;
  --appgenio-btn-active-color: #ffffff;
  --appgenio-btn-active-bg: #494949;
  --appgenio-btn-active-border-color: #323232;
  --appgenio-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --appgenio-btn-disabled-color: #ffffff;
  --appgenio-btn-disabled-bg: #1b1b1b;
  --appgenio-btn-disabled-border-color: #1b1b1b;
}

.btn-black {
  --appgenio-btn-color: #ffffff;
  --appgenio-btn-bg: #000000;
  --appgenio-btn-border-color: #000000;
  --appgenio-btn-hover-color: #ffffff;
  --appgenio-btn-hover-bg: black;
  --appgenio-btn-hover-border-color: black;
  --appgenio-btn-focus-shadow-rgb: 38, 38, 38;
  --appgenio-btn-active-color: #ffffff;
  --appgenio-btn-active-bg: black;
  --appgenio-btn-active-border-color: black;
  --appgenio-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --appgenio-btn-disabled-color: #ffffff;
  --appgenio-btn-disabled-bg: #000000;
  --appgenio-btn-disabled-border-color: #000000;
}

.btn-white {
  --appgenio-btn-color: #000000;
  --appgenio-btn-bg: #ffffff;
  --appgenio-btn-border-color: #ffffff;
  --appgenio-btn-hover-color: #000000;
  --appgenio-btn-hover-bg: white;
  --appgenio-btn-hover-border-color: white;
  --appgenio-btn-focus-shadow-rgb: 217, 217, 217;
  --appgenio-btn-active-color: #000000;
  --appgenio-btn-active-bg: white;
  --appgenio-btn-active-border-color: white;
  --appgenio-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --appgenio-btn-disabled-color: #000000;
  --appgenio-btn-disabled-bg: #ffffff;
  --appgenio-btn-disabled-border-color: #ffffff;
}

.btn-gray {
  --appgenio-btn-color: #000000;
  --appgenio-btn-bg: #f2f2f2;
  --appgenio-btn-border-color: #f2f2f2;
  --appgenio-btn-hover-color: #000000;
  --appgenio-btn-hover-bg: #f4f4f4;
  --appgenio-btn-hover-border-color: #f3f3f3;
  --appgenio-btn-focus-shadow-rgb: 206, 206, 206;
  --appgenio-btn-active-color: #000000;
  --appgenio-btn-active-bg: whitesmoke;
  --appgenio-btn-active-border-color: #f3f3f3;
  --appgenio-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --appgenio-btn-disabled-color: #000000;
  --appgenio-btn-disabled-bg: #f2f2f2;
  --appgenio-btn-disabled-border-color: #f2f2f2;
}

.btn-outline-dark {
  --appgenio-btn-color: #1b1b1b;
  --appgenio-btn-border-color: #1b1b1b;
  --appgenio-btn-hover-color: #ffffff;
  --appgenio-btn-hover-bg: #1b1b1b;
  --appgenio-btn-hover-border-color: #1b1b1b;
  --appgenio-btn-focus-shadow-rgb: 27, 27, 27;
  --appgenio-btn-active-color: #ffffff;
  --appgenio-btn-active-bg: #1b1b1b;
  --appgenio-btn-active-border-color: #1b1b1b;
  --appgenio-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --appgenio-btn-disabled-color: #1b1b1b;
  --appgenio-btn-disabled-bg: transparent;
  --appgenio-btn-disabled-border-color: #1b1b1b;
  --appgenio-gradient: none;
}

.btn-outline-black {
  --appgenio-btn-color: #000000;
  --appgenio-btn-border-color: #000000;
  --appgenio-btn-hover-color: #ffffff;
  --appgenio-btn-hover-bg: #000000;
  --appgenio-btn-hover-border-color: #000000;
  --appgenio-btn-focus-shadow-rgb: 0, 0, 0;
  --appgenio-btn-active-color: #ffffff;
  --appgenio-btn-active-bg: #000000;
  --appgenio-btn-active-border-color: #000000;
  --appgenio-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --appgenio-btn-disabled-color: #000000;
  --appgenio-btn-disabled-bg: transparent;
  --appgenio-btn-disabled-border-color: #000000;
  --appgenio-gradient: none;
}

.btn-outline-white {
  --appgenio-btn-color: #ffffff;
  --appgenio-btn-border-color: #ffffff;
  --appgenio-btn-hover-color: #000000;
  --appgenio-btn-hover-bg: #ffffff;
  --appgenio-btn-hover-border-color: #ffffff;
  --appgenio-btn-focus-shadow-rgb: 255, 255, 255;
  --appgenio-btn-active-color: #000000;
  --appgenio-btn-active-bg: #ffffff;
  --appgenio-btn-active-border-color: #ffffff;
  --appgenio-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --appgenio-btn-disabled-color: #ffffff;
  --appgenio-btn-disabled-bg: transparent;
  --appgenio-btn-disabled-border-color: #ffffff;
  --appgenio-gradient: none;
}

.btn-outline-gray {
  --appgenio-btn-color: #f2f2f2;
  --appgenio-btn-border-color: #f2f2f2;
  --appgenio-btn-hover-color: #000000;
  --appgenio-btn-hover-bg: #f2f2f2;
  --appgenio-btn-hover-border-color: #f2f2f2;
  --appgenio-btn-focus-shadow-rgb: 242, 242, 242;
  --appgenio-btn-active-color: #000000;
  --appgenio-btn-active-bg: #f2f2f2;
  --appgenio-btn-active-border-color: #f2f2f2;
  --appgenio-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --appgenio-btn-disabled-color: #f2f2f2;
  --appgenio-btn-disabled-bg: transparent;
  --appgenio-btn-disabled-border-color: #f2f2f2;
  --appgenio-gradient: none;
}

.btn-link {
  --appgenio-btn-font-weight: 400;
  --appgenio-btn-color: var(--appgenio-link-color);
  --appgenio-btn-bg: transparent;
  --appgenio-btn-border-color: transparent;
  --appgenio-btn-hover-color: var(--appgenio-link-hover-color);
  --appgenio-btn-hover-border-color: transparent;
  --appgenio-btn-active-color: var(--appgenio-link-hover-color);
  --appgenio-btn-active-border-color: transparent;
  --appgenio-btn-disabled-color: #999999;
  --appgenio-btn-disabled-border-color: transparent;
  --appgenio-btn-box-shadow: none;
  --appgenio-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--appgenio-btn-color);
}
.btn-link:hover {
  color: var(--appgenio-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --appgenio-btn-padding-y: 0.5rem;
  --appgenio-btn-padding-x: 1rem;
  --appgenio-btn-font-size: 1.25rem;
  --appgenio-btn-border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  --appgenio-btn-padding-y: 0.25rem;
  --appgenio-btn-padding-x: 0.5rem;
  --appgenio-btn-font-size: 0.875rem;
  --appgenio-btn-border-radius: 0.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --appgenio-dropdown-zindex: 1000;
  --appgenio-dropdown-min-width: 10rem;
  --appgenio-dropdown-padding-x: 0;
  --appgenio-dropdown-padding-y: 0.5rem;
  --appgenio-dropdown-spacer: 0.125rem;
  --appgenio-dropdown-font-size: 1rem;
  --appgenio-dropdown-color: #212529;
  --appgenio-dropdown-bg: #ffffff;
  --appgenio-dropdown-border-color: var(--appgenio-border-color-translucent);
  --appgenio-dropdown-border-radius: 0.375rem;
  --appgenio-dropdown-border-width: 1px;
  --appgenio-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --appgenio-dropdown-divider-bg: var(--appgenio-border-color-translucent);
  --appgenio-dropdown-divider-margin-y: 0.5rem;
  --appgenio-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --appgenio-dropdown-link-color: #212529;
  --appgenio-dropdown-link-hover-color: #1e2125;
  --appgenio-dropdown-link-hover-bg: #f8f8f8;
  --appgenio-dropdown-link-active-color: #ffffff;
  --appgenio-dropdown-link-active-bg: #0d6efd;
  --appgenio-dropdown-link-disabled-color: #54595f;
  --appgenio-dropdown-item-padding-x: 1rem;
  --appgenio-dropdown-item-padding-y: 0.25rem;
  --appgenio-dropdown-header-color: #999999;
  --appgenio-dropdown-header-padding-x: 1rem;
  --appgenio-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--appgenio-dropdown-zindex);
  display: none;
  min-width: var(--appgenio-dropdown-min-width);
  padding: var(--appgenio-dropdown-padding-y) var(--appgenio-dropdown-padding-x);
  margin: 0;
  font-size: var(--appgenio-dropdown-font-size);
  color: var(--appgenio-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--appgenio-dropdown-bg);
  background-clip: padding-box;
  border: var(--appgenio-dropdown-border-width) solid var(--appgenio-dropdown-border-color);
  border-radius: var(--appgenio-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--appgenio-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--appgenio-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--appgenio-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--appgenio-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--appgenio-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--appgenio-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--appgenio-dropdown-item-padding-y) var(--appgenio-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--appgenio-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--appgenio-dropdown-link-hover-color);
  background-color: var(--appgenio-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--appgenio-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--appgenio-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--appgenio-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--appgenio-dropdown-header-padding-y) var(--appgenio-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--appgenio-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--appgenio-dropdown-item-padding-y) var(--appgenio-dropdown-item-padding-x);
  color: var(--appgenio-dropdown-link-color);
}

.dropdown-menu-dark {
  --appgenio-dropdown-color: #e6e6e6;
  --appgenio-dropdown-bg: #343a40;
  --appgenio-dropdown-border-color: var(--appgenio-border-color-translucent);
  --appgenio-dropdown-box-shadow: ;
  --appgenio-dropdown-link-color: #e6e6e6;
  --appgenio-dropdown-link-hover-color: #ffffff;
  --appgenio-dropdown-divider-bg: var(--appgenio-border-color-translucent);
  --appgenio-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --appgenio-dropdown-link-active-color: #ffffff;
  --appgenio-dropdown-link-active-bg: #0d6efd;
  --appgenio-dropdown-link-disabled-color: #54595f;
  --appgenio-dropdown-header-color: #54595f;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.375rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --appgenio-nav-link-padding-x: 1rem;
  --appgenio-nav-link-padding-y: 0.5rem;
  --appgenio-nav-link-font-weight: ;
  --appgenio-nav-link-color: var(--appgenio-link-color);
  --appgenio-nav-link-hover-color: var(--appgenio-link-hover-color);
  --appgenio-nav-link-disabled-color: #999999;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--appgenio-nav-link-padding-y) var(--appgenio-nav-link-padding-x);
  font-size: var(--appgenio-nav-link-font-size);
  font-weight: var(--appgenio-nav-link-font-weight);
  color: var(--appgenio-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--appgenio-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--appgenio-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --appgenio-nav-tabs-border-width: 1px;
  --appgenio-nav-tabs-border-color: #e6e6e6;
  --appgenio-nav-tabs-border-radius: 0.375rem;
  --appgenio-nav-tabs-link-hover-border-color: #f8f8f8 #f8f8f8 #e6e6e6;
  --appgenio-nav-tabs-link-active-color: #495057;
  --appgenio-nav-tabs-link-active-bg: #ffffff;
  --appgenio-nav-tabs-link-active-border-color: #e6e6e6 #e6e6e6 #ffffff;
  border-bottom: var(--appgenio-nav-tabs-border-width) solid var(--appgenio-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--appgenio-nav-tabs-border-width));
  background: none;
  border: var(--appgenio-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--appgenio-nav-tabs-border-radius);
  border-top-right-radius: var(--appgenio-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--appgenio-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--appgenio-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--appgenio-nav-tabs-link-active-color);
  background-color: var(--appgenio-nav-tabs-link-active-bg);
  border-color: var(--appgenio-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--appgenio-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --appgenio-nav-pills-border-radius: 0.375rem;
  --appgenio-nav-pills-link-active-color: #ffffff;
  --appgenio-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--appgenio-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--appgenio-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--appgenio-nav-pills-link-active-color);
  background-color: var(--appgenio-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --appgenio-navbar-padding-x: 0;
  --appgenio-navbar-padding-y: 0.5rem;
  --appgenio-navbar-color: rgba(0, 0, 0, 0.55);
  --appgenio-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --appgenio-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --appgenio-navbar-active-color: rgba(0, 0, 0, 0.9);
  --appgenio-navbar-brand-padding-y: 0.3125rem;
  --appgenio-navbar-brand-margin-end: 1rem;
  --appgenio-navbar-brand-font-size: 1.25rem;
  --appgenio-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --appgenio-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --appgenio-navbar-nav-link-padding-x: 0.5rem;
  --appgenio-navbar-toggler-padding-y: 0.25rem;
  --appgenio-navbar-toggler-padding-x: 0.75rem;
  --appgenio-navbar-toggler-font-size: 1.25rem;
  --appgenio-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --appgenio-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --appgenio-navbar-toggler-border-radius: 0.375rem;
  --appgenio-navbar-toggler-focus-width: 0.25rem;
  --appgenio-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--appgenio-navbar-padding-y) var(--appgenio-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--appgenio-navbar-brand-padding-y);
  padding-bottom: var(--appgenio-navbar-brand-padding-y);
  margin-right: var(--appgenio-navbar-brand-margin-end);
  font-size: var(--appgenio-navbar-brand-font-size);
  color: var(--appgenio-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--appgenio-navbar-brand-hover-color);
}

.navbar-nav {
  --appgenio-nav-link-padding-x: 0;
  --appgenio-nav-link-padding-y: 0.5rem;
  --appgenio-nav-link-font-weight: ;
  --appgenio-nav-link-color: var(--appgenio-navbar-color);
  --appgenio-nav-link-hover-color: var(--appgenio-navbar-hover-color);
  --appgenio-nav-link-disabled-color: var(--appgenio-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--appgenio-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--appgenio-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--appgenio-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--appgenio-navbar-toggler-padding-y) var(--appgenio-navbar-toggler-padding-x);
  font-size: var(--appgenio-navbar-toggler-font-size);
  line-height: 1;
  color: var(--appgenio-navbar-color);
  background-color: transparent;
  border: var(--appgenio-border-width) solid var(--appgenio-navbar-toggler-border-color);
  border-radius: var(--appgenio-navbar-toggler-border-radius);
  transition: var(--appgenio-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--appgenio-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--appgenio-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--appgenio-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--appgenio-navbar-nav-link-padding-x);
    padding-left: var(--appgenio-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--appgenio-navbar-nav-link-padding-x);
    padding-left: var(--appgenio-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--appgenio-navbar-nav-link-padding-x);
    padding-left: var(--appgenio-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--appgenio-navbar-nav-link-padding-x);
    padding-left: var(--appgenio-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--appgenio-navbar-nav-link-padding-x);
    padding-left: var(--appgenio-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--appgenio-navbar-nav-link-padding-x);
  padding-left: var(--appgenio-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --appgenio-navbar-color: rgba(255, 255, 255, 0.55);
  --appgenio-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --appgenio-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --appgenio-navbar-active-color: #ffffff;
  --appgenio-navbar-brand-color: #ffffff;
  --appgenio-navbar-brand-hover-color: #ffffff;
  --appgenio-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --appgenio-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --appgenio-card-spacer-y: 1rem;
  --appgenio-card-spacer-x: 1rem;
  --appgenio-card-title-spacer-y: 0.5rem;
  --appgenio-card-border-width: 1px;
  --appgenio-card-border-color: var(--appgenio-border-color-translucent);
  --appgenio-card-border-radius: 0.375rem;
  --appgenio-card-box-shadow: ;
  --appgenio-card-inner-border-radius: calc(0.375rem - 1px);
  --appgenio-card-cap-padding-y: 0.5rem;
  --appgenio-card-cap-padding-x: 1rem;
  --appgenio-card-cap-bg: rgba(0, 0, 0, 0.03);
  --appgenio-card-cap-color: ;
  --appgenio-card-height: ;
  --appgenio-card-color: ;
  --appgenio-card-bg: #ffffff;
  --appgenio-card-img-overlay-padding: 1rem;
  --appgenio-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--appgenio-card-height);
  word-wrap: break-word;
  background-color: var(--appgenio-card-bg);
  background-clip: border-box;
  border: var(--appgenio-card-border-width) solid var(--appgenio-card-border-color);
  border-radius: var(--appgenio-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--appgenio-card-inner-border-radius);
  border-top-right-radius: var(--appgenio-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--appgenio-card-inner-border-radius);
  border-bottom-left-radius: var(--appgenio-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--appgenio-card-spacer-y) var(--appgenio-card-spacer-x);
  color: var(--appgenio-card-color);
}

.card-title {
  margin-bottom: var(--appgenio-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--appgenio-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--appgenio-card-spacer-x);
}

.card-header {
  padding: var(--appgenio-card-cap-padding-y) var(--appgenio-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--appgenio-card-cap-color);
  background-color: var(--appgenio-card-cap-bg);
  border-bottom: var(--appgenio-card-border-width) solid var(--appgenio-card-border-color);
}
.card-header:first-child {
  border-radius: var(--appgenio-card-inner-border-radius) var(--appgenio-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--appgenio-card-cap-padding-y) var(--appgenio-card-cap-padding-x);
  color: var(--appgenio-card-cap-color);
  background-color: var(--appgenio-card-cap-bg);
  border-top: var(--appgenio-card-border-width) solid var(--appgenio-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--appgenio-card-inner-border-radius) var(--appgenio-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--appgenio-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--appgenio-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--appgenio-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--appgenio-card-bg);
  border-bottom-color: var(--appgenio-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--appgenio-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--appgenio-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--appgenio-card-img-overlay-padding);
  border-radius: var(--appgenio-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--appgenio-card-inner-border-radius);
  border-top-right-radius: var(--appgenio-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--appgenio-card-inner-border-radius);
  border-bottom-left-radius: var(--appgenio-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--appgenio-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --appgenio-accordion-color: #212529;
  --appgenio-accordion-bg: #ffffff;
  --appgenio-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --appgenio-accordion-border-color: var(--appgenio-border-color);
  --appgenio-accordion-border-width: 1px;
  --appgenio-accordion-border-radius: 0.375rem;
  --appgenio-accordion-inner-border-radius: calc(0.375rem - 1px);
  --appgenio-accordion-btn-padding-x: 1.25rem;
  --appgenio-accordion-btn-padding-y: 1rem;
  --appgenio-accordion-btn-color: #212529;
  --appgenio-accordion-btn-bg: var(--appgenio-accordion-bg);
  --appgenio-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --appgenio-accordion-btn-icon-width: 1.25rem;
  --appgenio-accordion-btn-icon-transform: rotate(-180deg);
  --appgenio-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --appgenio-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --appgenio-accordion-btn-focus-border-color: #86b7fe;
  --appgenio-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --appgenio-accordion-body-padding-x: 1.25rem;
  --appgenio-accordion-body-padding-y: 1rem;
  --appgenio-accordion-active-color: #0c63e4;
  --appgenio-accordion-active-bg: #e7f1ff;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--appgenio-accordion-btn-padding-y) var(--appgenio-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--appgenio-accordion-btn-color);
  text-align: left;
  background-color: var(--appgenio-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--appgenio-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--appgenio-accordion-active-color);
  background-color: var(--appgenio-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--appgenio-accordion-border-width)) 0 var(--appgenio-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--appgenio-accordion-btn-active-icon);
  transform: var(--appgenio-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--appgenio-accordion-btn-icon-width);
  height: var(--appgenio-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--appgenio-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--appgenio-accordion-btn-icon-width);
  transition: var(--appgenio-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--appgenio-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--appgenio-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--appgenio-accordion-color);
  background-color: var(--appgenio-accordion-bg);
  border: var(--appgenio-accordion-border-width) solid var(--appgenio-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--appgenio-accordion-border-radius);
  border-top-right-radius: var(--appgenio-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--appgenio-accordion-inner-border-radius);
  border-top-right-radius: var(--appgenio-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--appgenio-accordion-border-radius);
  border-bottom-left-radius: var(--appgenio-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--appgenio-accordion-inner-border-radius);
  border-bottom-left-radius: var(--appgenio-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--appgenio-accordion-border-radius);
  border-bottom-left-radius: var(--appgenio-accordion-border-radius);
}

.accordion-body {
  padding: var(--appgenio-accordion-body-padding-y) var(--appgenio-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --appgenio-breadcrumb-padding-x: 0;
  --appgenio-breadcrumb-padding-y: 0;
  --appgenio-breadcrumb-margin-bottom: 1rem;
  --appgenio-breadcrumb-bg: ;
  --appgenio-breadcrumb-border-radius: ;
  --appgenio-breadcrumb-divider-color: #999999;
  --appgenio-breadcrumb-item-padding-x: 0.5rem;
  --appgenio-breadcrumb-item-active-color: #999999;
  display: flex;
  flex-wrap: wrap;
  padding: var(--appgenio-breadcrumb-padding-y) var(--appgenio-breadcrumb-padding-x);
  margin-bottom: var(--appgenio-breadcrumb-margin-bottom);
  font-size: var(--appgenio-breadcrumb-font-size);
  list-style: none;
  background-color: var(--appgenio-breadcrumb-bg);
  border-radius: var(--appgenio-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--appgenio-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--appgenio-breadcrumb-item-padding-x);
  color: var(--appgenio-breadcrumb-divider-color);
  content: var(--appgenio-breadcrumb-divider, "/") /* rtl: var(--appgenio-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--appgenio-breadcrumb-item-active-color);
}

.pagination {
  --appgenio-pagination-padding-x: 0.75rem;
  --appgenio-pagination-padding-y: 0.375rem;
  --appgenio-pagination-font-size: 1rem;
  --appgenio-pagination-color: var(--appgenio-link-color);
  --appgenio-pagination-bg: #ffffff;
  --appgenio-pagination-border-width: 1px;
  --appgenio-pagination-border-color: #e6e6e6;
  --appgenio-pagination-border-radius: 0.375rem;
  --appgenio-pagination-hover-color: var(--appgenio-link-hover-color);
  --appgenio-pagination-hover-bg: #f8f8f8;
  --appgenio-pagination-hover-border-color: #e6e6e6;
  --appgenio-pagination-focus-color: var(--appgenio-link-hover-color);
  --appgenio-pagination-focus-bg: #f8f8f8;
  --appgenio-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --appgenio-pagination-active-color: #ffffff;
  --appgenio-pagination-active-bg: #0d6efd;
  --appgenio-pagination-active-border-color: #0d6efd;
  --appgenio-pagination-disabled-color: #999999;
  --appgenio-pagination-disabled-bg: #ffffff;
  --appgenio-pagination-disabled-border-color: #e6e6e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--appgenio-pagination-padding-y) var(--appgenio-pagination-padding-x);
  font-size: var(--appgenio-pagination-font-size);
  color: var(--appgenio-pagination-color);
  text-decoration: none;
  background-color: var(--appgenio-pagination-bg);
  border: var(--appgenio-pagination-border-width) solid var(--appgenio-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--appgenio-pagination-hover-color);
  background-color: var(--appgenio-pagination-hover-bg);
  border-color: var(--appgenio-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--appgenio-pagination-focus-color);
  background-color: var(--appgenio-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--appgenio-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--appgenio-pagination-active-color);
  background-color: var(--appgenio-pagination-active-bg);
  border-color: var(--appgenio-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--appgenio-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--appgenio-pagination-disabled-bg);
  border-color: var(--appgenio-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--appgenio-pagination-border-radius);
  border-bottom-left-radius: var(--appgenio-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--appgenio-pagination-border-radius);
  border-bottom-right-radius: var(--appgenio-pagination-border-radius);
}

.pagination-lg {
  --appgenio-pagination-padding-x: 1.5rem;
  --appgenio-pagination-padding-y: 0.75rem;
  --appgenio-pagination-font-size: 1.25rem;
  --appgenio-pagination-border-radius: 0.5rem;
}

.pagination-sm {
  --appgenio-pagination-padding-x: 0.5rem;
  --appgenio-pagination-padding-y: 0.25rem;
  --appgenio-pagination-font-size: 0.875rem;
  --appgenio-pagination-border-radius: 0.25rem;
}

.badge {
  --appgenio-badge-padding-x: 0.65em;
  --appgenio-badge-padding-y: 0.35em;
  --appgenio-badge-font-size: 0.75em;
  --appgenio-badge-font-weight: 700;
  --appgenio-badge-color: #ffffff;
  --appgenio-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--appgenio-badge-padding-y) var(--appgenio-badge-padding-x);
  font-size: var(--appgenio-badge-font-size);
  font-weight: var(--appgenio-badge-font-weight);
  line-height: 1;
  color: var(--appgenio-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--appgenio-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --appgenio-alert-bg: transparent;
  --appgenio-alert-padding-x: 1rem;
  --appgenio-alert-padding-y: 1rem;
  --appgenio-alert-margin-bottom: 1rem;
  --appgenio-alert-color: inherit;
  --appgenio-alert-border-color: transparent;
  --appgenio-alert-border: 1px solid var(--appgenio-alert-border-color);
  --appgenio-alert-border-radius: 0.375rem;
  position: relative;
  padding: var(--appgenio-alert-padding-y) var(--appgenio-alert-padding-x);
  margin-bottom: var(--appgenio-alert-margin-bottom);
  color: var(--appgenio-alert-color);
  background-color: var(--appgenio-alert-bg);
  border: var(--appgenio-alert-border);
  border-radius: var(--appgenio-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-dark {
  --appgenio-alert-color: #101010;
  --appgenio-alert-bg: #d1d1d1;
  --appgenio-alert-border-color: #bbbbbb;
}
.alert-dark .alert-link {
  color: #0d0d0d;
}

.alert-black {
  --appgenio-alert-color: black;
  --appgenio-alert-bg: #cccccc;
  --appgenio-alert-border-color: #b3b3b3;
}
.alert-black .alert-link {
  color: black;
}

.alert-white {
  --appgenio-alert-color: #666666;
  --appgenio-alert-bg: white;
  --appgenio-alert-border-color: white;
}
.alert-white .alert-link {
  color: #525252;
}

.alert-gray {
  --appgenio-alert-color: #616161;
  --appgenio-alert-bg: #fcfcfc;
  --appgenio-alert-border-color: #fbfbfb;
}
.alert-gray .alert-link {
  color: #4e4e4e;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --appgenio-progress-height: 1rem;
  --appgenio-progress-font-size: 0.75rem;
  --appgenio-progress-bg: #f8f8f8;
  --appgenio-progress-border-radius: 0.375rem;
  --appgenio-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --appgenio-progress-bar-color: #ffffff;
  --appgenio-progress-bar-bg: #0d6efd;
  --appgenio-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--appgenio-progress-height);
  overflow: hidden;
  font-size: var(--appgenio-progress-font-size);
  background-color: var(--appgenio-progress-bg);
  border-radius: var(--appgenio-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--appgenio-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--appgenio-progress-bar-bg);
  transition: var(--appgenio-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--appgenio-progress-height) var(--appgenio-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --appgenio-list-group-color: #212529;
  --appgenio-list-group-bg: #ffffff;
  --appgenio-list-group-border-color: rgba(0, 0, 0, 0.125);
  --appgenio-list-group-border-width: 1px;
  --appgenio-list-group-border-radius: 0.375rem;
  --appgenio-list-group-item-padding-x: 1rem;
  --appgenio-list-group-item-padding-y: 0.5rem;
  --appgenio-list-group-action-color: #495057;
  --appgenio-list-group-action-hover-color: #495057;
  --appgenio-list-group-action-hover-bg: #f2f2f2;
  --appgenio-list-group-action-active-color: #212529;
  --appgenio-list-group-action-active-bg: #f8f8f8;
  --appgenio-list-group-disabled-color: #999999;
  --appgenio-list-group-disabled-bg: #ffffff;
  --appgenio-list-group-active-color: #ffffff;
  --appgenio-list-group-active-bg: #0d6efd;
  --appgenio-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--appgenio-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--appgenio-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--appgenio-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--appgenio-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--appgenio-list-group-action-active-color);
  background-color: var(--appgenio-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--appgenio-list-group-item-padding-y) var(--appgenio-list-group-item-padding-x);
  color: var(--appgenio-list-group-color);
  text-decoration: none;
  background-color: var(--appgenio-list-group-bg);
  border: var(--appgenio-list-group-border-width) solid var(--appgenio-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--appgenio-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--appgenio-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--appgenio-list-group-active-color);
  background-color: var(--appgenio-list-group-active-bg);
  border-color: var(--appgenio-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--appgenio-list-group-border-width));
  border-top-width: var(--appgenio-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--appgenio-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--appgenio-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--appgenio-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--appgenio-list-group-border-width));
  border-left-width: var(--appgenio-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--appgenio-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--appgenio-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--appgenio-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--appgenio-list-group-border-width));
    border-left-width: var(--appgenio-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--appgenio-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--appgenio-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--appgenio-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--appgenio-list-group-border-width));
    border-left-width: var(--appgenio-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--appgenio-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--appgenio-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--appgenio-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--appgenio-list-group-border-width));
    border-left-width: var(--appgenio-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--appgenio-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--appgenio-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--appgenio-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--appgenio-list-group-border-width));
    border-left-width: var(--appgenio-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--appgenio-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--appgenio-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--appgenio-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--appgenio-list-group-border-width));
    border-left-width: var(--appgenio-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--appgenio-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-dark {
  color: #101010;
  background-color: #d1d1d1;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #101010;
  background-color: #bcbcbc;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #101010;
  border-color: #101010;
}

.list-group-item-black {
  color: black;
  background-color: #cccccc;
}
.list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
  color: black;
  background-color: #b8b8b8;
}
.list-group-item-black.list-group-item-action.active {
  color: #ffffff;
  background-color: black;
  border-color: black;
}

.list-group-item-white {
  color: #666666;
  background-color: white;
}
.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #666666;
  background-color: #e6e6e6;
}
.list-group-item-white.list-group-item-action.active {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
}

.list-group-item-gray {
  color: #616161;
  background-color: #fcfcfc;
}
.list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
  color: #616161;
  background-color: #e3e3e3;
}
.list-group-item-gray.list-group-item-action.active {
  color: #ffffff;
  background-color: #616161;
  border-color: #616161;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --appgenio-toast-zindex: 1090;
  --appgenio-toast-padding-x: 0.75rem;
  --appgenio-toast-padding-y: 0.5rem;
  --appgenio-toast-spacing: 1.5rem;
  --appgenio-toast-max-width: 350px;
  --appgenio-toast-font-size: 0.875rem;
  --appgenio-toast-color: ;
  --appgenio-toast-bg: rgba(255, 255, 255, 0.85);
  --appgenio-toast-border-width: 1px;
  --appgenio-toast-border-color: var(--appgenio-border-color-translucent);
  --appgenio-toast-border-radius: 0.375rem;
  --appgenio-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --appgenio-toast-header-color: #999999;
  --appgenio-toast-header-bg: rgba(255, 255, 255, 0.85);
  --appgenio-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--appgenio-toast-max-width);
  max-width: 100%;
  font-size: var(--appgenio-toast-font-size);
  color: var(--appgenio-toast-color);
  pointer-events: auto;
  background-color: var(--appgenio-toast-bg);
  background-clip: padding-box;
  border: var(--appgenio-toast-border-width) solid var(--appgenio-toast-border-color);
  box-shadow: var(--appgenio-toast-box-shadow);
  border-radius: var(--appgenio-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --appgenio-toast-zindex: 1090;
  position: absolute;
  z-index: var(--appgenio-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--appgenio-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--appgenio-toast-padding-y) var(--appgenio-toast-padding-x);
  color: var(--appgenio-toast-header-color);
  background-color: var(--appgenio-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--appgenio-toast-border-width) solid var(--appgenio-toast-header-border-color);
  border-top-left-radius: calc(var(--appgenio-toast-border-radius) - var(--appgenio-toast-border-width));
  border-top-right-radius: calc(var(--appgenio-toast-border-radius) - var(--appgenio-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--appgenio-toast-padding-x));
  margin-left: var(--appgenio-toast-padding-x);
}

.toast-body {
  padding: var(--appgenio-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --appgenio-modal-zindex: 1055;
  --appgenio-modal-width: 500px;
  --appgenio-modal-padding: 1rem;
  --appgenio-modal-margin: 0.5rem;
  --appgenio-modal-color: ;
  --appgenio-modal-bg: #ffffff;
  --appgenio-modal-border-color: var(--appgenio-border-color-translucent);
  --appgenio-modal-border-width: 1px;
  --appgenio-modal-border-radius: 0.5rem;
  --appgenio-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --appgenio-modal-inner-border-radius: calc(0.5rem - 1px);
  --appgenio-modal-header-padding-x: 1rem;
  --appgenio-modal-header-padding-y: 1rem;
  --appgenio-modal-header-padding: 1rem 1rem;
  --appgenio-modal-header-border-color: var(--appgenio-border-color);
  --appgenio-modal-header-border-width: 1px;
  --appgenio-modal-title-line-height: 1.5;
  --appgenio-modal-footer-gap: 0.5rem;
  --appgenio-modal-footer-bg: ;
  --appgenio-modal-footer-border-color: var(--appgenio-border-color);
  --appgenio-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--appgenio-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--appgenio-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--appgenio-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--appgenio-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--appgenio-modal-color);
  pointer-events: auto;
  background-color: var(--appgenio-modal-bg);
  background-clip: padding-box;
  border: var(--appgenio-modal-border-width) solid var(--appgenio-modal-border-color);
  border-radius: var(--appgenio-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --appgenio-backdrop-zindex: 1050;
  --appgenio-backdrop-bg: #000000;
  --appgenio-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--appgenio-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--appgenio-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--appgenio-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--appgenio-modal-header-padding);
  border-bottom: var(--appgenio-modal-header-border-width) solid var(--appgenio-modal-header-border-color);
  border-top-left-radius: var(--appgenio-modal-inner-border-radius);
  border-top-right-radius: var(--appgenio-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--appgenio-modal-header-padding-y) * 0.5) calc(var(--appgenio-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--appgenio-modal-header-padding-y)) calc(-0.5 * var(--appgenio-modal-header-padding-x)) calc(-0.5 * var(--appgenio-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--appgenio-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--appgenio-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--appgenio-modal-padding) - var(--appgenio-modal-footer-gap) * 0.5);
  background-color: var(--appgenio-modal-footer-bg);
  border-top: var(--appgenio-modal-footer-border-width) solid var(--appgenio-modal-footer-border-color);
  border-bottom-right-radius: var(--appgenio-modal-inner-border-radius);
  border-bottom-left-radius: var(--appgenio-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--appgenio-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --appgenio-modal-margin: 1.75rem;
    --appgenio-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--appgenio-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --appgenio-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --appgenio-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --appgenio-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --appgenio-tooltip-zindex: 1080;
  --appgenio-tooltip-max-width: 200px;
  --appgenio-tooltip-padding-x: 0.5rem;
  --appgenio-tooltip-padding-y: 0.25rem;
  --appgenio-tooltip-margin: ;
  --appgenio-tooltip-font-size: 0.875rem;
  --appgenio-tooltip-color: #ffffff;
  --appgenio-tooltip-bg: #000000;
  --appgenio-tooltip-border-radius: 0.375rem;
  --appgenio-tooltip-opacity: 0.9;
  --appgenio-tooltip-arrow-width: 0.8rem;
  --appgenio-tooltip-arrow-height: 0.4rem;
  z-index: var(--appgenio-tooltip-zindex);
  display: block;
  padding: var(--appgenio-tooltip-arrow-height);
  margin: var(--appgenio-tooltip-margin);
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--appgenio-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--appgenio-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--appgenio-tooltip-arrow-width);
  height: var(--appgenio-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--appgenio-tooltip-arrow-height) calc(var(--appgenio-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--appgenio-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--appgenio-tooltip-arrow-height);
  height: var(--appgenio-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--appgenio-tooltip-arrow-width) * 0.5) var(--appgenio-tooltip-arrow-height) calc(var(--appgenio-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--appgenio-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--appgenio-tooltip-arrow-width) * 0.5) var(--appgenio-tooltip-arrow-height);
  border-bottom-color: var(--appgenio-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--appgenio-tooltip-arrow-height);
  height: var(--appgenio-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--appgenio-tooltip-arrow-width) * 0.5) 0 calc(var(--appgenio-tooltip-arrow-width) * 0.5) var(--appgenio-tooltip-arrow-height);
  border-left-color: var(--appgenio-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--appgenio-tooltip-max-width);
  padding: var(--appgenio-tooltip-padding-y) var(--appgenio-tooltip-padding-x);
  color: var(--appgenio-tooltip-color);
  text-align: center;
  background-color: var(--appgenio-tooltip-bg);
  border-radius: var(--appgenio-tooltip-border-radius);
}

.popover {
  --appgenio-popover-zindex: 1070;
  --appgenio-popover-max-width: 276px;
  --appgenio-popover-font-size: 0.875rem;
  --appgenio-popover-bg: #ffffff;
  --appgenio-popover-border-width: 1px;
  --appgenio-popover-border-color: var(--appgenio-border-color-translucent);
  --appgenio-popover-border-radius: 0.5rem;
  --appgenio-popover-inner-border-radius: calc(0.5rem - 1px);
  --appgenio-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --appgenio-popover-header-padding-x: 1rem;
  --appgenio-popover-header-padding-y: 0.5rem;
  --appgenio-popover-header-font-size: 1rem;
  --appgenio-popover-header-color: ;
  --appgenio-popover-header-bg: #f0f0f0;
  --appgenio-popover-body-padding-x: 1rem;
  --appgenio-popover-body-padding-y: 1rem;
  --appgenio-popover-body-color: #212529;
  --appgenio-popover-arrow-width: 1rem;
  --appgenio-popover-arrow-height: 0.5rem;
  --appgenio-popover-arrow-border: var(--appgenio-popover-border-color);
  z-index: var(--appgenio-popover-zindex);
  display: block;
  max-width: var(--appgenio-popover-max-width);
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--appgenio-popover-font-size);
  word-wrap: break-word;
  background-color: var(--appgenio-popover-bg);
  background-clip: padding-box;
  border: var(--appgenio-popover-border-width) solid var(--appgenio-popover-border-color);
  border-radius: var(--appgenio-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--appgenio-popover-arrow-width);
  height: var(--appgenio-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--appgenio-popover-arrow-height)) - var(--appgenio-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--appgenio-popover-arrow-height) calc(var(--appgenio-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--appgenio-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--appgenio-popover-border-width);
  border-top-color: var(--appgenio-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--appgenio-popover-arrow-height)) - var(--appgenio-popover-border-width));
  width: var(--appgenio-popover-arrow-height);
  height: var(--appgenio-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--appgenio-popover-arrow-width) * 0.5) var(--appgenio-popover-arrow-height) calc(var(--appgenio-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--appgenio-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--appgenio-popover-border-width);
  border-right-color: var(--appgenio-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--appgenio-popover-arrow-height)) - var(--appgenio-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--appgenio-popover-arrow-width) * 0.5) var(--appgenio-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--appgenio-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--appgenio-popover-border-width);
  border-bottom-color: var(--appgenio-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--appgenio-popover-arrow-width);
  margin-left: calc(-0.5 * var(--appgenio-popover-arrow-width));
  content: "";
  border-bottom: var(--appgenio-popover-border-width) solid var(--appgenio-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--appgenio-popover-arrow-height)) - var(--appgenio-popover-border-width));
  width: var(--appgenio-popover-arrow-height);
  height: var(--appgenio-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--appgenio-popover-arrow-width) * 0.5) 0 calc(var(--appgenio-popover-arrow-width) * 0.5) var(--appgenio-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--appgenio-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--appgenio-popover-border-width);
  border-left-color: var(--appgenio-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--appgenio-popover-header-padding-y) var(--appgenio-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--appgenio-popover-header-font-size);
  color: var(--appgenio-popover-header-color);
  background-color: var(--appgenio-popover-header-bg);
  border-bottom: var(--appgenio-popover-border-width) solid var(--appgenio-popover-border-color);
  border-top-left-radius: var(--appgenio-popover-inner-border-radius);
  border-top-right-radius: var(--appgenio-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--appgenio-popover-body-padding-y) var(--appgenio-popover-body-padding-x);
  color: var(--appgenio-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000000;
}
.carousel-dark .carousel-caption {
  color: #000000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--appgenio-spinner-width);
  height: var(--appgenio-spinner-height);
  vertical-align: var(--appgenio-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--appgenio-spinner-animation-speed) linear infinite var(--appgenio-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --appgenio-spinner-width: 2rem;
  --appgenio-spinner-height: 2rem;
  --appgenio-spinner-vertical-align: -0.125em;
  --appgenio-spinner-border-width: 0.25em;
  --appgenio-spinner-animation-speed: 0.75s;
  --appgenio-spinner-animation-name: spinner-border;
  border: var(--appgenio-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --appgenio-spinner-width: 1rem;
  --appgenio-spinner-height: 1rem;
  --appgenio-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --appgenio-spinner-width: 2rem;
  --appgenio-spinner-height: 2rem;
  --appgenio-spinner-vertical-align: -0.125em;
  --appgenio-spinner-animation-speed: 0.75s;
  --appgenio-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --appgenio-spinner-width: 1rem;
  --appgenio-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --appgenio-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --appgenio-offcanvas-zindex: 1045;
  --appgenio-offcanvas-width: 400px;
  --appgenio-offcanvas-height: 30vh;
  --appgenio-offcanvas-padding-x: 1rem;
  --appgenio-offcanvas-padding-y: 1rem;
  --appgenio-offcanvas-color: ;
  --appgenio-offcanvas-bg: #ffffff;
  --appgenio-offcanvas-border-width: 1px;
  --appgenio-offcanvas-border-color: var(--appgenio-border-color-translucent);
  --appgenio-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--appgenio-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--appgenio-offcanvas-color);
    visibility: hidden;
    background-color: var(--appgenio-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--appgenio-offcanvas-width);
    border-right: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--appgenio-offcanvas-width);
    border-left: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--appgenio-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--appgenio-offcanvas-height);
    max-height: 100%;
    border-top: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --appgenio-offcanvas-height: auto;
    --appgenio-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--appgenio-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--appgenio-offcanvas-color);
    visibility: hidden;
    background-color: var(--appgenio-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--appgenio-offcanvas-width);
    border-right: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--appgenio-offcanvas-width);
    border-left: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--appgenio-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--appgenio-offcanvas-height);
    max-height: 100%;
    border-top: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --appgenio-offcanvas-height: auto;
    --appgenio-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--appgenio-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--appgenio-offcanvas-color);
    visibility: hidden;
    background-color: var(--appgenio-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--appgenio-offcanvas-width);
    border-right: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--appgenio-offcanvas-width);
    border-left: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--appgenio-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--appgenio-offcanvas-height);
    max-height: 100%;
    border-top: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --appgenio-offcanvas-height: auto;
    --appgenio-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--appgenio-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--appgenio-offcanvas-color);
    visibility: hidden;
    background-color: var(--appgenio-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--appgenio-offcanvas-width);
    border-right: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--appgenio-offcanvas-width);
    border-left: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--appgenio-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--appgenio-offcanvas-height);
    max-height: 100%;
    border-top: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --appgenio-offcanvas-height: auto;
    --appgenio-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--appgenio-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--appgenio-offcanvas-color);
    visibility: hidden;
    background-color: var(--appgenio-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--appgenio-offcanvas-width);
    border-right: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--appgenio-offcanvas-width);
    border-left: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--appgenio-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--appgenio-offcanvas-height);
    max-height: 100%;
    border-top: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --appgenio-offcanvas-height: auto;
    --appgenio-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--appgenio-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--appgenio-offcanvas-color);
  visibility: hidden;
  background-color: var(--appgenio-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--appgenio-offcanvas-width);
  border-right: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--appgenio-offcanvas-width);
  border-left: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--appgenio-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--appgenio-offcanvas-height);
  max-height: 100%;
  border-top: var(--appgenio-offcanvas-border-width) solid var(--appgenio-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--appgenio-offcanvas-padding-y) var(--appgenio-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--appgenio-offcanvas-padding-y) * 0.5) calc(var(--appgenio-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--appgenio-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--appgenio-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--appgenio-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--appgenio-offcanvas-padding-y) var(--appgenio-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000000 55%, rgba(0, 0, 0, 0.8) 75%, #000000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-dark {
  color: #ffffff !important;
  background-color: RGBA(27, 27, 27, var(--appgenio-bg-opacity, 1)) !important;
}

.text-bg-black {
  color: #ffffff !important;
  background-color: RGBA(0, 0, 0, var(--appgenio-bg-opacity, 1)) !important;
}

.text-bg-white {
  color: #000000 !important;
  background-color: RGBA(255, 255, 255, var(--appgenio-bg-opacity, 1)) !important;
}

.text-bg-gray {
  color: #000000 !important;
  background-color: RGBA(242, 242, 242, var(--appgenio-bg-opacity, 1)) !important;
}

.link-dark {
  color: #1b1b1b !important;
}
.link-dark:hover, .link-dark:focus {
  color: #161616 !important;
}

.link-black {
  color: #000000 !important;
}
.link-black:hover, .link-black:focus {
  color: black !important;
}

.link-white {
  color: #ffffff !important;
}
.link-white:hover, .link-white:focus {
  color: white !important;
}

.link-gray {
  color: #f2f2f2 !important;
}
.link-gray:hover, .link-gray:focus {
  color: whitesmoke !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--appgenio-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --appgenio-aspect-ratio: 100%;
}

.ratio-4x3 {
  --appgenio-aspect-ratio: 75%;
}

.ratio-16x9 {
  --appgenio-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --appgenio-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--appgenio-border-width) var(--appgenio-border-style) var(--appgenio-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--appgenio-border-width) var(--appgenio-border-style) var(--appgenio-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--appgenio-border-width) var(--appgenio-border-style) var(--appgenio-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--appgenio-border-width) var(--appgenio-border-style) var(--appgenio-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--appgenio-border-width) var(--appgenio-border-style) var(--appgenio-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-dark {
  --appgenio-border-opacity: 1;
  border-color: rgba(var(--appgenio-dark-rgb), var(--appgenio-border-opacity)) !important;
}

.border-black {
  --appgenio-border-opacity: 1;
  border-color: rgba(var(--appgenio-black-rgb), var(--appgenio-border-opacity)) !important;
}

.border-white {
  --appgenio-border-opacity: 1;
  border-color: rgba(var(--appgenio-white-rgb), var(--appgenio-border-opacity)) !important;
}

.border-gray {
  --appgenio-border-opacity: 1;
  border-color: rgba(var(--appgenio-gray-rgb), var(--appgenio-border-opacity)) !important;
}

.border-1 {
  --appgenio-border-width: 1px;
}

.border-2 {
  --appgenio-border-width: 2px;
}

.border-3 {
  --appgenio-border-width: 3px;
}

.border-4 {
  --appgenio-border-width: 4px;
}

.border-5 {
  --appgenio-border-width: 5px;
}

.border-opacity-10 {
  --appgenio-border-opacity: 0.1;
}

.border-opacity-25 {
  --appgenio-border-opacity: 0.25;
}

.border-opacity-50 {
  --appgenio-border-opacity: 0.5;
}

.border-opacity-75 {
  --appgenio-border-opacity: 0.75;
}

.border-opacity-100 {
  --appgenio-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--appgenio-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-dark {
  --appgenio-text-opacity: 1;
  color: rgba(var(--appgenio-dark-rgb), var(--appgenio-text-opacity)) !important;
}

.text-black {
  --appgenio-text-opacity: 1;
  color: rgba(var(--appgenio-black-rgb), var(--appgenio-text-opacity)) !important;
}

.text-white {
  --appgenio-text-opacity: 1;
  color: rgba(var(--appgenio-white-rgb), var(--appgenio-text-opacity)) !important;
}

.text-gray {
  --appgenio-text-opacity: 1;
  color: rgba(var(--appgenio-gray-rgb), var(--appgenio-text-opacity)) !important;
}

.text-body {
  --appgenio-text-opacity: 1;
  color: rgba(var(--appgenio-body-color-rgb), var(--appgenio-text-opacity)) !important;
}

.text-muted {
  --appgenio-text-opacity: 1;
  color: #999999 !important;
}

.text-black-50 {
  --appgenio-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --appgenio-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --appgenio-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --appgenio-text-opacity: 0.25;
}

.text-opacity-50 {
  --appgenio-text-opacity: 0.5;
}

.text-opacity-75 {
  --appgenio-text-opacity: 0.75;
}

.text-opacity-100 {
  --appgenio-text-opacity: 1;
}

.bg-dark {
  --appgenio-bg-opacity: 1;
  background-color: rgba(var(--appgenio-dark-rgb), var(--appgenio-bg-opacity)) !important;
}

.bg-black {
  --appgenio-bg-opacity: 1;
  background-color: rgba(var(--appgenio-black-rgb), var(--appgenio-bg-opacity)) !important;
}

.bg-white {
  --appgenio-bg-opacity: 1;
  background-color: rgba(var(--appgenio-white-rgb), var(--appgenio-bg-opacity)) !important;
}

.bg-gray {
  --appgenio-bg-opacity: 1;
  background-color: rgba(var(--appgenio-gray-rgb), var(--appgenio-bg-opacity)) !important;
}

.bg-body {
  --appgenio-bg-opacity: 1;
  background-color: rgba(var(--appgenio-body-bg-rgb), var(--appgenio-bg-opacity)) !important;
}

.bg-transparent {
  --appgenio-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --appgenio-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --appgenio-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --appgenio-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --appgenio-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --appgenio-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--appgenio-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--appgenio-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--appgenio-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--appgenio-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--appgenio-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--appgenio-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--appgenio-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--appgenio-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--appgenio-border-radius) !important;
  border-top-right-radius: var(--appgenio-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--appgenio-border-radius) !important;
  border-bottom-right-radius: var(--appgenio-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--appgenio-border-radius) !important;
  border-bottom-left-radius: var(--appgenio-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--appgenio-border-radius) !important;
  border-top-left-radius: var(--appgenio-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
html {
  scroll-behavior: smooth;
  font-size: 100%;
}

body {
  position: relative;
  font-family: "Montserrat", sans-serif;
  color: #000000;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.5s ease-in-out;
  font-size: 1rem;
  line-height: 26px;
}

.container {
  position: relative;
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
  max-width: 1920px;
  padding: 0px 40px;
}

@media screen and (min-width: 992px) {
  .container-sm {
    max-width: 970px;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}
@media screen and (min-width: 1600px) {
  .container {
    max-width: 1400px;
  }
  .container-sm {
    max-width: 1170px;
  }
}
@media screen and (max-width: 1199px) {
  .container {
    max-width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .container-sm {
    max-width: 100%;
  }
}
.wrapper {
  position: relative;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "Montserrat", sans-serif;
  margin-top: 0px;
  margin-bottom: 0px;
}

h1, .h1 {
  font-size: calc(1.425rem + 1.8vw);
  font-weight: 700;
}
@media (min-width: 1400px) {
  h1, .h1 {
    font-size: 3rem;
  }
}

h2, .h2 {
  font-size: calc(1.35rem + 1.0285714286vw);
  font-weight: 700;
}
@media (min-width: 1400px) {
  h2, .h2 {
    font-size: 2.25rem;
  }
}

h3, .h3 {
  font-size: calc(1.3125rem + 0.6428571429vw);
  font-weight: 700;
}
@media (min-width: 1400px) {
  h3, .h3 {
    font-size: 1.875rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.2571428571vw);
  font-weight: 700;
}
@media (min-width: 1400px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
  font-weight: 600;
}

h6, .h6 {
  font-size: 1rem;
  font-weight: 600;
}

p {
  line-height: 26px;
  font-weight: 400;
}

a {
  color: #1b1b1b;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  outline: none;
}
a:hover {
  color: #a6a182;
  text-decoration: none;
  outline: none;
}

.bg-dark {
  color: #999999;
}
.bg-dark a {
  color: #ffffff;
}
.bg-dark a:hover, .bg-dark a.active {
  color: #a6a182;
}

.clearfix {
  clear: both;
}

.divider {
  margin: 30px 0px;
  display: inline-block;
}

table {
  margin-bottom: 10px;
  border: 1px solid #eee;
}

table td, table th {
  padding: 3px 10px;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  text-align: center;
}

img {
  max-width: 100%;
}

.section, section {
  position: relative;
  padding: 0px 0px;
  z-index: 1;
}

.large_font {
  position: absolute;
  top: 85px;
  width: 100%;
}
.large_font h2, .large_font .h2 {
  font-size: calc(2.625rem + 14.1428571429vw);
  line-height: calc(2.475rem + 12.6vw);
  letter-spacing: 1.25rem;
  text-align: center;
  text-transform: uppercase;
}
@media (min-width: 1400px) {
  .large_font h2, .large_font .h2 {
    font-size: 15rem;
  }
}
@media (min-width: 1400px) {
  .large_font h2, .large_font .h2 {
    line-height: 13.5rem;
  }
}

.large_font_bottom {
  top: 217px;
}

/* Default Input type */
select,
input[type=text],
input[type=email],
input[type=url],
input[type=password],
input[type=search],
input[type=number],
input[type=tel],
input[type=range],
input[type=date],
input[type=month],
input[type=week],
input[type=time],
input[type=datetime],
input[type=datetime-local],
input[type=color] {
  display: block;
  width: 100%;
  height: 64px;
  padding: 10px 40px;
  font-size: 0.75rem;
  font-weight: 600;
  background-color: #000000;
  color: #b2b2b2;
  background-clip: padding-box;
  border-width: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  transition: all 0.5s ease-in-out;
  backface-visibility: hidden;
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
  outline-offset: 0;
}

textarea.form-control {
  display: block;
  width: 100%;
  height: 150px;
  padding: 10px 40px;
  font-size: 0.75rem;
  font-weight: 600;
  background-color: #000000;
  color: #b2b2b2;
  background-clip: padding-box;
  border-width: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  transition: all 0.5s ease-in-out;
  backface-visibility: hidden;
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
  outline-offset: 0;
}

input[type=text]:focus,
input[type=email]:focus,
input[type=url]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=number]:focus,
input[type=tel]:focus,
input[type=range]:focus,
input[type=date]:focus,
input[type=month]:focus,
input[type=week]:focus,
input[type=time]:focus,
input[type=datetime]:focus,
input[type=datetime-local]:focus,
input[type=color]:focus,
textarea.form-control:focus {
  background-color: #1b1b1b;
  border-color: #1b1b1b;
  color: #ffffff;
  outline: none;
  outline-offset: 0px;
  box-shadow: none;
  transition: all 0.5s ease-in-out;
}

label {
  display: inline-block;
  color: #b2b2b2;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}

::-webkit-input-placeholder {
  color: #b2b2b2;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}

::-moz-placeholder {
  color: #b2b2b2;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}

:-ms-input-placeholder {
  color: #b2b2b2;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}

:-moz-placeholder {
  color: #b2b2b2;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}

:placeholder {
  color: #b2b2b2;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  background-color: #2e2e2e;
}

.bg_1 {
  background: url("../../assets/img/bg/bg-1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg_2 {
  background: url("../../assets/img/bg/bg-1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/*---------- Button Section ------------*/
.btn_group {
  position: relative;
  z-index: 1;
  display: block;
}

.btn {
  position: relative;
  width: auto;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  outline: none;
  padding: 0.75rem calc(1.41875rem + 1.7357142857vw);
  background-color: #000000;
  color: #ffffff;
  border: none;
  border-radius: 0rem;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.5s ease-in-out;
}
@media (min-width: 1400px) {
  .btn {
    padding: 0.75rem 2.9375rem;
  }
}
.btn:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 9px;
  left: 9px;
  background-image: url(/assets/img/btn-texture-black.png);
  border: 1px solid #000000;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: -1;
  transition: left 0.3s ease-out 0.3s, top 0.3s ease-out 0.3s;
}
.btn:hover {
  background-color: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  border-color: transparent;
}
.btn:hover:before {
  top: 0;
  left: 0;
}
.btn.white {
  background-color: #ffffff;
  color: #000000;
}
.btn.white:before {
  background-image: url(/assets/img/btn-texture-white.png);
  border-color: #ffffff;
}
.btn.white:hover {
  background-color: rgba(255, 255, 255, 0.6);
  color: #000000;
}
.btn.gray {
  background-color: #54595f;
  color: #ffffff;
}
.btn.gray:before {
  background-image: url(/assets/img/btn-texture-gray.png);
  border-color: #54595f;
}
.btn.gray:hover {
  background-color: rgba(84, 89, 95, 0.6);
  color: #ffffff;
}
.btn.olive {
  background-color: #a6a182;
  color: #ffffff;
}
.btn.olive:before {
  background-image: url(/assets/img/btn-texture-olive.png);
  border-color: #a6a182;
}
.btn.olive:hover {
  background-color: rgba(166, 161, 130, 0.6);
  color: #ffffff;
}

.btn_2 {
  position: relative;
  width: auto;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  outline: none;
  padding: 0.75rem calc(1.41875rem + 1.7357142857vw);
  background-color: #000000;
  color: #ffffff;
  border: none;
  border-radius: 0rem;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
}
@media (min-width: 1400px) {
  .btn_2 {
    padding: 0.75rem 2.9375rem;
  }
}
.btn_2::before, .btn_2::after {
  box-sizing: inherit;
  position: absolute;
  content: "";
  border: 1px solid transparent;
  width: 0;
  height: 0;
}
.btn_2::before {
  top: 0;
  left: 0;
}
.btn_2::after {
  bottom: 0;
  right: 0;
}
.btn_2:hover::before, .btn_2:hover::after {
  width: 100%;
  height: 100%;
}
.btn_2:hover::before {
  border-top-color: #a6a182;
  border-right-color: #a6a182;
  transition: width 0.3s ease-out, height 0.3s ease-out 0.3s;
}
.btn_2:hover::after {
  border-bottom-color: #a6a182;
  border-left-color: #a6a182;
  transition: border-color 0s ease-out 0.6s, width 0.3s ease-out 0.6s, height 0.3s ease-out 1s;
}

.details_link {
  font-size: 0.6875rem;
  font-weight: 600;
  line-height: 1.375rem;
  text-transform: uppercase;
  color: #a6a182;
  margin-bottom: 0px;
  transition-delay: 0.9s;
}
.details_link a {
  color: #a6a182;
  display: flex;
  align-items: center;
}
.details_link a .link_text {
  margin-right: 1.875rem;
}
.details_link a .link_icon {
  position: relative;
  display: inline-flex;
  align-items: center;
}
.details_link a .link_icon .line {
  position: absolute;
  right: 50%;
  top: 49.4%;
  width: 2.1875rem;
  height: 0.0625rem;
  display: inline-block;
  background-color: #a6a182;
  z-index: 1;
  transform-origin: right;
  transition: all 0.5s ease-in-out;
}
.details_link a .link_icon .circle {
  position: relative;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 100%;
  display: inline-block;
  border: 1px solid #a6a182;
}
.details_link a .link_icon .dot {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 100%;
  display: inline-block;
  background-color: #a6a182;
}
.details_link a:hover .link_icon .line {
  animation: scaleAnim1 2s infinite linear;
}
.details_link a:hover .link_icon .circle {
  animation: rotateAnim 7s infinite linear;
  border-style: dashed;
}
.details_link a:hover .link_icon .dot {
  animation: scaleAnim2 2s infinite ease-in-out;
}
.details_link.l-dir a .link_icon .line {
  right: auto;
  left: calc(50% + 5px);
  transform-origin: left;
}
.details_link.l-dir a .link_icon .dot {
  width: 0px;
  height: 0px;
  border-radius: 0;
  display: inline-block;
  background-color: transparent;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 0px solid transparent;
  border-right: 10px solid #a6a182;
  animation: none;
}
.details_link.r-dir a .link_icon .line {
  left: auto;
  right: calc(50% + 5px);
  transform-origin: right;
}
.details_link.r-dir a .link_icon .dot {
  width: 0px;
  height: 0px;
  border-radius: 0;
  display: inline-block;
  background-color: transparent;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 10px solid #a6a182;
  border-right: 0px solid transparent;
  animation: none;
}

@keyframes scaleAnim1 {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes rotateAnim {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes scaleAnim2 {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(0.8);
    background: #ffffff;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
.header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  background-color: transparent;
  z-index: 9;
  transition: all 0.5s ease-in-out;
}
.header .header_inner {
  padding: 1.875rem 0rem;
  transition: all 0.5s ease-in-out;
}
.header.sticky {
  transform: perspective(300px) rotateX(0deg);
  transform-origin: top;
}
.header.sticky .header_inner {
  padding: 0.625rem 0rem;
  transition: all 0.5s ease-in-out;
}
.header.top-up {
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  transform: perspective(300px) rotateX(-90deg);
  transform-origin: top;
}
.header .logo {
  position: relative;
  flex-shrink: 0;
}
.header .logo a {
  display: inline-block;
}
.header .logo a img {
  width: 100%;
  max-width: 165px;
  transition: all 0.5s ease-in-out;
}
.header .logo a.dark_logo {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.header .mainnav ul.main_menu {
  padding: 0rem;
  margin: 0rem;
}
.header .mainnav ul.main_menu li {
  position: relative;
  border: 0;
  list-style: none;
  line-height: 1;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
}
.header .mainnav ul.main_menu li a {
  border: 0;
  list-style: none;
  display: inline-block;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  color: #ffffff;
  padding: 1.875rem 0rem;
  letter-spacing: 1.2px;
}
.header .mainnav ul.main_menu li:hover > ul {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0px, 0px, 0px);
}
.header .mainnav ul.main_menu li.current > a, .header .mainnav ul.main_menu li.active > a, .header .mainnav ul.main_menu li:hover > a {
  color: #a6a182;
}
.header .mainnav ul.main_menu li ul {
  position: absolute;
  left: 0;
  opacity: 0;
  visibility: hidden;
  min-width: 12.5rem;
  margin: 0rem 0rem;
  padding: 0.9375rem 1.5625rem;
  z-index: 5;
  transition: all 0.5s ease-in-out;
  transform: translate3d(15px, 0px, 0px);
  background: #212121;
}
.header .mainnav ul.main_menu li ul li {
  width: 100%;
}
.header .mainnav ul.main_menu li ul li a {
  font-size: 0.6875rem;
  padding: 0.625rem 0rem;
  width: 100%;
  line-height: 0.875rem;
  letter-spacing: 1.5px;
  text-align: left;
}
.header .mainnav ul.main_menu li ul li a:hover {
  color: #a6a182;
}
.header .mainnav ul.main_menu li ul li:last-child > a {
  border-bottom: none;
}
.header .mainnav ul.main_menu li ul li.menu-item-has-children > a:after {
  content: "\f285";
  font-family: "bootstrap-icons";
  speak: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.header .mainnav ul.main_menu li ul ul {
  left: calc(100% + 26px);
  top: -15px;
  border-top: 0px solid #a6a182;
}
.header .mainnav ul.main_menu li ul ul ul ul {
  left: auto;
  right: calc(100% + 26px);
}
.header .mainnav ul.main_menu > li:not(:last-child) > a {
  padding-right: calc(1.5625rem + 3.2142857143vw);
}
@media (min-width: 1400px) {
  .header .mainnav ul.main_menu > li:not(:last-child) > a {
    padding-right: 4.375rem;
  }
}
.header .mainnav ul.main_menu > li.menu-item-has-children > a::after {
  content: "\f282";
  color: #ffffff;
  font-family: "bootstrap-icons";
  speak: none;
  transition: all 0.5s ease-in-out;
  display: inline-block;
  margin-left: 5px;
  font-size: 10px;
  line-height: normal;
}
.header .mainnav ul.main_menu > li.menu-item-has-children.current a::after, .header .mainnav ul.main_menu > li.menu-item-has-children.active a::after, .header .mainnav ul.main_menu > li.menu-item-has-children:hover a::after {
  color: #a6a182;
}
.header .ma5menu__toggle {
  background: transparent;
  width: 0.75rem;
  height: 0.75rem;
  line-height: 0.75rem;
  padding: 0rem;
  color: #999999;
  font-size: 0.75rem;
  transition: all 0.5s ease-in-out;
  flex-shrink: 0;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
.header .ma5menu__toggle:focus {
  outline: none;
}
.header .ma5menu__toggle:hover {
  color: #a6a182;
}
.header .header .ma5menu__toggle {
  background: transparent;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  padding: 0rem;
  color: #999999;
  font-size: 1.5rem;
  transition: all 0.5s ease-in-out;
  flex-shrink: 0;
  margin-left: 1.25rem;
}
.header .header_search {
  padding-left: 1.25rem;
  border-left: 0.0625rem solid #999999;
  line-height: 1.125rem;
  transition: all 0.5s ease-in-out;
}
.header .header_search .form-control-submit {
  background: transparent;
  font-size: 1rem;
  line-height: 1.125rem;
  color: #999999;
  border: none;
  padding: 0rem;
  cursor: pointer;
}
.header .header_search .form-control-submit:focus {
  outline: none;
}
.header .open_search {
  position: absolute;
  top: 100%;
  right: 0;
  transform: translateY(-30px);
  margin: 0px 15px 0px 0px;
  width: calc(3.4375rem + 22.5vw);
  height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  z-index: 3;
}
@media (min-width: 1400px) {
  .header .open_search {
    width: 23.125rem;
  }
}
.header .open_search.active {
  height: 3.75rem;
}
.header .open_search .search_form {
  width: 100%;
  max-width: 100%;
}
.header .open_search .search_form .form-control {
  height: 3.75rem;
  border-radius: 0rem;
  padding: 0.3125rem 1.5625rem;
  background: #ffffff;
  color: #000000;
}
.header .open_search .search_form .form-control-submit {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0px;
  background: transparent;
  color: #000000;
  padding: 0.625rem;
  font-size: 1rem;
  cursor: pointer;
}

.instala_app {
  position: relative;
  background: transparent;
  color: #999999;
  padding: 0rem;
  border: none;
  outline: none;
  width: 0.875rem;
  margin-right: 1.25rem;
  margin-left: 1.25rem;
}
.instala_app .line {
  background-color: #999999;
  width: 100%;
  height: 0.125rem;
  display: block;
  float: right;
  transition: all 0.5s ease-in-out;
}
.instala_app .line:not(:last-child) {
  margin-bottom: 0.25rem;
}
.instala_app .line:nth-child(2) {
  width: 0.75rem;
}
.instala_app:hover .line:nth-child(2) {
  width: 0.875rem;
}

.aside_open {
  position: relative;
  background: transparent;
  padding: 0rem;
  border: none;
  outline: none;
  width: 0.875rem;
  margin-right: 1.25rem;
}
.aside_open .line {
  background-color: #999999;
  width: 100%;
  height: 0.125rem;
  display: block;
  float: right;
  transition: all 0.5s ease-in-out;
}
.aside_open .line:not(:last-child) {
  margin-bottom: 0.25rem;
}
.aside_open .line:nth-child(2) {
  width: 0.75rem;
}
.aside_open:hover .line:nth-child(2) {
  width: 0.875rem;
}

.aside_info_wrapper {
  padding: calc(1.375rem + 1.2857142857vw) calc(1.4375rem + 1.9285714286vw);
  background: #212121;
  max-width: calc(4.875rem + 37.2857142857vw);
  height: 100%;
  text-align: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: calc(-4.375rem - 32.1428571429vw);
  overflow-y: auto;
  z-index: 99;
  transition: all 0.5s ease-in-out;
  transform: scale(0);
}
@media (min-width: 1400px) {
  .aside_info_wrapper {
    padding: 2.5rem 3.125rem;
  }
}
@media (min-width: 1400px) {
  .aside_info_wrapper {
    max-width: 37.5rem;
  }
}
@media (min-width: 1400px) {
  .aside_info_wrapper {
    right: -32.5rem;
  }
}
.aside_info_wrapper.show {
  right: 0;
  transform: scale(1);
}
.aside_info_wrapper .aside_close {
  font-size: 1.5rem;
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  padding: 0;
  background-color: transparent;
  border: none;
  margin-bottom: 55px;
}
.aside_info_wrapper::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
.aside_info_wrapper .aside_logo {
  position: relative;
}
.aside_info_wrapper .aside_logo a {
  display: inline-block;
}
.aside_info_wrapper .aside_logo a img {
  max-width: 160px;
  transition: all 0.5s ease-in-out;
}
.aside_info_wrapper .aside_logo a.dark_logo {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
}
.aside_info_wrapper .aside_info_inner {
  margin-top: 25px;
  padding: 0px 15px;
}
.aside_info_wrapper .aside_info_inner h5, .aside_info_wrapper .aside_info_inner .h5 {
  display: inline-block;
  color: #ffffff;
  margin-top: 1.875rem;
  margin-bottom: 0.9375rem;
}
.aside_info_wrapper .aside_info_inner .aside_info_inner_box {
  margin-top: 1.875rem;
}
.aside_info_wrapper .aside_info_inner .aside_info_inner_box p {
  font-size: 0.9375rem;
}
.aside_info_wrapper .aside_info_inner .social_sites {
  margin-top: 1.875rem;
}
.aside_info_wrapper .aside_info_inner .social_sites ul {
  list-style-type: none;
  padding: 0rem;
}
.aside_info_wrapper .aside_info_inner .social_sites ul li:not(:last-child) {
  margin-right: 0.625rem;
}
.aside_info_wrapper .aside_info_inner .social_sites ul li a {
  color: #ffffff;
  background-color: #2e2e2e;
  display: inline-block;
  width: 1.875rem;
  height: 1.875rem;
  font-size: 0.875rem;
  text-align: center;
  line-height: 1.875rem;
  border-radius: 50%;
}

.mission_bottom_part .row:hover {
  border-style: dotted;
  border-color: gray;
  border-width: 3px;
}

.divider_bg {
  position: relative;
  z-index: 1;
  width: calc(100% - 260px);
  height: 5rem;
  margin-top: calc(1.71875rem + 4.8214285714vw);
}
@media (min-width: 1400px) {
  .divider_bg {
    margin-top: 5.9375rem;
  }
}

.slide_content .slide_content_wrapper {
  position: relative;
  overflow: hidden;
  height: auto;
  transition: all 0.5s ease-in-out;
  margin-bottom: 0rem;
}
.slide_content .slide_content_wrapper .slide_content_inner {
  position: relative;
  padding: 20px 30px 20px 30px;
  z-index: 1;
  transition: all 0.5s ease-in-out;
}
.slide_content .slide_content_wrapper .slide_content_inner .meta {
  margin-top: 0px;
  margin-bottom: 0px;
}

/*-- Theme Slider --*/
.theme_slider {
  position: relative;
  padding: calc(2.3125rem + 10.9285714286vw) 0rem 0rem 0rem;
}
@media (min-width: 1400px) {
  .theme_slider {
    padding: 11.875rem 0rem 0rem 0rem;
  }
}
.theme_slider .swiper {
  padding-bottom: calc(1.5rem + 2.5714285714vw);
}
@media (min-width: 1400px) {
  .theme_slider .swiper {
    padding-bottom: 3.75rem;
  }
}
.theme_slider .swiper-slide {
  padding-right: calc(2.0938rem + 8.6790857143vw);
}
@media (min-width: 1400px) {
  .theme_slider .swiper-slide {
    padding-right: 9.688rem;
  }
}
.theme_slider .swiper-slide .slider {
  background-repeat: repeat;
  background-size: cover;
  background-position: left center;
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 100px 0px;
}
.theme_slider .swiper-slide .slider .slide_content {
  max-width: 310px;
  margin-right: calc(-1.875rem - 6.4285714286vw);
  transform-style: preserve-3d;
}
@media (min-width: 1400px) {
  .theme_slider .swiper-slide .slider .slide_content {
    margin-right: -7.5rem;
  }
}
.theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper {
  transform: scale(0.8);
  transition: all 0.5s ease-in-out;
}
.theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .meta .category {
  font-size: 0.6875rem;
  font-weight: 600;
}
.theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
  position: relative;
  padding-top: calc(1.75rem + 5.1428571429vw);
  margin-bottom: calc(1.75rem + 5.1428571429vw);
}
@media (min-width: 1400px) {
  .theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
    padding-top: 6.25rem;
  }
}
@media (min-width: 1400px) {
  .theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
    margin-bottom: 6.25rem;
  }
}
.theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4::before, .theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: calc(1.75rem + 5.1428571429vw);
  background-color: #54595f;
}
@media (min-width: 1400px) {
  .theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4::before, .theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4::before {
    height: 6.25rem;
  }
}
.theme_slider .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner p {
  font-size: 1.375rem;
  font-weight: 600;
}
.theme_slider .swiper-slide-active .slider .slide_content .slide_content_wrapper {
  transform: scale(1);
  transition-delay: 0.5s;
}

.theme_slider_2 {
  position: relative;
}
.theme_slider_2 .swiper-slide .slider {
  background-repeat: repeat;
  background-size: cover;
  background-position: left center;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: start;
}
.theme_slider_2 .swiper-slide .slider .slide_content {
  max-width: 100%;
  transition: all 0.5s ease-in-out;
}
.theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper {
  display: flex;
  align-items: center;
  padding: calc(1.375rem + 1.2857142857vw) calc(1.375rem + 1.2857142857vw);
  transition: all 0.5s ease-in-out;
}
@media (min-width: 1400px) {
  .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper {
    padding: 2.5rem 2.5rem;
  }
}
.theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner {
  text-align: center;
}
.theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .meta {
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1875rem;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: all 0.5s ease-in-out;
}
.theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-size: calc(1.3625rem + 1.1571428571vw);
  font-weight: 400;
  padding-top: calc(2.6875rem + 14.7857142857vw);
  margin-bottom: calc(1.75rem + 5.1428571429vw);
  opacity: 0;
  visibility: hidden;
  transform: skew(50deg, 0deg);
  transition: all 0.5s ease-in-out;
}
@media (min-width: 1400px) {
  .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
    font-size: 2.375rem;
  }
}
@media (min-width: 1400px) {
  .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
    padding-top: 15.625rem;
  }
}
@media (min-width: 1400px) {
  .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
    margin-bottom: 6.25rem;
  }
}
.theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4::before, .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4::before {
  content: "";
  position: absolute;
  left: 50%;
  top: calc(1.4375rem + 1.9285714286vw);
  width: 1px;
  height: calc(2rem + 7.7142857143vw);
  background-color: #54595f;
  transform: scale(0);
  transform-origin: center;
  transition: all 0.5s ease-in-out;
}
@media (min-width: 1400px) {
  .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4::before, .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4::before {
    top: 3.125rem;
  }
}
@media (min-width: 1400px) {
  .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4::before, .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4::before {
    height: 8.75rem;
  }
}
.theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner p {
  font-size: 1.375rem;
  font-weight: 600;
}
.theme_slider_2 .swiper-slide .slider .slide_content .details_link {
  font-size: 0.875rem;
  line-height: 1rem;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition: all 0.5s ease-in-out;
}
.theme_slider_2 .swiper-slide .slider .slide_content .details_link a {
  justify-content: center;
}
.theme_slider_2 .swiper-slide:nth-child(odd) .slider {
  justify-content: end;
}
.theme_slider_2 .swiper-slide-active .slider .slide_content {
  max-width: 50%;
}
.theme_slider_2 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner .meta {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition-delay: 1.5s;
}
.theme_slider_2 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_2 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
  opacity: 1;
  visibility: visible;
  transform: skew(0deg, 0deg);
  transition-delay: 1s;
}
.theme_slider_2 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner h4::before, .theme_slider_2 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner .h4::before {
  transform: scale(1);
}
.theme_slider_2 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner .details_link {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition-delay: 1.5s;
}
.theme_slider_2 .swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .theme_slider_2 .swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  width: 16px;
}

.bg-dark .theme_slider_3 video {
  filter: brightness(40%);
}

.bg-dark.bg-white .theme_slider_3 video {
  filter: brightness(250%);
}

.theme_slider_3 {
  position: relative;
}
.theme_slider_3 .swiper-slide .slider {
  background-repeat: norepeat;
  background-size: fit;
  background-position: left center;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-top: calc(3rem + 18vw);
  padding-bottom: calc(3.625rem + 24.4285714286vw);
}
@media (min-width: 1400px) {
  .theme_slider_3 .swiper-slide .slider {
    padding-top: 18.75rem;
  }
}
@media (min-width: 1400px) {
  .theme_slider_3 .swiper-slide .slider {
    padding-bottom: 25rem;
  }
}
.theme_slider_3 .swiper-slide .slider::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.theme_slider_3 .swiper-slide .slider .slide_content {
  max-width: 100%;
  transition: all 0.5s ease-in-out;
}
.theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper {
  display: flex;
  align-items: center;
  padding: 0rem 0rem;
  transition: all 0.5s ease-in-out;
}
.theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner {
  text-align: left;
}
.theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .meta {
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1875rem;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: all 0.5s ease-in-out;
}
.theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-size: calc(1.4375rem + 1.9285714286vw);
  line-height: calc(1.4375rem + 1.9285714286vw);
  font-weight: 600;
  margin-bottom: calc(1.28125rem + 0.3214285714vw);
  opacity: 0;
  visibility: hidden;
  transform: skew(50deg, 0deg);
  transition: all 0.5s ease-in-out;
}
@media (min-width: 1400px) {
  .theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
    font-size: 3.125rem;
  }
}
@media (min-width: 1400px) {
  .theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
    line-height: 3.125rem;
  }
}
@media (min-width: 1400px) {
  .theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
    margin-bottom: 1.5625rem;
  }
}
.theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner p {
  font-size: 1.5625rem;
  font-weight: 300;
  line-height: 2.1875rem;
}
.theme_slider_3 .swiper-slide .slider .slide_content .details_link {
  font-size: calc(1.275rem + 0.2571428571vw);
  line-height: calc(1.325rem + 0.7714285714vw);
  font-weight: 400;
  text-transform: none;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition: all 0.5s ease-in-out;
}
@media (min-width: 1400px) {
  .theme_slider_3 .swiper-slide .slider .slide_content .details_link {
    font-size: 1.5rem;
  }
}
@media (min-width: 1400px) {
  .theme_slider_3 .swiper-slide .slider .slide_content .details_link {
    line-height: 2rem;
  }
}
.theme_slider_3 .swiper-slide .slider .slide_content .details_link a {
  color: #ffffff;
}
.theme_slider_3 .swiper-slide .slider .slide_content .details_link a .link_text {
  cursor: auto;
}
.theme_slider_3 .swiper-slide .slider .slide_content .details_link a .link_icon {
  color: #a6a182;
}
.theme_slider_3 .swiper-slide .slider .card {
  min-height: 15em;
  background-color: transparent;
}
.theme_slider_3 .swiper-slide .slider .card .card-img-top {
  max-width: 5em;
  margin: auto;
  padding-top: 2em;
}
.theme_slider_3 .swiper-slide .slider .card .card-title {
  text-align: center;
}
.theme_slider_3 .swiper-slide:nth-child(odd) .slider {
  justify-content: end;
}
.theme_slider_3 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner .meta {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition-delay: 1.5s;
}
.theme_slider_3 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_3 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
  opacity: 1;
  visibility: visible;
  transform: skew(0deg, 0deg);
  transition-delay: 1s;
}
.theme_slider_3 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner h4::before, .theme_slider_3 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner .h4::before {
  transform: scale(1);
}
.theme_slider_3 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner .details_link {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition-delay: 1.5s;
}
.theme_slider_3 .swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .theme_slider_3 .swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  width: 16px;
}
.theme_slider_3 .swiper-button-prev, .theme_slider_3 .swiper-rtl .swiper-button-next {
  left: calc(1.5625rem + 3.2142857143vw);
}
@media (min-width: 1400px) {
  .theme_slider_3 .swiper-button-prev, .theme_slider_3 .swiper-rtl .swiper-button-next {
    left: 4.375rem;
  }
}
.theme_slider_3 .swiper-button-next, .theme_slider_3 .swiper-rtl .swiper-button-prev {
  right: calc(1.5625rem + 3.2142857143vw);
}
@media (min-width: 1400px) {
  .theme_slider_3 .swiper-button-next, .theme_slider_3 .swiper-rtl .swiper-button-prev {
    right: 4.375rem;
  }
}
.theme_slider_3 .swiper-button-next:after, .theme_slider_3 .swiper-button-prev:after {
  content: none;
}
.theme_slider_3 .details_link.swiper-button-prev a .link_icon .line, .theme_slider_3 .details_link.swiper-button-next a .link_icon .line {
  width: 5.625rem;
}
.theme_slider_3 .details_link.swiper-button-prev a .link_icon .circle, .theme_slider_3 .details_link.swiper-button-next a .link_icon .circle {
  width: 3.75rem;
  height: 3.75rem;
}
.theme_slider_3 .details_link.l-dir a .link_icon .dot {
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 18px solid #a6a182;
}
.theme_slider_3 .details_link.r-dir a .link_icon .dot {
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 18px solid #a6a182;
}

.theme_slider_4 {
  position: relative;
}
.theme_slider_4 .swiper-slide .slider {
  background-repeat: repeat;
  background-size: cover;
  background-position: left center;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: start;
  align-items: center;
}
.theme_slider_4 .swiper-slide .slider::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.theme_slider_4 .swiper-slide .slider .slide_content {
  transition: all 0.5s ease-in-out;
  padding: 0rem calc(1.4375rem + 1.9285714286vw);
}
@media (min-width: 1400px) {
  .theme_slider_4 .swiper-slide .slider .slide_content {
    padding: 0rem 3.125rem;
  }
}
.theme_slider_4 .swiper-slide .slider .slide_content .slide_content_wrapper {
  display: flex;
  align-items: center;
  padding: 0rem 0rem;
  transition: all 0.5s ease-in-out;
}
.theme_slider_4 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner {
  max-width: 80%;
  padding: calc(3.1875rem + 19.9285714286vw) 0rem calc(3rem + 18vw) 0rem;
  text-align: left;
}
@media (min-width: 1400px) {
  .theme_slider_4 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner {
    padding: 20.625rem 0rem 18.75rem 0rem;
  }
}
.theme_slider_4 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .meta {
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1875rem;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: all 0.5s ease-in-out;
}
.theme_slider_4 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_4 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-size: calc(1.78125rem + 5.4642857143vw);
  line-height: calc(1.7rem + 4.6285714286vw);
  font-weight: 400;
  margin-bottom: calc(1.28125rem + 0.3214285714vw);
  opacity: 0;
  visibility: hidden;
  transform: skew(50deg, 0deg);
  transition: all 0.5s ease-in-out;
}
@media (min-width: 1400px) {
  .theme_slider_4 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_4 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
    font-size: 6.5625rem;
  }
}
@media (min-width: 1400px) {
  .theme_slider_4 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_4 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
    line-height: 5.75rem;
  }
}
@media (min-width: 1400px) {
  .theme_slider_4 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_4 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
    margin-bottom: 1.5625rem;
  }
}
.theme_slider_4 .swiper-slide .slider .slide_content .slide_content_wrapper .mask-content {
  position: absolute;
  right: 0;
  font-size: calc(5.1875rem + 40.5vw);
  line-height: calc(4.1875rem + 30.2142857143vw);
  font-weight: 700;
  text-transform: uppercase;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  transition: all 0.5s ease-in-out;
}
@media (min-width: 1400px) {
  .theme_slider_4 .swiper-slide .slider .slide_content .slide_content_wrapper .mask-content {
    font-size: 40.625rem;
  }
}
@media (min-width: 1400px) {
  .theme_slider_4 .swiper-slide .slider .slide_content .slide_content_wrapper .mask-content {
    line-height: 30.625rem;
  }
}
.theme_slider_4 .swiper-slide .slider .slide_content .details_link {
  font-size: calc(1.275rem + 0.2571428571vw);
  line-height: calc(1.325rem + 0.7714285714vw);
  font-weight: 400;
  text-transform: capitalize;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition: all 0.5s ease-in-out;
}
@media (min-width: 1400px) {
  .theme_slider_4 .swiper-slide .slider .slide_content .details_link {
    font-size: 1.5rem;
  }
}
@media (min-width: 1400px) {
  .theme_slider_4 .swiper-slide .slider .slide_content .details_link {
    line-height: 2rem;
  }
}
.theme_slider_4 .swiper-slide .slider .slide_content .details_link a {
  color: #ffffff;
}
.theme_slider_4 .swiper-slide .slider .slide_content .details_link a .link_text {
  cursor: auto;
}
.theme_slider_4 .swiper-slide .slider .slide_content .details_link a .link_icon {
  color: #a6a182;
}
.theme_slider_4 .swiper-slide:nth-child(odd) .slider {
  justify-content: end;
}
.theme_slider_4 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner .meta {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition-delay: 1.5s;
}
.theme_slider_4 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_4 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
  opacity: 1;
  visibility: visible;
  transform: skew(0deg, 0deg);
  transition-delay: 1s;
}
.theme_slider_4 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner h4::before, .theme_slider_4 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner .h4::before {
  transform: scale(1);
}
.theme_slider_4 .swiper-slide-active .slider .slide_content .slide_content_wrapper .slide_content_inner .details_link {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition-delay: 1.5s;
}
.theme_slider_4 .swiper-slide-active .slider .slide_content .slide_content_wrapper .mask-content {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition-delay: 1s;
}
.theme_slider_4 .swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .theme_slider_4 .swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  width: 16px;
}
.theme_slider_4 .swiper-button-prev, .theme_slider_4 .swiper-rtl .swiper-button-next {
  left: calc(1.5625rem + 3.2142857143vw);
}
@media (min-width: 1400px) {
  .theme_slider_4 .swiper-button-prev, .theme_slider_4 .swiper-rtl .swiper-button-next {
    left: 4.375rem;
  }
}
.theme_slider_4 .swiper-button-next, .theme_slider_4 .swiper-rtl .swiper-button-prev {
  right: calc(1.5625rem + 3.2142857143vw);
}
@media (min-width: 1400px) {
  .theme_slider_4 .swiper-button-next, .theme_slider_4 .swiper-rtl .swiper-button-prev {
    right: 4.375rem;
  }
}
.theme_slider_4 .swiper-button-next:after, .theme_slider_4 .swiper-button-prev:after {
  content: none;
}
.theme_slider_4 .details_link.swiper-button-prev a .link_icon .line, .theme_slider_4 .details_link.swiper-button-next a .link_icon .line {
  width: 5.625rem;
}
.theme_slider_4 .details_link.swiper-button-prev a .link_icon .circle, .theme_slider_4 .details_link.swiper-button-next a .link_icon .circle {
  width: 3.75rem;
  height: 3.75rem;
}
.theme_slider_4 .details_link.l-dir a .link_icon .dot {
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 18px solid #a6a182;
}
.theme_slider_4 .details_link.r-dir a .link_icon .dot {
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 18px solid #a6a182;
}
.theme_slider_4 .swiper-horizontal > .swiper-pagination-bullets, .theme_slider_4 .swiper-pagination-bullets.swiper-pagination-horizontal, .theme_slider_4 .swiper-pagination-custom, .theme_slider_4 .swiper-pagination-fraction {
  bottom: 90px;
}

.theme_slider_5 {
  position: relative;
  z-index: 1;
}
.theme_slider_5 .slider {
  background-repeat: repeat;
  background-size: cover;
  background-position: left center;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: start;
  align-items: center;
}
.theme_slider_5 .slider .slide_content {
  position: relative;
  z-index: 1;
  max-width: 85%;
  padding: 150px 0px;
  margin: 0 auto;
  text-align: center;
  transition: all 0.5s ease-in-out;
}
.theme_slider_5 .slider .slide_content .slide_content_inner {
  position: relative;
}
.theme_slider_5 .slider .slide_content .slide_content_inner::after {
  content: "";
  position: absolute;
  left: 50%;
  top: calc(100% + 40px);
  width: 1px;
  height: 85px;
  background-color: #d9d9d9;
  transform: rotate(20deg);
  transform-origin: top;
  animation: lineBlink 10s linear infinite;
}
.theme_slider_5 .slider .slide_content .shape {
  position: relative;
  width: 130px;
  height: 135px;
  margin: 0 auto;
  z-index: 1;
}
.theme_slider_5 .slider .slide_content .shape .star1 {
  position: absolute;
  left: 0;
  top: 12px;
  animation: blink 3s linear infinite;
}
.theme_slider_5 .slider .slide_content .shape .star1-dark {
  position: absolute;
  left: 0;
  top: 12px;
  animation: blink 3s linear infinite;
  display: none;
}
.theme_slider_5 .slider .slide_content .shape .star2 {
  position: absolute;
  right: 0;
  top: 0;
  animation: blink 3.5s linear infinite;
}
.theme_slider_5 .slider .slide_content .shape .star2-dark {
  position: absolute;
  right: 0;
  top: 0;
  animation: blink 3.5s linear infinite;
  display: none;
}
.theme_slider_5 .slider .slide_content .shape .star3 {
  animation: blink 5s linear infinite;
}
.theme_slider_5 .slider .slide_content .shape .star3-dark {
  animation: blink 5s linear infinite;
  display: none;
  margin: 0 auto;
}
@keyframes blink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.6);
    opacity: 0;
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lineBlink {
  0% {
    height: 85px;
  }
  50% {
    height: 30px;
  }
  100% {
    height: 85px;
  }
}
.theme_slider_5 .slider .slide_content h4, .theme_slider_5 .slider .slide_content .h4 {
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-size: calc(1.5625rem + 3.2142857143vw);
  line-height: calc(1.625rem + 3.8571428571vw);
  font-weight: 400;
  margin-top: calc(1.28125rem + 0.3214285714vw);
  margin-bottom: calc(1.28125rem + 0.3214285714vw);
  transition: all 0.5s ease-in-out;
}
@media (min-width: 1400px) {
  .theme_slider_5 .slider .slide_content h4, .theme_slider_5 .slider .slide_content .h4 {
    font-size: 4.375rem;
  }
}
@media (min-width: 1400px) {
  .theme_slider_5 .slider .slide_content h4, .theme_slider_5 .slider .slide_content .h4 {
    line-height: 5rem;
  }
}
@media (min-width: 1400px) {
  .theme_slider_5 .slider .slide_content h4, .theme_slider_5 .slider .slide_content .h4 {
    margin-top: 1.5625rem;
  }
}
@media (min-width: 1400px) {
  .theme_slider_5 .slider .slide_content h4, .theme_slider_5 .slider .slide_content .h4 {
    margin-bottom: 1.5625rem;
  }
}
.theme_slider_5 .slider .slide_content .details_link {
  font-family: "Montserrat", sans-serif;
  font-size: calc(1.275rem + 0.2571428571vw);
  line-height: calc(1.325rem + 0.7714285714vw);
  font-weight: 400;
  text-transform: capitalize;
  transition: all 0.5s ease-in-out;
}
@media (min-width: 1400px) {
  .theme_slider_5 .slider .slide_content .details_link {
    font-size: 1.5rem;
  }
}
@media (min-width: 1400px) {
  .theme_slider_5 .slider .slide_content .details_link {
    line-height: 2rem;
  }
}
.theme_slider_5 .slider .slide_content .details_link a {
  color: #ffffff;
  justify-content: center;
}
.theme_slider_5 .slider .slide_content .details_link a .link_text {
  cursor: auto;
}
.theme_slider_5 .slider .slide_content .details_link a .link_icon {
  color: #a6a182;
}

.theme_slider_6 {
  position: relative;
  padding: 130px 0px;
}
.theme_slider_6 .slider .slide_content {
  transition: all 0.5s ease-in-out;
}
.theme_slider_6 .slider .slide_content .slide_content_wrapper {
  display: flex;
  align-items: center;
  padding: 0rem 0rem;
  transition: all 0.5s ease-in-out;
  overflow: visible;
}
.theme_slider_6 .slider .slide_content .slide_content_wrapper .slide_content_inner {
  text-align: center;
  max-width: calc(6.125rem + 50.1428571429vw);
  margin: 50px auto;
}
@media (min-width: 1400px) {
  .theme_slider_6 .slider .slide_content .slide_content_wrapper .slide_content_inner {
    max-width: 50rem;
  }
}
.theme_slider_6 .slider .slide_content .slide_content_wrapper .slide_content_inner .meta {
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1875rem;
}
.theme_slider_6 .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_6 .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-size: calc(1.78125rem + 5.4642857143vw);
  line-height: calc(1.7rem + 4.6285714286vw);
  font-weight: 400;
  margin-bottom: calc(1.28125rem + 0.3214285714vw);
}
@media (min-width: 1400px) {
  .theme_slider_6 .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_6 .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
    font-size: 6.5625rem;
  }
}
@media (min-width: 1400px) {
  .theme_slider_6 .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_6 .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
    line-height: 5.75rem;
  }
}
@media (min-width: 1400px) {
  .theme_slider_6 .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_6 .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
    margin-bottom: 1.5625rem;
  }
}
.theme_slider_6 .slider .slide_content .details_link {
  font-size: calc(1.275rem + 0.2571428571vw);
  line-height: calc(1.325rem + 0.7714285714vw);
  font-weight: 400;
  text-transform: capitalize;
}
@media (min-width: 1400px) {
  .theme_slider_6 .slider .slide_content .details_link {
    font-size: 1.5rem;
  }
}
@media (min-width: 1400px) {
  .theme_slider_6 .slider .slide_content .details_link {
    line-height: 2rem;
  }
}
.theme_slider_6 .slider .slide_content .details_link a {
  display: block;
  color: #ffffff;
}
.theme_slider_6 .slider .slide_content .details_link a .link_text {
  cursor: auto;
}
.theme_slider_6 .slider .slide_content .details_link a .link_icon {
  color: #a6a182;
}
.theme_slider_6 .swiper-slide .slider {
  max-width: calc(2.90625rem + 17.0357142857vw);
  height: auto;
  margin: 0 auto;
  transition: all 0.5s ease-in-out;
}
@media (min-width: 1400px) {
  .theme_slider_6 .swiper-slide .slider {
    max-width: 17.8125rem;
  }
}
.theme_slider_6 .swiper-slide .slider a {
  display: block;
  filter: brightness(40%);
}
.theme_slider_6 .swiper-slide .slider a:hover {
  filter: brightness(1);
}
.theme_slider_6 .swiper_theme_slider_6 .swiper-slide.swiper-slide-visible .slider {
  margin-top: 40px;
}
.theme_slider_6 .swiper_theme_slider_6 .swiper-slide.swiper-slide-visible.swiper-slide-next .slider {
  margin-top: 0px;
}
.theme_slider_6 .swiper_theme_slider_6.second_row {
  padding-top: 40px;
}
.theme_slider_6 .swiper_theme_slider_6.second_row .swiper-slide.swiper-slide-visible .slider {
  margin-top: -40px;
}
.theme_slider_6 .swiper_theme_slider_6.second_row .swiper-slide.swiper-slide-visible.swiper-slide-next .slider {
  margin-top: 0px;
}
.theme_slider_6 .swiper-horizontal > .swiper-pagination-bullets, .theme_slider_6 .swiper-pagination-bullets.swiper-pagination-horizontal, .theme_slider_6 .swiper-pagination-custom, .theme_slider_6 .swiper-pagination-fraction {
  bottom: 55px;
}
.theme_slider_6 .swiper-button-prev, .theme_slider_6 .swiper-rtl .swiper-button-next {
  left: calc(1.5625rem + 3.2142857143vw);
}
@media (min-width: 1400px) {
  .theme_slider_6 .swiper-button-prev, .theme_slider_6 .swiper-rtl .swiper-button-next {
    left: 4.375rem;
  }
}
.theme_slider_6 .swiper-button-next, .theme_slider_6 .swiper-rtl .swiper-button-prev {
  right: calc(1.5625rem + 3.2142857143vw);
}
@media (min-width: 1400px) {
  .theme_slider_6 .swiper-button-next, .theme_slider_6 .swiper-rtl .swiper-button-prev {
    right: 4.375rem;
  }
}
.theme_slider_6 .swiper-button-next:after, .theme_slider_6 .swiper-button-prev:after {
  content: none;
}
.theme_slider_6 .details_link.swiper-button-prev a .link_icon .line, .theme_slider_6 .details_link.swiper-button-next a .link_icon .line {
  width: 5.625rem;
}
.theme_slider_6 .details_link.swiper-button-prev a .link_icon .circle, .theme_slider_6 .details_link.swiper-button-next a .link_icon .circle {
  width: 3.75rem;
  height: 3.75rem;
}
.theme_slider_6 .details_link.l-dir a .link_icon .dot {
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 18px solid #a6a182;
}
.theme_slider_6 .details_link.r-dir a .link_icon .dot {
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 18px solid #a6a182;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: -10px;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0rem 0.9375rem;
}

.swiper-pagination-bullet {
  width: 0.6875rem;
  height: 0.6875rem;
  background-color: #999999;
  opacity: 1;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #a6a182;
}

.swiper-navigation {
  --button-width: 44px;
  --button-height: 44px;
  position: absolute;
  right: 0px;
  bottom: 0;
  width: var(--button-width);
  height: calc(var(--button-height) * 2);
  overflow: hidden;
  z-index: 9;
  transition: all 1s ease-in-out;
}
.swiper-navigation .swiper-button-next, .swiper-navigation .swiper-button-prev {
  font-size: 1.25rem;
  color: #000000;
  background: #ffffff;
  position: absolute;
  top: 100%;
  width: var(--button-width);
  height: var(--button-height);
  margin-top: 0px;
  transition: all 0.5s ease-in-out;
}
.swiper-navigation .swiper-button-next:hover, .swiper-navigation .swiper-button-prev:hover {
  background: #a6a182;
  color: #ffffff;
}
.swiper-navigation .swiper-button-prev, .swiper-navigation .swiper-rtl .swiper-button-next {
  left: auto;
  right: 0;
}
.swiper-navigation .swiper-button-next, .swiper-navigation .swiper-rtl .swiper-button-prev {
  right: 0;
  left: auto;
  margin-top: 1px;
}
.swiper-navigation .swiper-button-next.swiper-button-disabled, .swiper-navigation .swiper-button-prev.swiper-button-disabled {
  opacity: 1;
  background: rgba(255, 255, 255, 0.65);
}
.swiper-navigation .swiper-button-next:after, .swiper-navigation .swiper-button-prev:after {
  font-size: 20px;
  display: none;
}

.swiper:hover .swiper-navigation .swiper-button-prev {
  top: 0;
}
.swiper:hover .swiper-navigation .swiper-button-next {
  top: var(--button-height);
}

.mt-lg-minus {
  margin-top: calc(-2.1875rem - 9.6428571429vw);
  z-index: 1;
}
@media (min-width: 1400px) {
  .mt-lg-minus {
    margin-top: -10.625rem;
  }
}
.mt-lg-minus .gutter-90:nth-child(1) .grid-item, .mt-lg-minus .gutter-90:nth-child(2) .grid-item {
  padding-top: 0;
}

.rotate-logo {
  position: absolute;
  left: 50%;
  bottom: 88px;
  display: inline-block;
  z-index: 2;
}
.rotate-logo .rotate-bg {
  display: inline-block;
}
.rotate-logo .rotate-text {
  position: absolute;
  left: 8px;
  top: 8px;
  transform-origin: center;
  animation: rotateLogo 20s linear infinite;
}

@keyframes rotateLogo {
  100% {
    transform: rotate(360deg);
  }
}
video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.overlay {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.funfacts_inner {
  display: flex;
  justify-content: space-around;
  text-align: center;
  flex-flow: row wrap;
}
.funfacts_inner .funfact .funfact_content h2, .funfacts_inner .funfact .funfact_content .h2 {
  color: #ffffff;
  font-size: calc(1.525rem + 2.8285714286vw);
  font-weight: 600;
  line-height: calc(1.525rem + 2.8285714286vw);
}
@media (min-width: 1400px) {
  .funfacts_inner .funfact .funfact_content h2, .funfacts_inner .funfact .funfact_content .h2 {
    font-size: 4rem;
  }
}
@media (min-width: 1400px) {
  .funfacts_inner .funfact .funfact_content h2, .funfacts_inner .funfact .funfact_content .h2 {
    line-height: 4rem;
  }
}
.funfacts_inner .funfact .funfact_content span {
  color: #ffffff;
  font-size: calc(1.525rem + 2.8285714286vw);
  font-weight: 600;
  line-height: calc(1.525rem + 2.8285714286vw);
}
@media (min-width: 1400px) {
  .funfacts_inner .funfact .funfact_content span {
    font-size: 4rem;
  }
}
@media (min-width: 1400px) {
  .funfacts_inner .funfact .funfact_content span {
    line-height: 4rem;
  }
}
.funfacts_inner .funfact .funfact_content p {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: calc(1.28125rem + 0.3214285714vw);
  margin-top: calc(1.28125rem + 0.3214285714vw);
  color: #ffffff;
}
@media (min-width: 1400px) {
  .funfacts_inner .funfact .funfact_content p {
    line-height: 1.5625rem;
  }
}
@media (min-width: 1400px) {
  .funfacts_inner .funfact .funfact_content p {
    margin-top: 1.5625rem;
  }
}

.funfacts_inner {
  display: flex;
  justify-content: space-around;
  text-align: center;
  flex-flow: row wrap;
}
.funfacts_inner .funfact2 .fun_img {
  width: 4.375rem;
  height: 4.375rem;
  line-height: 4.125rem;
  border: 0.0625rem dashed #ffffff;
  border-radius: 100%;
  flex-shrink: 0;
  margin-right: 1.25rem;
}
.funfacts_inner .funfact2 .funfact_content h2, .funfacts_inner .funfact2 .funfact_content .h2 {
  font-size: calc(1.275rem + 0.2571428571vw);
  font-weight: 600;
  line-height: calc(1.325rem + 0.7714285714vw);
  color: #ffffff;
  margin-top: 0rem;
  margin-bottom: 0rem;
}
@media (min-width: 1400px) {
  .funfacts_inner .funfact2 .funfact_content h2, .funfacts_inner .funfact2 .funfact_content .h2 {
    font-size: 1.5rem;
  }
}
@media (min-width: 1400px) {
  .funfacts_inner .funfact2 .funfact_content h2, .funfacts_inner .funfact2 .funfact_content .h2 {
    line-height: 2rem;
  }
}
.funfacts_inner .funfact2 .funfact_content span {
  font-size: calc(1.275rem + 0.2571428571vw);
  font-weight: 600;
  line-height: calc(1.325rem + 0.7714285714vw);
  color: #ffffff;
}
@media (min-width: 1400px) {
  .funfacts_inner .funfact2 .funfact_content span {
    font-size: 1.5rem;
  }
}
@media (min-width: 1400px) {
  .funfacts_inner .funfact2 .funfact_content span {
    line-height: 2rem;
  }
}
.funfacts_inner .funfact2 .funfact_content p {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: calc(1.3rem + 0.5142857143vw);
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 0rem;
}
@media (min-width: 1400px) {
  .funfacts_inner .funfact2 .funfact_content p {
    line-height: 1.75rem;
  }
}

/*------ Portfolio Grid ------*/
.projects {
  z-index: 1;
}
.projects .section-header {
  max-width: calc(6.75rem + 56.5714285714vw);
  margin: 0rem auto 3.75rem;
}
@media (min-width: 1400px) {
  .projects .section-header {
    max-width: 56.25rem;
  }
}
.projects .section-header .section-desc {
  margin-top: 35px;
}

.grid {
  overflow: hidden;
}

.grid-item {
  overflow: hidden;
  position: relative;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

/* clear fix */
.grid:after {
  content: "";
  display: block;
  clear: both;
}

/* -------- grid ---------- */
.grid-item.width-20 {
  width: 20%;
}

.grid-item.width-25 {
  width: 25%;
}

.grid-item.width-30 {
  width: 30%;
}

.grid-item.width-35 {
  width: 35%;
}

.grid-item.width-40 {
  width: 40%;
}

.grid-item.width-50 {
  width: 50%;
}

.grid-item.width-60 {
  width: 60%;
}

.grid-item.width-66 {
  width: 66.66%;
}

.grid-item.width-70 {
  width: 70%;
}

.grid-item.width-75 {
  width: 75%;
}

.grid-item.width-80 {
  width: 78%;
}

.grid-item.width-100 {
  width: 100%;
}

/* -------- grid ---------- */
.grid-1 .grid-item, .grid-1 .grid-sizer {
  width: 100%;
}

.grid-2 .grid-item, .grid-2 .grid-sizer {
  width: 50%;
}

.grid-3 .grid-item, .grid-3 .grid-sizer {
  width: 33.33%;
}

.grid-4 .grid-item, .grid-4 .grid-sizer {
  width: 25%;
}

.grid-5 .grid-item, .grid-5 .grid-sizer {
  width: 20%;
}

.grid-2 .grid-sizer.small {
  width: 1%;
}

.grid-3 .grid-sizer.small {
  width: 1%;
}

.grid-4 .grid-sizer.small {
  width: 1%;
}

@media screen and (max-width: 991px) {
  .grid-4 .grid-item, .grid-4 .grid-sizer,
  .grid-5 .grid-item, .grid-5 .grid-sizer {
    width: 33.33%;
  }
  .grid-item.width-20,
  .grid-item.width-25,
  .grid-item.width-30,
  .grid-item.width-35,
  .grid-item.width-40,
  .grid-item.width-50,
  .grid-item.width-60,
  .grid-item.width-66,
  .grid-item.width-70,
  .grid-item.width-75,
  .grid-item.width-80 {
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .grid-3 .grid-item, .grid-3 .grid-sizer,
  .grid-4 .grid-item, .grid-4 .grid-sizer,
  .grid-5 .grid-item, .grid-5 .grid-sizer {
    width: 50%;
  }
  .grid-item.width-20,
  .grid-item.width-25,
  .grid-item.width-30,
  .grid-item.width-35,
  .grid-item.width-40,
  .grid-item.width-50,
  .grid-item.width-60,
  .grid-item.width-66,
  .grid-item.width-70,
  .grid-item.width-75,
  .grid-item.width-80 {
    width: 50%;
  }
}
@media screen and (max-width: 500px) {
  .grid-2 .grid-item, .grid-2 .grid-sizer,
  .grid-3 .grid-item, .grid-3 .grid-sizer,
  .grid-4 .grid-item, .grid-4 .grid-sizer,
  .grid-5 .grid-item, .grid-5 .grid-sizer {
    width: 100%;
  }
  .grid-item.width-20,
  .grid-item.width-25,
  .grid-item.width-30,
  .grid-item.width-35,
  .grid-item.width-40,
  .grid-item.width-50,
  .grid-item.width-60,
  .grid-item.width-66,
  .grid-item.width-70,
  .grid-item.width-75,
  .grid-item.width-80 {
    width: 100%;
  }
}
.gutter-0 {
  margin: 0rem 0rem;
}
.gutter-0 .grid-item {
  padding: 0rem;
}

.gutter-5 {
  margin: 0rem -0.3125rem;
}
.gutter-5 .grid-item {
  padding: 0.3125rem;
}

.gutter-10 {
  margin: 0rem -0.625rem;
}
.gutter-10 .grid-item {
  padding: 0.625rem;
}

.gutter-15 {
  margin: 0rem -0.9375rem;
}
.gutter-15 .grid-item {
  padding: 0.9375rem;
}

.gutter-20 {
  margin: 0rem -1.25rem;
}
.gutter-20 .grid-item {
  padding: 1.25rem;
}

.gutter-25 {
  margin: 0rem calc(-1.28125rem - 0.3214285714vw);
}
@media (min-width: 1400px) {
  .gutter-25 {
    margin: 0rem -1.5625rem;
  }
}
.gutter-25 .grid-item {
  padding: calc(1.28125rem + 0.3214285714vw);
}
@media (min-width: 1400px) {
  .gutter-25 .grid-item {
    padding: 1.5625rem;
  }
}

.gutter-30 {
  margin: 0rem calc(-1.3125rem - 0.6428571429vw);
}
@media (min-width: 1400px) {
  .gutter-30 {
    margin: 0rem -1.875rem;
  }
}
.gutter-30 .grid-item {
  padding: calc(1.3125rem + 0.6428571429vw);
}
@media (min-width: 1400px) {
  .gutter-30 .grid-item {
    padding: 1.875rem;
  }
}

.gutter-35 {
  margin: 0rem calc(-1.34375rem - 0.9642857143vw);
}
@media (min-width: 1400px) {
  .gutter-35 {
    margin: 0rem -2.1875rem;
  }
}
.gutter-35 .grid-item {
  padding: calc(1.34375rem + 0.9642857143vw);
}
@media (min-width: 1400px) {
  .gutter-35 .grid-item {
    padding: 2.1875rem;
  }
}

.gutter-40 {
  margin: 0rem calc(-1.375rem - 1.2857142857vw);
}
@media (min-width: 1400px) {
  .gutter-40 {
    margin: 0rem -2.5rem;
  }
}
.gutter-40 .grid-item {
  padding: calc(1.375rem + 1.2857142857vw);
}
@media (min-width: 1400px) {
  .gutter-40 .grid-item {
    padding: 2.5rem;
  }
}

.gutter-45 {
  margin: 0rem calc(-1.40625rem - 1.6071428571vw);
}
@media (min-width: 1400px) {
  .gutter-45 {
    margin: 0rem -2.8125rem;
  }
}
.gutter-45 .grid-item {
  padding: calc(1.40625rem + 1.6071428571vw);
}
@media (min-width: 1400px) {
  .gutter-45 .grid-item {
    padding: 2.8125rem;
  }
}

.gutter-50 {
  margin: 0rem calc(-1.4375rem - 1.9285714286vw);
}
@media (min-width: 1400px) {
  .gutter-50 {
    margin: 0rem -3.125rem;
  }
}
.gutter-50 .grid-item {
  padding: calc(1.4375rem + 1.9285714286vw);
}
@media (min-width: 1400px) {
  .gutter-50 .grid-item {
    padding: 3.125rem;
  }
}

.gutter-55 {
  margin: 0rem calc(-1.46875rem - 2.25vw);
}
@media (min-width: 1400px) {
  .gutter-55 {
    margin: 0rem -3.4375rem;
  }
}
.gutter-55 .grid-item {
  padding: calc(1.46875rem + 2.25vw);
}
@media (min-width: 1400px) {
  .gutter-55 .grid-item {
    padding: 3.4375rem;
  }
}

.gutter-60 {
  margin: 0rem calc(-1.5rem - 2.5714285714vw);
}
@media (min-width: 1400px) {
  .gutter-60 {
    margin: 0rem -3.75rem;
  }
}
.gutter-60 .grid-item {
  padding: calc(1.5rem + 2.5714285714vw);
}
@media (min-width: 1400px) {
  .gutter-60 .grid-item {
    padding: 3.75rem;
  }
}

.gutter-70 {
  margin: 0rem calc(-1.5625rem - 3.2142857143vw);
}
@media (min-width: 1400px) {
  .gutter-70 {
    margin: 0rem -4.375rem;
  }
}
.gutter-70 .grid-item {
  padding: calc(1.5625rem + 3.2142857143vw);
}
@media (min-width: 1400px) {
  .gutter-70 .grid-item {
    padding: 4.375rem;
  }
}

.gutter-80 {
  margin: 0rem calc(-1.625rem - 3.8571428571vw);
}
@media (min-width: 1400px) {
  .gutter-80 {
    margin: 0rem -5rem;
  }
}
.gutter-80 .grid-item {
  padding: calc(1.625rem + 3.8571428571vw);
}
@media (min-width: 1400px) {
  .gutter-80 .grid-item {
    padding: 5rem;
  }
}

.gutter-90 {
  margin: 0rem calc(-1.6875rem - 4.5vw);
}
@media (min-width: 1400px) {
  .gutter-90 {
    margin: 0rem -5.625rem;
  }
}
.gutter-90 .grid-item {
  padding: calc(1.6875rem + 4.5vw);
}
@media (min-width: 1400px) {
  .gutter-90 .grid-item {
    padding: 5.625rem;
  }
}

.gutter-100 {
  margin: 0rem calc(-1.75rem - 5.1428571429vw);
}
@media (min-width: 1400px) {
  .gutter-100 {
    margin: 0rem -6.25rem;
  }
}
.gutter-100 .grid-item {
  padding: calc(1.75rem + 5.1428571429vw);
}
@media (min-width: 1400px) {
  .gutter-100 .grid-item {
    padding: 6.25rem;
  }
}

@media screen and (max-width: 991px) {
  .grid {
    margin: 0px -30px;
  }
  .grid .grid-item {
    padding: 30px;
  }
}
.grid-item.hidden {
  visibility: hidden;
  width: 0px;
  height: 0px;
  margin: 0px;
  padding: 0px;
}

/*-------- Filter Button ---------*/
.portfolio-filters-content {
  position: relative;
  text-align: center;
  margin-bottom: -30px;
  z-index: 1;
}

.portfolio-filters-content .filters-button-group {
  text-align: center;
  margin: 0 auto;
  display: inline-block;
  border-bottom: 0.0625rem solid #54595f;
}

.portfolio-filters-content .filters-button-group .button {
  position: relative;
  background: transparent;
  border: 0px;
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
  border-bottom: 0.1875rem solid transparent;
  padding: 0rem 1.25rem 1.25rem 1.25rem;
  margin-bottom: -2px;
  cursor: pointer;
  outline: none;
  transition: all 0.5s ease-in-out;
}

.portfolio-filters-content .filters-button-group .button.is-checked,
.portfolio-filters-content .filters-button-group .button:hover {
  color: #a6a182;
  outline: none;
  border-color: #a6a182;
}

.portfolio-filters-content .filters-button-group .button .filter-count {
  position: absolute;
  top: 0rem;
  right: 0.5rem;
  font-size: 0.75rem;
  color: #ffffff;
  transition: all 0.5s ease-in-out;
}

.portfolio-filters-content .filters-button-group .button .filter-count {
  opacity: 0;
  visibility: hidden;
}

.portfolio-filters-content .filters-button-group .button:first-child.is-checked .filter-count {
  opacity: 0;
  visibility: hidden;
}

.portfolio-filters-content .filters-button-group .button.is-checked .filter-count {
  opacity: 1;
  visibility: visible;
}

/*-------- Grid Gallery ---------*/
.grid-item img.item_image {
  width: 100%;
  transition: all 0.5s ease-in-out;
}
.grid-item .thumb {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.grid-item .works-info {
  margin-top: 1.25rem;
}
.grid-item .label-text h5, .grid-item .label-text .h5 {
  position: relative;
  color: #ffffff;
  transition-delay: 0.6s;
  margin-bottom: 0.625rem;
}
.grid-item .label-text p {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.375rem;
  color: #999999;
  margin-bottom: 0px;
  transition-delay: 0.9s;
}
.grid-item .label-text h6, .grid-item .label-text .h6 {
  font-size: 0.6875rem;
  font-weight: 600;
  line-height: 1.375rem;
  text-transform: uppercase;
  color: #999999;
  margin-bottom: 0px;
  transition-delay: 0.9s;
}
.grid-item .label-text h6 a, .grid-item .label-text .h6 a {
  color: #999999;
}
.grid-item .label-text h6 a:hover, .grid-item .label-text .h6 a:hover {
  color: #ffffff;
}
.grid-item .label-text .popup {
  font-size: 40px;
  color: #fff;
  display: inline-block;
  margin-top: 20px;
}

.packery .portfolio-filters-content {
  margin-bottom: 2.5rem;
}
.packery .grid-item .works-info {
  position: absolute;
  right: 0;
  top: auto;
  bottom: 0;
  padding: calc(1.34375rem + 0.9642857143vw);
  margin-top: 0rem;
  max-width: 300px;
  background-color: #1b1b1b;
  transform: perspective(250px) rotateY(-90deg);
  transform-origin: right;
  transition: all 0.5s ease-in-out;
}
@media (min-width: 1400px) {
  .packery .grid-item .works-info {
    padding: 2.1875rem;
  }
}
.packery .grid-item .label-text h6, .packery .grid-item .label-text .h6 {
  letter-spacing: 0.1875rem;
  color: #a6a182;
}
.packery .grid-item .label-text h6 a, .packery .grid-item .label-text .h6 a {
  color: #a6a182;
}
.packery .grid-item .label-text h5, .packery .grid-item .label-text .h5 {
  margin-top: calc(1.4375rem + 1.9285714286vw);
  margin-bottom: 1.25rem;
}
@media (min-width: 1400px) {
  .packery .grid-item .label-text h5, .packery .grid-item .label-text .h5 {
    margin-top: 3.125rem;
  }
}
.packery .grid-item .label-text .details_link {
  font-size: 0.6875rem;
  font-weight: 600;
  line-height: 1.375rem;
  text-transform: uppercase;
  color: #a6a182;
  margin-bottom: 0px;
  transition-delay: 0.9s;
}
.packery .grid-item .label-text .details_link a {
  color: #a6a182;
}
.packery .grid-item:hover .works-info {
  transform: perspective(250px) rotateY(0deg);
}

.project-grid-list .grid-item img.item_image {
  width: calc(100% - 150px);
}
.project-grid-list .grid-item .works-info {
  top: 50%;
  bottom: auto;
  transform: translateY(-50%) perspective(250px) rotateY(-90deg);
}
.project-grid-list .grid-item:nth-child(even) img.item_image {
  float: right;
}
.project-grid-list .grid-item:nth-child(even) .works-info {
  left: 0;
  right: auto;
  transform: translateY(-50%) perspective(250px) rotateY(90deg);
  transform-origin: left;
}
.project-grid-list .grid-item:nth-child(even):hover .works-info {
  transform: translateY(-50%) perspective(250px) rotateY(0deg);
}
.project-grid-list .grid-item:hover img.item_image {
  transform: scale(1.1);
  -webkit-filter: brightness(0.6) contrast(1.1) saturate(1.2) blur(3px);
  filter: brightness(0.6) contrast(1.1) saturate(1.2) blur(3px);
}

.effect-tilt .grid-item .thumb {
  transform-style: preserve-3d;
}

.effect-tilt .grid-item .label-text {
  top: 0%;
  left: 0%;
  transform: none;
}

.running_projects {
  z-index: 1;
}
.running_projects .grid-item {
  overflow: visible;
}
.running_projects .grid-item .thumb {
  overflow: visible;
}
.running_projects .grid-item img.item_image {
  width: auto;
}
.running_projects .grid-item .works-info {
  position: absolute;
  background: #000000;
  max-width: 300px;
  top: 50%;
  left: -150px;
  padding: calc(1.40625rem + 1.6071428571vw);
  margin-top: 0px;
  transform: translateY(-50%) perspective(400px) rotateY(-90deg);
  transform-origin: center;
  transition: all 0.5s ease-in-out;
}
@media (min-width: 1400px) {
  .running_projects .grid-item .works-info {
    padding: 2.8125rem;
  }
}
.running_projects .grid-item .label-text h6, .running_projects .grid-item .label-text .h6 {
  letter-spacing: 0.1875rem;
  color: #a6a182;
}
.running_projects .grid-item .label-text h6 a, .running_projects .grid-item .label-text .h6 a {
  color: #a6a182;
}
.running_projects .grid-item .label-text h5, .running_projects .grid-item .label-text .h5 {
  margin-top: 3.125rem;
  margin-bottom: 1.25rem;
}
.running_projects .grid-item .label-text .details_link {
  font-size: 0.6875rem;
  font-weight: 600;
  line-height: 1.375rem;
  text-transform: uppercase;
  color: #a6a182;
  margin-bottom: 0px;
  transition-delay: 0.9s;
}
.running_projects .grid-item .label-text .details_link a {
  color: #a6a182;
}
.running_projects .grid-item.active .works-info, .running_projects .grid-item:hover .works-info {
  transform: translateY(-50%) perspective(400px) rotateY(0deg);
}
.running_projects .left_part {
  display: flex;
  align-items: end;
  height: 100%;
}
.running_projects .left_part .grid-item .works-info {
  right: -110px;
  left: unset;
}
.running_projects .right_part .grid-item:first-child {
  margin-bottom: 5.625rem;
  margin-left: 2.9375rem;
}

.project-details {
  position: relative;
  z-index: 1;
}
.project-details h6.widget-title, .project-details .widget-title.h6 {
  color: #ffffff;
}
.project-details .gallery_slider {
  margin-bottom: 2.5rem;
}
.project-details .gallery_slider .gallery-image img {
  width: 100%;
}
.project-details .gallery_slider .swiper-horizontal > .swiper-pagination-bullets, .project-details .gallery_slider .swiper-pagination-bullets.swiper-pagination-horizontal, .project-details .gallery_slider .swiper-pagination-custom, .project-details .gallery_slider .swiper-pagination-fraction {
  bottom: 10px;
}
.project-details .gallery_slider .swiper_gallery .swiper-navigation {
  width: 100%;
  height: calc(var(--button-height));
  top: 50%;
  transform: translateY(-50%);
  bottom: auto;
}
.project-details .gallery_slider .swiper_gallery .swiper-navigation .swiper-button-prev, .project-details .gallery_slider .swiper_gallery .swiper-navigation .swiper-rtl .swiper-button-next {
  left: 0;
  right: auto;
}
.project-details .gallery_slider .swiper_gallery .swiper-navigation .swiper-button-next, .project-details .gallery_slider .swiper_gallery .swiper-navigation .swiper-rtl .swiper-button-prev {
  left: auto;
  right: 0;
}
.project-details .gallery_slider .swiper_gallery .swiper-navigation .swiper-button-next, .project-details .gallery_slider .swiper_gallery .swiper-navigation .swiper-button-prev {
  font-size: 1.5rem;
  top: 0;
}
.project-details .gallery_slider .swiper_gallery:hover .swiper-navigation .swiper-button-prev {
  top: 0;
}
.project-details .gallery_slider .swiper_gallery:hover .swiper-navigation .swiper-button-next {
  top: 0;
}
.project-details .post-image {
  margin-bottom: 2.5rem;
}
.project-details .post-header .post-title {
  font-size: calc(1.325rem + 0.7714285714vw);
  line-height: calc(1.35rem + 1.0285714286vw);
  font-weight: 500;
  text-align: left;
  margin: 0rem 0rem 1.25rem 0rem;
  color: #ffffff;
}
@media (min-width: 1400px) {
  .project-details .post-header .post-title {
    font-size: 2rem;
  }
}
@media (min-width: 1400px) {
  .project-details .post-header .post-title {
    line-height: 2.25rem;
  }
}
.project-details .fulltext .widget-title {
  margin-top: calc(1.3125rem + 0.6428571429vw);
  margin-bottom: calc(1.34375rem + 0.9642857143vw);
}
@media (min-width: 1400px) {
  .project-details .fulltext .widget-title {
    margin-top: 1.875rem;
  }
}
@media (min-width: 1400px) {
  .project-details .fulltext .widget-title {
    margin-bottom: 2.1875rem;
  }
}
.project-details .fulltext p {
  margin-bottom: 1.875rem;
}
.project-details ul.point_order {
  padding-left: calc(1.46875rem + 2.25vw);
  margin-bottom: calc(1.34375rem + 0.9642857143vw);
  list-style-type: none;
}
@media (min-width: 1400px) {
  .project-details ul.point_order {
    padding-left: 3.4375rem;
  }
}
@media (min-width: 1400px) {
  .project-details ul.point_order {
    margin-bottom: 2.1875rem;
  }
}
.project-details ul.point_order li {
  position: relative;
  padding-left: 35px;
  line-height: 36px;
}
.project-details ul.point_order li:first-letter {
  text-transform: capitalize;
}
.project-details ul.point_order li:before {
  content: "\f26f";
  font-family: "bootstrap-icons";
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  color: #a6a182;
}
.project-details .blocks-gallery-grid {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.project-details .blocks-gallery-grid .blocks-gallery-item {
  margin: 0 1em 1em 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: calc(50% - 1em);
}
.project-details .blocks-gallery-grid .blocks-gallery-item figure {
  position: relative;
  margin: 0;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.project-details .blocks-gallery-grid .blocks-gallery-item figure::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.5s ease-in-out;
}
.project-details .blocks-gallery-grid .blocks-gallery-item figure .popup-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 30px;
  color: #000000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}
.project-details .blocks-gallery-grid .blocks-gallery-item figure .popup-btn a {
  color: #000000;
}
.project-details .blocks-gallery-grid .blocks-gallery-item figure:hover::before {
  background-color: rgba(0, 0, 0, 0.6);
}
.project-details .blocks-gallery-grid .blocks-gallery-item figure:hover .popup-btn {
  opacity: 1;
  visibility: visible;
}
.project-details .post-footer {
  display: inline-block;
  width: 100%;
  margin: 1.5625rem 0rem 3.4375rem 0rem;
  padding: 1.875rem 0rem;
  border-top: 1px solid #2e2e2e;
  color: #a6a182;
}
.project-details .post-footer .page-all a {
  font-size: 1.5rem;
  color: #a6a182;
}
.project-details .post-share {
  display: flex;
  align-items: center;
}
.project-details .post-share ul.share-list {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  padding-left: 0;
}
.project-details .post-share ul.share-list li {
  list-style-type: none;
  display: inline-block;
}
.project-details .post-share ul.share-list li a {
  font-size: 0.75rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  width: auto;
  display: inline-block;
  background-color: #2e2e2e;
  color: #ffffff;
  margin: 0px 5px;
  padding: 3px 12px;
  border-radius: 3px;
  letter-spacing: 1px;
}
.project-details .post-share ul.share-list li a:hover {
  background-color: #a6a182;
}
.project-details .post-share ul.share-list li:first-child {
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  margin-right: 15px;
}
.project-details .post-share ul.share-list li:last-child a {
  margin-right: 0px;
}

.project-information {
  color: #ffffff;
  font-size: 1rem;
}
.project-information .widget-title {
  font-size: 1.25rem;
  margin-bottom: 1.125rem;
}
.project-information ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.project-information ul li {
  padding: 0.3125rem 0rem;
  display: flex;
}
.project-information ul li:first-child {
  padding-top: 0rem;
}
.project-information ul li:last-child {
  background-image: none;
}
.project-information ul li .data {
  flex-shrink: 0;
  margin-right: 0.75rem;
  font-weight: 600;
}
.project-information ul li .value {
  color: #b2b2b2;
  font-weight: 400;
}
.project-information ul li a {
  color: #b2b2b2;
  text-decoration: underline;
  text-underline-offset: 4px;
}
.project-information .project-share {
  margin-top: 2.5rem;
}
.project-information .project-share ul {
  margin: 0px;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}
.project-information .project-share ul li {
  width: auto;
  background-image: none;
  margin-right: 10px;
  padding: 0px;
  font-weight: 600;
}
.project-information .project-share ul li a {
  display: block;
  font-size: 0.875rem;
  text-align: center;
  background: #545454;
  color: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-information .project-share ul li:last-child {
  margin-right: 0px;
}

.project-list-view {
  z-index: 1;
}
.project-list-view .section-header h2, .project-list-view .section-header .h2 {
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 3.5rem;
  text-transform: capitalize;
  letter-spacing: 0rem;
}
.project-list-view .project-list {
  counter-reset: item;
  margin-left: 0;
  margin-bottom: 55px;
  padding-left: 0;
}
.project-list-view .project-list li {
  position: relative;
  display: block;
  padding: calc(1.4375rem + 1.9285714286vw) 0rem;
  border-bottom: 1px solid rgba(211, 211, 214, 0.5);
  transition: all 0.5s ease-in-out;
}
@media (min-width: 1400px) {
  .project-list-view .project-list li {
    padding: 3.125rem 0rem;
  }
}
.project-list-view .project-list li::before {
  display: inline-block;
  content: "0" counter(item);
  counter-increment: item;
  font-size: calc(1.35rem + 1.0285714286vw);
  position: absolute;
  left: 0;
  top: calc(25% - 15px);
  transition: all 0.5s ease-in-out;
}
@media (min-width: 1400px) {
  .project-list-view .project-list li::before {
    font-size: 2.25rem;
  }
}
.project-list-view .project-list li a {
  position: relative;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: calc(1.525rem + 2.8285714286vw);
  line-height: calc(1.6688rem + 4.3076571429vw);
  text-transform: uppercase;
  color: #d3d3d6;
  padding-left: calc(1.90625rem + 6.75vw);
}
@media (min-width: 1400px) {
  .project-list-view .project-list li a {
    font-size: 4rem;
  }
}
@media (min-width: 1400px) {
  .project-list-view .project-list li a {
    line-height: 5.438rem;
  }
}
@media (min-width: 1400px) {
  .project-list-view .project-list li a {
    padding-left: 7.8125rem;
  }
}
.project-list-view .project-list li a::before {
  display: inline-block;
  content: "";
  position: absolute;
  left: calc(1.4375rem + 1.9285714286vw);
  top: 50%;
  width: calc(1.6875rem + 4.5vw);
  height: 2px;
  background-color: #d9d9d9;
  transform: rotate(-45deg);
  transform-origin: left center;
  transition: all 0.5s ease-in-out;
}
@media (min-width: 1400px) {
  .project-list-view .project-list li a::before {
    left: 3.125rem;
  }
}
@media (min-width: 1400px) {
  .project-list-view .project-list li a::before {
    width: 5.625rem;
  }
}
.project-list-view .project-list li a::after {
  display: inline-block;
  content: "";
  position: absolute;
  left: 50px;
  top: 50%;
  width: 0px;
  height: 2px;
  background-color: #a6a182;
  transform: rotate(0deg);
  transform-origin: left center;
  transition: all 0.5s ease-in-out;
}
.project-list-view .project-list li .hidden-image {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
  max-width: 300px;
}
.project-list-view .project-list li:hover::before {
  color: #a6a182;
}
.project-list-view .project-list li:hover a {
  color: #a6a182;
  padding-left: calc(2.0625rem + 8.3571428571vw);
  transition-delay: 0.6s;
}
@media (min-width: 1400px) {
  .project-list-view .project-list li:hover a {
    padding-left: 9.375rem;
  }
}
.project-list-view .project-list li:hover a::before {
  width: 0px;
}
.project-list-view .project-list li:hover a::after {
  width: calc(1.6875rem + 4.5vw);
  transition-delay: 0.6s;
}
@media (min-width: 1400px) {
  .project-list-view .project-list li:hover a::after {
    width: 5.625rem;
  }
}
.project-list-view .project-list li:hover .hidden-image {
  opacity: 1;
  visibility: visible;
}
.project-list-view .details_link {
  font-size: 1.5rem;
  letter-spacing: 1.5px;
}
.project-list-view .details_link a .link_text {
  margin-right: 2.5rem;
}
.project-list-view .details_link a .link_icon .line {
  width: 3.125rem;
  height: 0.125rem;
  top: 49%;
}
.project-list-view .details_link a .link_icon .circle {
  width: 3.125rem;
  height: 3.125rem;
  border-width: 0.125rem;
}

.service-information ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.service-information ul li {
  background-image: linear-gradient(to right, #54595F 50%, rgba(84, 89, 95, 0) 0%);
  background-position: bottom;
  background-size: 6px 1px;
  background-repeat: repeat-x;
  padding: 0.75rem 0rem;
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}
.service-information ul li:first-child {
  padding-top: 0px;
}
.service-information ul li:last-child {
  background-image: none;
  padding-bottom: 0px;
}
.service-information ul li a {
  color: #ffffff;
  text-underline-offset: 4px;
}
.service-information ul li.active a, .service-information ul li:hover a {
  color: #a6a182;
}

/*------- Testimonial -------*/
.testimonial .container-fluid, .testimonial .container-sm, .testimonial .container-md, .testimonial .container-lg, .testimonial .container-xl, .testimonial .container-xxl {
  padding: 0px;
}

.testimonial_inner {
  position: relative;
  padding: calc(2.0625rem + 8.3571428571vw) 0rem calc(1.8125rem + 5.7857142857vw) 0rem;
  z-index: 1;
}
@media (min-width: 1400px) {
  .testimonial_inner {
    padding: 9.375rem 0rem 6.875rem 0rem;
  }
}
.testimonial_inner::before {
  position: absolute;
  content: "";
  background: url(../../assets/img/bg/bg_t_1.png);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: right bottom;
  bottom: 0;
}
.testimonial_inner::after {
  position: absolute;
  content: "";
  background: url(../../assets/img/bg/bg_t_2.png);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: left center;
  top: 0;
}

.testimonial-block {
  position: relative;
  z-index: 1;
}
.testimonial-block h6, .testimonial-block .h6 {
  margin-top: 30px;
  position: relative;
  display: -webkit-inline-box;
  display: -moz-inline-box;
}
.testimonial-block h6::before, .testimonial-block .h6::before {
  position: absolute;
  content: "";
  background: #ffffff;
  width: 14px;
  height: 2px;
  left: -27px;
  top: 8px;
}

.swiper_testimonial {
  padding-bottom: 50px;
}
.swiper_testimonial .swiper-slide.swiper-slide-active .testimonial-block p {
  color: #ffffff;
}

.page_header.in-service .page_header_inner {
  background: transparent;
}
.page_header.in-service .page_header_inner::before {
  display: none;
}
.page_header.in-service .page_header_inner .page_header_content .heading {
  font-weight: 700;
  text-transform: uppercase;
}

.header-layer-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("../img/bg/bg-2.jpg");
  background-position: top center;
  background-repeat: no-repeat;
}

.testimonial.in-service {
  position: relative;
  z-index: 3;
  background: transparent;
}

.partners.in-service .partner_flex {
  margin-top: 0px;
}

/*
 * Shortcode: sidebar
 * -----------------------------------------------
*/
.sidebar {
  position: sticky;
  position: -webkit-sticky;
  top: 100px;
  background-color: #262626;
  padding: 30px;
}

.widget {
  margin-bottom: 50px;
  /*-- Page List --*/
}
.widget:last-child {
  margin-bottom: 0px;
}
.widget h2, .widget .h2,
.widget .widget-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #ffffff;
  display: inline-block;
  margin-bottom: 15px;
}
.widget ul, .widget ol {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}
.widget ul li, .widget ol li {
  margin-bottom: 0px;
  border-bottom: 1px solid #2e2e2e;
  padding: 12px 0px;
}
.widget ul li a, .widget ol li a {
  width: 100%;
}
.widget ul li .submenu-container, .widget ol li .submenu-container {
  padding-left: 20px;
}
.widget .wp-block-page-list li {
  border-bottom: 0px;
  padding: 0px;
}
.widget .wp-block-page-list li a {
  border-bottom: 1px solid #2e2e2e;
  padding: 12px 0px;
  width: 100%;
  display: block;
}
.widget .wp-calendar-table {
  width: 100%;
  border: 1px solid #2e2e2e;
}
.widget .wp-calendar-table td, .widget .wp-calendar-table th {
  border-bottom: 1px solid #2e2e2e;
  border-right: 1px solid #2e2e2e;
}
.widget .wp-calendar-table caption {
  color: #ffffff;
}

/*-- Search --*/
.widget_search {
  text-align: left;
}
.widget_search .wp-block-search__inside-wrapper {
  position: relative;
}
.widget_search .wp-block-search {
  position: relative;
}
.widget_search .wp-block-search .wp-block-search__input {
  padding: 5px 15px;
  height: 44px;
  border: 1px solid #2e2e2e;
  border-radius: 0;
  color: #a6a182;
  background: transparent;
}
.widget_search .wp-block-search .wp-block-search__input:focus {
  box-shadow: none;
}
.widget_search .wp-block-search .wp-block-search__button {
  position: absolute;
  top: 0;
  right: 0;
  border: 0px;
  background: #a6a182;
  padding: 0px 15px;
  width: auto;
  font-size: 16px;
  line-height: 44px;
  color: #ffffff;
  cursor: pointer;
}
.widget_search .wp-block-search .wp-block-search__button:focus {
  outline: none;
}
.widget_search .wp-block-search ::-webkit-input-placeholder {
  font-size: 0.75rem;
  font-weight: 500;
  color: #a6a182;
}
.widget_search .wp-block-search ::-moz-placeholder {
  font-size: 0.75rem;
  font-weight: 500;
  color: #a6a182;
}
.widget_search .wp-block-search :-ms-input-placeholder {
  font-size: 0.75rem;
  font-weight: 500;
  color: #a6a182;
}
.widget_search .wp-block-search :-moz-placeholder {
  font-size: 0.75rem;
  font-weight: 500;
  color: #a6a182;
}
.widget_search .wp-block-search :placeholder {
  font-size: 0.75rem;
  font-weight: 500;
  color: #a6a182;
}

/*-- Tag Cloud --*/
.widget_tag_cloud a {
  font-size: 13px;
  display: inline-block;
  padding: 0px 0px;
  text-decoration: underline;
  text-underline-offset: 5px;
  background: transparent;
  color: #999999;
  letter-spacing: 1px;
  margin: 0px 10px 10px 0px;
}
.widget_tag_cloud a:hover {
  color: #a6a182;
}

/*-- Tag Cloud --*/
.widget_tag_cloud ul {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}
.widget_tag_cloud ul li {
  margin-bottom: 6px;
  margin-right: 6px;
}
.widget_tag_cloud ul li a {
  font-size: 10px;
  text-transform: uppercase;
  display: inline-block;
  padding: 10px 20px;
  background: #a6a182;
  letter-spacing: 1px;
  color: #fff;
}

.blog.pt-lg {
  padding-top: calc(2.3125rem + 10.9285714286vw);
}
@media (min-width: 1400px) {
  .blog.pt-lg {
    padding-top: 11.875rem;
  }
}

.blog_post {
  position: relative;
  overflow: hidden;
  height: 488px;
  transition: all 0.5s ease-in-out;
  margin-bottom: 1.875rem;
}
.blog_post .blog_content {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  transition: all 0.5s ease-in-out;
}
.blog_post .blog_content::before {
  content: "";
  background: rgba(0, 0, 0, 0);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.blog_post .blog_content .post-count {
  font-size: 0.125rem;
  line-height: 0.5rem;
  font-weight: 700;
  transition: all 0.5s ease;
  transform: translateY(10px);
  opacity: 0;
  color: rgba(255, 255, 255, 0.3);
}
.blog_post .blog_content h5, .blog_post .blog_content .h5 {
  margin-bottom: 1.5625rem;
}
.blog_post .blog_content h5 > a, .blog_post .blog_content .h5 > a {
  font-size: 1.25rem;
  line-height: 1.625rem;
}
.blog_post .blog_content p {
  font-size: 1rem;
  margin-bottom: 25px;
  color: #999999;
  transition: all 0.5s ease;
}
.blog_post .blog_content > a {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: 1.2px;
  color: #b2b2b2;
  position: relative;
  transition: all 0.5s ease;
}
.blog_post .blog_content > a:after {
  position: absolute;
  content: url("../../assets/img/icon_box/i1.svg");
  transform: translateX(20px);
  transition: all 0.5s ease;
}
.blog_post .blog_content > a:hover::after {
  transform: translateX(-20px);
}
.blog_post .blog_content .meta {
  margin-top: 10px;
  margin-bottom: 10px;
}
.blog_post .blog_content .meta time {
  font-size: 0.75rem;
  line-height: 1.375rem;
  font-weight: 600;
}
.blog_post img {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: all 0.5s ease;
}
.blog_post:hover .blog_content::before {
  background: rgba(0, 0, 0, 0.75);
}
.blog_post:hover .blog_content .post-count {
  font-size: calc(1.575rem + 3.3428571429vw);
  line-height: 4.875rem;
  opacity: 1;
  transform: translateY(0);
}
@media (min-width: 1400px) {
  .blog_post:hover .blog_content .post-count {
    font-size: 4.5rem;
  }
}
.blog_post:hover .blog_content p {
  color: #ffffff;
}
.blog_post:hover .blog_content > a {
  color: #a6a182;
}
.blog_post:hover img {
  opacity: 1;
}

/*
 * Shortcode: blog-grid
 * -----------------------------------------------
*/
/*-------- Comments ------------*/
.comments-area .comments-title {
  position: relative;
  font-size: 1.875rem;
  font-weight: 600;
  padding-bottom: 0.625rem;
  color: #ffffff;
}
.comments-area ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0px;
}
.comments-area ul ul {
  padding-left: 90px;
}
.comments-area ul.comment-list .commenter-block {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  align-items: start;
}
.comments-area ul.comment-list .commenter-block .comment-avatar {
  margin-right: 30px;
  min-width: 90px;
  max-width: 90px;
  max-height: 90px;
  overflow: hidden;
}
.comments-area ul.comment-list .commenter-block .comment-content {
  width: 100%;
}
.comments-area ul.comment-list .commenter-block .comment-content .comment-author-name {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-top: 0px;
  color: #ffffff;
  text-transform: capitalize;
}
.comments-area ul.comment-list .commenter-block .comment-content .comment-author-comment {
  font-size: 1rem;
  line-height: 24px;
  color: #999999;
  margin-top: 10px;
}
.comments-area ul.comment-list .commenter-block .comment-content .comment-author-comment p {
  margin-bottom: 0.625rem;
}
.comments-area ul.comment-list .commenter-block .comment-content .comment-date {
  font-size: 0.875rem;
  font-weight: 400;
  color: #a6a182;
  margin-left: 1.875rem;
}
.comments-area ul.comment-list .commenter-block .comment-content .comment-reply .comment-reply-link {
  font-size: 0.75rem;
  font-weight: 400;
  text-transform: uppercase;
  color: #a6a182;
}
.comments-area .comments-pagination {
  margin-bottom: 0px;
  list-style-type: none;
  align-items: center;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0px;
}
.comments-area .comments-pagination a, .comments-area .comments-pagination span {
  margin-right: 7px;
  font-size: 1.125rem;
  font-weight: 400;
  text-align: center;
  width: 44px;
  height: 44px;
  line-height: 44px;
  background: #2e2e2e;
  color: #ffffff;
  display: block;
}
.comments-area .comments-pagination a:hover, .comments-area .comments-pagination a.current, .comments-area .comments-pagination span:hover, .comments-area .comments-pagination span.current {
  background: #a6a182;
  color: #ffffff;
}

.comment-respond {
  margin-top: 100px;
}
.comment-respond .comment-reply-title {
  position: relative;
  font-size: 1.875rem;
  font-weight: 600;
  margin-bottom: 0.625rem;
  padding-bottom: 0.625rem;
  color: #ffffff;
}
.comment-respond .logged-in-as {
  font-size: 1rem;
  font-weight: 400;
  font-style: italic;
}
.comment-respond select, .comment-respond input[type=text], .comment-respond input[type=email], .comment-respond input[type=url], .comment-respond input[type=password], .comment-respond input[type=search], .comment-respond input[type=number], .comment-respond input[type=tel], .comment-respond input[type=range], .comment-respond input[type=date], .comment-respond input[type=month], .comment-respond input[type=week], .comment-respond input[type=time], .comment-respond input[type=datetime], .comment-respond input[type=datetime-local], .comment-respond input[type=color] {
  height: 60px;
  font-size: 0.875rem;
  background: #2e2e2e;
  color: #ffffff;
  border: 1px solid #2e2e2e;
  padding: 15px 25px;
}
.comment-respond textarea.form-control {
  height: 140px;
  font-size: 0.875rem;
  background: #2e2e2e;
  color: #ffffff;
  border: 1px solid #2e2e2e;
  padding: 15px 25px;
}
.comment-respond .form-group {
  margin-bottom: 1.25rem;
}
.comment-respond ::-webkit-input-placeholder,
.comment-respond ::-moz-placeholder,
.comment-respond :-ms-input-placeholder,
.comment-respond :-moz-placeholder,
.comment-respond :placeholder {
  font-size: 0.875rem;
  font-weight: 400;
  color: #999999;
}

.pagination-div {
  display: inline-block;
  width: 100%;
  margin-top: 30px;
}

.pagination {
  margin-bottom: 0px;
  list-style-type: none;
  align-items: center;
  justify-content: center;
}
.pagination li {
  margin-right: 10px;
}
.pagination li:last-child {
  margin-right: 0px;
}
.pagination li a {
  font-family: "Montserrat", sans-serif;
  font-size: 0.9375rem;
  color: #ffffff;
  text-align: center;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  border-radius: 50%;
}
.pagination li a:hover, .pagination li a.current {
  background: #2e2e2e;
  color: #a6a182;
}

/*=========== Newsletter =========*/
.newsletter {
  position: relative;
  z-index: 1;
  margin-bottom: -135px;
}
.newsletter .newsletter_inner {
  padding: 70px 110px;
  border-radius: 10px;
}
.newsletter .newsletter_inner .newsletter_header h1, .newsletter .newsletter_inner .newsletter_header .h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #ffffff;
  margin-top: 10px;
}

.newsletter_form .form_group {
  position: relative;
}
.newsletter_form .form_group .form-control {
  height: 70px;
  border-radius: 35px;
  border-width: 0px;
  padding: 15px 40px;
}
.newsletter_form .form_group .button {
  position: absolute;
  top: 7px;
  right: 7px;
  font-size: 15px;
  line-height: 1.375rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 15px 30px;
  box-shadow: none;
}

/*=========== Footer =========*/
.footer {
  position: relative;
  padding-top: 0rem;
  padding-bottom: calc(1.5625rem + 3.2142857143vw);
}
@media (min-width: 1400px) {
  .footer {
    padding-bottom: 4.375rem;
  }
}
.footer.pd-top {
  padding-top: calc(1.5625rem + 3.2142857143vw);
}
@media (min-width: 1400px) {
  .footer.pd-top {
    padding-top: 4.375rem;
  }
}
.footer .footer_inner {
  position: relative;
  z-index: 1;
  padding: calc(1.6563rem + 4.1790857143vw) 0rem;
}
@media (min-width: 1400px) {
  .footer .footer_inner {
    padding: 5.313rem 0rem;
  }
}
.footer .footer_inner > div {
  position: relative;
  z-index: 2;
}
.footer .footer_inner .section-header {
  margin-bottom: 0rem;
}
.footer .footer_inner .section-header h2, .footer .footer_inner .section-header .h2 {
  font-size: calc(1.8125rem + 5.7857142857vw);
  line-height: calc(1.65rem + 4.1142857143vw);
  writing-mode: vertical-rl;
  color: #2e2e2e;
  margin: 0px 0px 0px -47px;
  transform: rotate(180deg);
}
@media (min-width: 1400px) {
  .footer .footer_inner .section-header h2, .footer .footer_inner .section-header .h2 {
    font-size: 6.875rem;
  }
}
@media (min-width: 1400px) {
  .footer .footer_inner .section-header h2, .footer .footer_inner .section-header .h2 {
    line-height: 5.25rem;
  }
}
.footer .footer_inner .communication .info_body h6, .footer .footer_inner .communication .info_body .h6 {
  color: #a6a182;
  font-weight: 400;
  line-height: 2.125rem;
}
.footer .footer_inner .communication .info_body h5, .footer .footer_inner .communication .info_body .h5 {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.25rem;
  word-break: break-all;
}
.footer .footer_inner .communication .info_body:not(:last-child) {
  margin-bottom: 1.5625rem;
}
.footer .footer_inner .footer_social {
  text-align: center;
  margin-top: 1.25rem;
  margin-bottom: 1.875rem;
}
.footer .footer_inner .footer_social .social_list {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 0px;
  margin-bottom: 0px;
}
.footer .footer_inner .footer_social .social_list li {
  list-style: none;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
}
.footer .footer_inner .footer_social .social_list li:not(:last-child) {
  margin-right: 10px;
}
.footer .footer_inner .footer_social .social_list li a {
  position: relative;
  overflow: hidden;
  font-size: 0.875rem;
  text-align: center;
  color: #ffffff;
  background: #2e2e2e;
  display: block;
  outline: none;
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  border-radius: 100%;
}
.footer .footer_inner .footer_social .social_list li a:hover {
  color: #a6a182;
}
.footer .footer_inner .terms_condition {
  margin-bottom: 0.625rem;
}
.footer .footer_inner .terms_condition ul {
  margin: 0rem;
  padding: 0rem;
  list-style-type: none;
  display: flex;
  align-items: center;
}
.footer .footer_inner .terms_condition ul li {
  margin-bottom: 0rem;
  line-height: 1rem;
}
.footer .footer_inner .terms_condition ul li:not(:last-child) {
  margin-right: 1.5625rem;
}
.footer .footer_inner .terms_condition ul li a {
  color: #b2b2b2;
  font-size: 0.625rem;
  font-weight: 500;
  text-transform: uppercase;
}
.footer .footer_inner .terms_condition ul li a:hover {
  color: #ffffff;
}
.footer .footer_inner .copyright p {
  font-size: 0.75rem;
  color: #b2b2b2;
}
.footer .grid_lines {
  padding: 0rem calc(1.56875rem + 3.2785714286vw);
}
@media (min-width: 1400px) {
  .footer .grid_lines {
    padding: 0rem 4.4375rem;
  }
}
.footer .grid_lines .grid_line {
  background-color: #191919 !important;
}

.totop {
  width: 55px;
  height: 55px;
  border: 1px solid #a6a182;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 70px;
  right: calc(1.28125rem + 0.3214285714vw);
  z-index: 2;
  color: #a6a182;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}
@media (min-width: 1400px) {
  .totop {
    right: 1.5625rem;
  }
}
.totop::before {
  content: "";
  position: absolute;
  top: -50px;
  left: 50%;
  width: 1px;
  height: 30px;
  background-color: #a6a182;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}
.totop a {
  color: #a6a182;
}
.totop.show {
  opacity: 1;
  visibility: visible;
}
.totop.show::before {
  top: -15px;
  opacity: 1;
  visibility: visible;
}

.about_image {
  position: relative;
}
.about_image img:last-child {
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.header .logo a.dark_logo {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.about_text_inner {
  padding-left: 2.8125rem;
}

.about_text {
  margin-bottom: 35px;
}
.about_text h2, .about_text .h2 {
  font-size: calc(1.425rem + 1.8vw);
  line-height: 3.6875rem;
  margin-bottom: 25px;
  text-transform: uppercase;
}
@media (min-width: 1400px) {
  .about_text h2, .about_text .h2 {
    font-size: 3rem;
  }
}

.mission_top_part {
  margin-bottom: 4.375rem;
}
.mission_top_part .section-header {
  margin-bottom: 180px;
  padding: 0rem calc(1.3125rem + 0.6428571429vw);
}
@media (min-width: 1400px) {
  .mission_top_part .section-header {
    padding: 0rem 1.875rem;
  }
}
.mission_top_part .section-header p {
  font-size: calc(1.3125rem + 0.6428571429vw);
  line-height: calc(1.4375rem + 1.9285714286vw);
  margin-top: calc(1.3125rem + 0.6428571429vw);
  letter-spacing: -1px;
  font-weight: 300;
}
@media (min-width: 1400px) {
  .mission_top_part .section-header p {
    font-size: 1.875rem;
  }
}
@media (min-width: 1400px) {
  .mission_top_part .section-header p {
    line-height: 3.125rem;
  }
}
@media (min-width: 1400px) {
  .mission_top_part .section-header p {
    margin-top: 1.875rem;
  }
}
.mission_top_part .has_line::before {
  bottom: calc(100% + 50px);
}

.mission_bottom_part .section-header .border-line {
  position: relative;
  text-align: center;
}
.mission_bottom_part .section-header .border-line::after {
  position: absolute;
  content: "";
  background: #ffffff;
  width: 124px;
  height: 2px;
  bottom: -20px;
  left: 36%;
}
.mission_bottom_part .mission_content p {
  margin-bottom: 1.25rem;
}
.mission_bottom_part .mission_content p:last-child {
  margin-bottom: 0;
}

.about_para {
  font-size: calc(1.525rem + 2.8285714286vw);
  line-height: calc(1.625rem + 3.8571428571vw);
  font-weight: 300;
  letter-spacing: -1px;
}
@media (min-width: 1400px) {
  .about_para {
    font-size: 4rem;
  }
}
@media (min-width: 1400px) {
  .about_para {
    line-height: 5rem;
  }
}
.about_para span {
  border-bottom: 1px solid #999999;
  font-weight: 400;
  color: #54595f;
}

.services.inner .icon_box {
  margin-bottom: 5.625rem;
}

.icon_box {
  transition: all 0.5s ease-in-out;
}
.icon_box h4, .icon_box .h4 {
  margin-bottom: 20px;
  margin-top: 15px;
  text-transform: uppercase;
  font-weight: 600;
}
.icon_box h6, .icon_box .h6 {
  color: #a6a182;
  margin-bottom: 8px;
  transition: all 0.5s ease-in-out;
}
.icon_box p {
  font-size: 0.875rem;
}
.icon_box img.light {
  display: none;
}
.icon_box .arrow_effect {
  margin-top: 20px;
}
.icon_box .arrow_effect a {
  position: relative;
  width: 20px;
  height: 15px;
  overflow: hidden;
  display: inline-block;
}
.icon_box .arrow_effect a .crossline1,
.icon_box .arrow_effect a .crossline2 {
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  display: inline-block;
  transition: all 0.5s ease-in-out;
}
.icon_box .arrow_effect a .crossline1::before, .icon_box .arrow_effect a .crossline1::after,
.icon_box .arrow_effect a .crossline2::before,
.icon_box .arrow_effect a .crossline2::after {
  content: "";
  position: absolute;
  right: 0;
  width: 7px;
  height: 1px;
  transform-origin: right;
  transition: all 0.5s ease-in-out;
}
.icon_box .arrow_effect a .crossline1::before,
.icon_box .arrow_effect a .crossline2::before {
  transform: rotate(40deg);
}
.icon_box .arrow_effect a .crossline1::after,
.icon_box .arrow_effect a .crossline2::after {
  transform: rotate(-40deg);
}
.icon_box .arrow_effect a .crossline1 {
  width: 15px;
  background-color: #e6e6e6;
  opacity: 1;
}
.icon_box .arrow_effect a .crossline1::before, .icon_box .arrow_effect a .crossline1::after {
  background-color: #e6e6e6;
}
.icon_box .arrow_effect a .crossline1::before {
  top: 0;
}
.icon_box .arrow_effect a .crossline1::after {
  bottom: 0;
}
.icon_box .arrow_effect a .crossline2 {
  width: 0px;
  background-color: #a6a182;
  opacity: 0;
}
.icon_box .arrow_effect a .crossline2::before, .icon_box .arrow_effect a .crossline2::after {
  background-color: #a6a182;
  transition: all 0.7s ease-in-out;
}
.icon_box .arrow_effect a .crossline2::before {
  top: -10px;
}
.icon_box .arrow_effect a .crossline2::after {
  bottom: -10px;
}
.icon_box .arrow_effect a:hover .crossline1 {
  opacity: 0;
  transform: scale(0);
}
.icon_box .arrow_effect a:hover .crossline2 {
  width: 15px;
  opacity: 1;
}
.icon_box .arrow_effect a:hover .crossline2::before {
  top: 0px;
}
.icon_box .arrow_effect a:hover .crossline2::after {
  bottom: 0px;
}
.icon_box:hover h6, .icon_box:hover .h6 {
  margin-bottom: 15px;
}
.icon_box.type-2 {
  position: relative;
  margin-bottom: 1.875rem;
}
.icon_box.type-2 .post-bg {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-out;
}
.icon_box.type-2 .icon_box_inner {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 50px 40px 55px 40px;
  z-index: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.icon_box.type-2 .icon_box_inner::before {
  content: "";
  background: rgba(0, 0, 0, 0);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.icon_box.type-2:hover .post-bg {
  opacity: 1;
  visibility: visible;
  transition: all 0.8s ease-in;
}
.icon_box.type-2:hover .icon_box_inner {
  padding-top: 100px;
}
.icon_box.type-2:hover .icon_box_inner::before {
  background: rgba(0, 0, 0, 0.75);
}

.icon_box_flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.icon_box_flex h6, .icon_box_flex .h6 {
  margin-left: 15px;
  color: #b2b2b2;
}
.icon_box_flex div .img.light {
  display: none;
}
.icon_box_flex .icon_size {
  border: 1px solid #2e2e2e;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon_box_flex .icon_size img {
  width: 21px;
  height: 21px;
}

.about_icon_box {
  margin-bottom: 25px;
}

/*------- Team -------*/
.team {
  padding-bottom: 50px;
}
.team.in-team .team-block {
  margin-bottom: 4.375rem;
}

.swiper_team {
  padding: 0rem calc(1.75rem + 5.1428571429vw);
}
@media (min-width: 1400px) {
  .swiper_team {
    padding: 0rem 6.25rem;
  }
}
.swiper_team .swiper-navigation {
  width: 100%;
  height: calc(var(--button-height));
  top: 40%;
  bottom: auto;
}
.swiper_team .swiper-navigation .swiper-button-prev, .swiper_team .swiper-navigation .swiper-rtl .swiper-button-next {
  left: 0;
  right: auto;
}
.swiper_team .swiper-navigation .swiper-button-next, .swiper_team .swiper-navigation .swiper-rtl .swiper-button-prev {
  left: auto;
  right: 0;
}
.swiper_team .swiper-navigation .swiper-button-next, .swiper_team .swiper-navigation .swiper-button-prev {
  font-size: 1.875rem;
  color: #e6e6e6;
  background: transparent;
  top: 0;
}
.swiper_team .swiper-navigation .swiper-button-next:hover, .swiper_team .swiper-navigation .swiper-button-prev:hover {
  background: transparent;
  color: #a6a182;
}
.swiper_team .swiper-navigation .swiper-button-next.swiper-button-disabled, .swiper_team .swiper-navigation .swiper-button-prev.swiper-button-disabled {
  opacity: 0.5;
  background: transparent;
}
.swiper_team:hover .swiper-navigation .swiper-button-prev {
  top: 0;
}
.swiper_team:hover .swiper-navigation .swiper-button-next {
  top: 0;
}

.team-block h5, .team-block .h5 {
  line-height: calc(1.2875rem + 0.3857142857vw);
  margin-top: calc(1.28125rem + 0.3214285714vw);
}
@media (min-width: 1400px) {
  .team-block h5, .team-block .h5 {
    line-height: 1.625rem;
  }
}
@media (min-width: 1400px) {
  .team-block h5, .team-block .h5 {
    margin-top: 1.5625rem;
  }
}
.team-block h6, .team-block .h6 {
  font-size: 0.6875rem;
  margin-top: 1.25rem;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  position: relative;
}
.team-block h6:after, .team-block .h6:after {
  position: absolute;
  content: "";
  background: #a6a182;
  width: 52px;
  height: 1px;
  right: -67px;
  top: 5px;
}

.team-member-information .name {
  font-size: calc(1.425rem + 1.8vw);
  font-weight: 400;
  line-height: calc(1.4875rem + 2.4428571429vw);
  margin-top: 0rem;
}
@media (min-width: 1400px) {
  .team-member-information .name {
    font-size: 3rem;
  }
}
@media (min-width: 1400px) {
  .team-member-information .name {
    line-height: 3.625rem;
  }
}
.team-member-information .designation {
  font-size: calc(1.2625rem + 0.1285714286vw);
  font-weight: 600;
  line-height: calc(1.4rem + 1.5428571429vw);
  margin-top: 0rem;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  position: relative;
}
@media (min-width: 1400px) {
  .team-member-information .designation {
    font-size: 1.375rem;
  }
}
@media (min-width: 1400px) {
  .team-member-information .designation {
    line-height: 2.75rem;
  }
}
.team-member-information .designation:after {
  position: absolute;
  content: "";
  background: #a6a182;
  width: 110px;
  height: 2px;
  left: calc(100% + 15px);
  top: 50%;
  transform: translateY(-50%);
}
.team-member-information .bio {
  margin-top: 0.9375rem;
}
.team-member-information .social-media {
  margin-top: 1.875rem;
  margin-bottom: 3.75rem;
}
.team-member-information .social-media ul {
  margin: 0px;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}
.team-member-information .social-media ul li {
  width: auto;
  background-image: none;
  margin-right: 10px;
  padding: 0px;
  font-weight: 600;
}
.team-member-information .social-media ul li a {
  display: block;
  font-size: 1.125rem;
  text-align: center;
  background: #545454;
  color: #ffffff;
  width: 46px;
  height: 46px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.team-member-information .social-media ul li:last-child {
  margin-right: 0px;
}
.team-member-information h6.widget-title, .team-member-information .widget-title.h6 {
  color: #ffffff;
  margin-bottom: 0.625rem;
}
.team-member-information ul.point_order {
  padding-left: 0rem;
  margin-bottom: calc(1.34375rem + 0.9642857143vw);
  list-style-type: none;
}
@media (min-width: 1400px) {
  .team-member-information ul.point_order {
    margin-bottom: 2.1875rem;
  }
}
.team-member-information ul.point_order li {
  position: relative;
  padding-left: 35px;
  line-height: 36px;
  font-size: 1rem;
  color: #ffffff;
}
.team-member-information ul.point_order li span {
  color: #a6a182;
}
.team-member-information ul.point_order li:first-letter {
  text-transform: capitalize;
}
.team-member-information ul.point_order li:before {
  content: "\f26f";
  font-family: "bootstrap-icons";
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  color: #a6a182;
}

.team-details .latest-projects {
  margin-top: 100px;
}
.team-details .project {
  margin-bottom: 1.875rem;
}

.shop_page {
  padding: 110px 0px;
}

/*
 * Shortcode: Shop Sidebar
 * -----------------------------------------------
*/
.shop_sidebar {
  position: sticky;
  position: -webkit-sticky;
  top: 30px;
  font-family: "Montserrat", sans-serif;
  /*-- Select2 Customize --*/
  /*-- Search --*/
  /*-- Category Dropdown --*/
  /*-- Price Range --*/
  /*-- Price Checkbox --*/
}
.shop_sidebar .widget {
  margin-top: 0px;
  margin-bottom: 45px;
  background: transparent;
  padding: 0px 0px;
  box-shadow: none;
}
.shop_sidebar .widget:last-child {
  margin-bottom: 0px;
}
.shop_sidebar .widget ul {
  margin: 0;
  padding: 0;
}
.shop_sidebar .widget ul li {
  list-style-type: none;
}
.shop_sidebar .widget label {
  font-size: 0.875rem;
  margin-bottom: 0px;
}
.shop_sidebar .select2-container {
  width: 100% !important;
}
.shop_sidebar .select2-container .select2-selection--single {
  height: 56px;
  line-height: 50px;
  border: 1px solid #ededed;
  padding: 5px 30px;
}
.shop_sidebar .select2-container--default .select2-selection--single .select2-selection__rendered {
  font-size: 15px;
  color: #A0A4B4;
  line-height: 44px;
  padding: 0;
}
.shop_sidebar .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 56px;
  position: absolute;
  top: 0px;
  right: 15px;
  width: 20px;
}
.shop_sidebar .select2-dropdown {
  padding: 15px;
}
.shop_sidebar .select2-search--dropdown {
  padding: 0px;
  margin-bottom: 5px;
}
.shop_sidebar .select2-results__option {
  padding: 3px 10px;
  transition: all 0.8s ease-in-out;
}
.shop_sidebar .select2-container--default .select2-results__option--selected,
.shop_sidebar .select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #a6a182;
  color: #ffffff;
  transition: all 0.8s ease-in-out;
}
.shop_sidebar .sidebar_search {
  text-align: left;
}
.shop_sidebar .sidebar_search .search_form {
  position: relative;
}
.shop_sidebar .sidebar_search .search_form .form-control {
  padding: 5px 15px;
  height: 48px;
  border: 1px solid #2e2e2e;
  border-radius: 0px;
  color: #ffffff;
  background: #2e2e2e;
  text-transform: capitalize;
}
.shop_sidebar .sidebar_search .search_form .form-control:focus {
  box-shadow: none;
}
.shop_sidebar .sidebar_search .search_form .form-control-submit {
  position: absolute;
  top: 0px;
  right: 0px;
  border: 1px solid #545454;
  border-radius: 0%;
  padding: 0;
  width: 48px;
  height: 48px;
  color: #ffffff;
  background: #545454;
  cursor: pointer;
}
.shop_sidebar .sidebar_search .search_form .form-control-submit:focus {
  outline: none;
}
.shop_sidebar .sidebar_search .search_form ::-webkit-input-placeholder {
  font-size: 13px;
  font-weight: 400;
  color: #999999;
}
.shop_sidebar .sidebar_search .search_form ::-moz-placeholder {
  font-size: 13px;
  font-weight: 400;
  color: #999999;
}
.shop_sidebar .sidebar_search .search_form :-ms-input-placeholder {
  font-size: 13px;
  font-weight: 400;
  color: #999999;
}
.shop_sidebar .sidebar_search .search_form :-moz-placeholder {
  font-size: 13px;
  font-weight: 400;
  color: #999999;
}
.shop_sidebar .sidebar_search .search_form :placeholder {
  font-size: 13px;
  font-weight: 400;
  color: #999999;
}
.shop_sidebar .widget_categories .category_item_inner {
  position: relative;
  min-height: 1.5rem;
  padding-left: 0;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  flex-direction: unset;
  justify-content: space-between;
}
.shop_sidebar .widget_categories ul li {
  margin-bottom: 4px;
  border-bottom: none;
  padding: 5px 0px;
}
.shop_sidebar .widget_categories ul li ul {
  padding-left: 15px;
}
.shop_sidebar .widget_categories ul li ul li {
  position: relative;
  padding-left: 10px;
}
.shop_sidebar .widget_categories ul li ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 6px;
  height: 1px;
  background: #ffffff;
}
.shop_sidebar .widget_categories ul li .cat_toggler a {
  font-size: 0.75rem;
}
.shop_sidebar .widget_categories ul li .cat_toggler i {
  display: block;
  transition: all 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .shop_sidebar .widget_categories ul li .cat_toggler i {
    transition: none;
  }
}
.shop_sidebar .widget_categories ul li .cat_toggler [aria-expanded=true] i {
  transform: rotate(90deg);
  color: #a6a182;
}
.shop_sidebar .sidebar_price_filter .range-value {
  margin-top: 10px;
  font-size: 0.75rem;
  color: #999999;
}
.shop_sidebar .sidebar_price_filter .range-value span {
  margin-right: 10px;
}
.shop_sidebar .sidebar_price_filter .range-value input[type=text] {
  background-color: transparent;
  color: #999999;
  padding: 0px;
  width: 80px;
  height: auto;
  border-width: 0px;
  text-align: right;
  font-size: 0.75rem;
}
.shop_sidebar .sidebar_price_filter .range-bar {
  border: none;
  background: #999999;
  height: 2px;
  width: 100%;
}
.shop_sidebar .sidebar_price_filter .range-bar .ui-slider-range {
  background: #ffffff;
}
.shop_sidebar .sidebar_price_filter .range-bar .ui-slider-handle {
  width: 12px;
  height: 12px;
  border-width: 0px;
  cursor: pointer;
  margin-top: 0px;
  margin-left: -5px;
}
.shop_sidebar .sidebar_price_filter .range-bar .ui-slider-handle {
  background: #ffffff;
  border-radius: 100%;
}
.shop_sidebar .form-check {
  position: relative;
  min-height: auto;
  padding-left: 0;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.shop_sidebar .form-check label {
  color: #ffffff;
  line-height: 19px;
  font-weight: 400;
}
.shop_sidebar .form-check .form-check-input {
  float: none;
  margin: 0px 7px 0px 0px;
  width: 12px;
  height: 12px;
  border: 1px solid #545454;
  background-color: transparent;
  border-radius: 3px;
}
.shop_sidebar .form-check-input:checked {
  border-color: #ffffff;
  background-color: #212121;
  box-shadow: none;
}
.shop_sidebar .form-check-input:focus {
  outline: none;
  box-shadow: none;
}
.shop_sidebar .content_count {
  background: #FFF2F0;
  display: inline-block;
  font-size: 10px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 1.125rem;
  color: #212121;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

/*-- Popular Products --*/
.sidebar_popular_products ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.sidebar_popular_products ul li .popular_product_image {
  min-width: 85px;
  margin-right: 15px;
  max-width: 85px;
  background: #000000;
  height: 110px;
  display: flex;
  align-items: center;
  padding: 15px;
}
.sidebar_popular_products ul li .popular_product_content .product_title {
  font-size: 1rem;
  font-weight: 600;
  margin: 0px 0px 10px 0px;
}
.sidebar_popular_products ul li .popular_product_content .product_title a {
  color: #ffffff;
}
.sidebar_popular_products ul li .popular_product_content .product_price {
  font-size: 0.875rem;
  font-weight: 400;
  color: #999999;
}

/*-- ============================================== --*/
/*-- ================ Main Content ================ --*/
/*-- ============================================== --*/
/*-- Filtering Method --*/
.shop_filtering_method {
  justify-content: space-between;
  margin-bottom: 30px;
}
.shop_filtering_method .view_type_wrapper {
  min-width: 300px;
}
.shop_filtering_method .view_type_wrapper .view_type a {
  font-size: 1rem;
  color: #ffffff;
  display: inline-block;
  text-align: center;
}
.shop_filtering_method .view_type_wrapper .view_type a.active {
  color: #a6a182;
}
.shop_filtering_method .view_type_wrapper .view_type li:not(:last-child) {
  margin-right: 8px;
}
.shop_filtering_method .view_type_wrapper .showing_results {
  font-size: 0.875rem;
  color: #999999;
  margin-left: 20px;
}
.shop_filtering_method .sorting_select {
  position: relative;
  width: 260px;
}
.shop_filtering_method .select2-container .select2-selection--single {
  width: 260px;
  height: 48px;
  line-height: normal;
  background-color: transparent;
  border: 1px solid #545454;
  border-radius: 0px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.shop_filtering_method .select2-container--default .select2-selection--single .select2-selection__rendered {
  font-size: 15px;
  color: #999999;
  line-height: 44px;
  padding: 0;
}
.shop_filtering_method .select2-container--default .select2-selection--single .select2-selection__arrow {
  position: relative;
  top: auto;
  right: 15px;
  width: auto;
  height: auto;
}
.shop_filtering_method .select2-container--default .select2-selection--single .select2-selection__arrow b {
  position: relative;
  top: auto;
  left: auto;
  width: 0;
  height: 5px;
  display: inline-block;
  border-color: #fff transparent transparent transparent;
  border-style: solid;
  border-width: 0px 0px 0 0px;
  margin-left: 0;
  margin-top: 0;
}
.shop_filtering_method .select2-container--default .select2-selection--single .select2-selection__arrow b::before {
  content: "\f282";
  font-family: "bootstrap-icons";
  font-size: 10px;
}

/*------ Product Column ------*/
.product_col_1,
.product_col_2,
.product_col_3,
.product_col_4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.product_col_1 .product_item,
.product_col_2 .product_item,
.product_col_3 .product_item,
.product_col_4 .product_item {
  position: relative;
  width: 100%;
  min-height: 1px;
  -webkit-box-flex: 0;
  padding-right: 15px;
  padding-left: 15px;
  box-sizing: border-box;
}

.product_col_1 .product_item {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.product_col_2 .product_item {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.product_col_3 .product_item {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.product_col_4 .product_item {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

@media screen and (max-width: 991px) {
  .product_col_3 .product_item, .product_col_4 .product_item {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .product_col_2 .product_item, .product_col_3 .product_item, .product_col_4 .product_item {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
/*----- Product Grid Item ------*/
.product_view_grid .product_item {
  position: relative;
  overflow: hidden;
}
.product_view_grid .product_item .product_thumb {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: 30px;
}
.product_view_grid .product_item .product_thumb .product_imagebox {
  position: relative;
  background: #000000;
  text-align: center;
  height: 325px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.8s ease-in-out;
}
.product_view_grid .product_item .product_thumb .product_imagebox .primary_img {
  transition: all 0.8s ease-in-out;
}
.product_view_grid .product_item .product_thumb .product_imagebox [class*=badge] {
  position: absolute;
  top: 15px;
  left: 15px;
  border-radius: 0;
  padding: 0px 0px;
  width: 50px;
  line-height: 24px;
  z-index: 1;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0px;
}
.product_view_grid .product_item .product_thumb .product_imagebox .badge-new {
  background-color: #ffffff;
  color: #000000;
}
.product_view_grid .product_item .product_thumb .product_imagebox .badge-sale {
  background-color: #545454;
  color: #ffffff;
}
.product_view_grid .product_item .product_thumb .product_item_inner {
  transition: all 0.8s ease-in-out;
}
.product_view_grid .product_item .product_thumb .product_item_inner .label_text {
  padding: 20px 0px 0px 0px;
  transition: all 0.8s ease-in-out;
}
.product_view_grid .product_item .product_thumb .product_item_inner .label_text .product_item_name {
  position: relative;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product_view_grid .product_item .product_thumb .product_item_inner .label_text .product_item_name a {
  color: #ffffff;
}
.product_view_grid .product_item .product_thumb .product_item_inner .product_item_price {
  font-size: 1rem;
  font-weight: 500;
  color: #999999;
  padding-bottom: 0px;
}
.product_view_grid .product_item .product_thumb .product_item_inner .product_item_price del {
  color: #54595f;
  margin-right: 5px;
}
.product_view_grid .product_item .product_thumb .product_item_inner p {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 22px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.product_view_grid .product_item .product_thumb .product_item_inner .product_category {
  font-size: 0.75rem;
  color: #999999;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}
.product_view_grid .product_item .product_thumb .product_item_inner .product_category a {
  color: #999999;
}
.product_view_grid .product_item .product_thumb .product_item_inner .product_category a:hover {
  color: #ffffff;
}
.product_view_grid .product_item .product_thumb .product_item_inner .rating ul {
  display: block;
  padding: 0px 0px;
  margin: 0px 0px;
  line-height: 16px;
}
.product_view_grid .product_item .product_thumb .product_item_inner .rating ul li {
  display: inline-block;
  font-size: 0.875rem;
  line-height: 14px;
  color: #a6a182;
  margin: 0px;
}
.product_view_grid .product_item .product_thumb .cart_button {
  position: absolute;
  width: 100%;
  text-align: center;
  top: -100%;
  transform: translateY(-50%);
  transition: all 0.8s ease-in-out;
  z-index: 1;
}
.product_view_grid .product_item:hover .product_thumb .cart_button {
  top: 50%;
}

.cart_button .button {
  position: relative;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  min-width: auto;
  display: inline-block;
  border: none;
  padding: 10px 30px;
  outline: none;
  background: transparent;
  overflow: hidden;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.8s ease-in-out;
  color: #000000;
}
.cart_button .button:hover {
  color: #ffffff;
}
.cart_button .button:before, .cart_button .button:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: -1;
  transition: all 0.8s ease-in-out;
}
.cart_button .button:before {
  right: 100%;
  top: 0;
  background: #a6a182;
}
.cart_button .button:hover::before {
  right: 0;
}
.cart_button .button:after {
  left: 0;
  top: 0;
  background: #ffffff;
}
.cart_button .button:hover::after {
  left: 100%;
}

/*------ Product List Item -------*/
.product_view_list .grid-item:not(:last-child) {
  margin-bottom: 30px;
}
.product_view_list .product_item {
  position: relative;
  overflow: hidden;
  background: #212121;
  padding: 30px;
  margin-bottom: 30px;
  display: none;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
}
.product_view_list .product_item .product_thumb {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
}
.product_view_list .product_item .product_thumb .product_imagebox {
  position: relative;
  background: #000000;
  text-align: center;
  height: 325px;
  width: 270px;
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.8s ease-in-out;
}
.product_view_list .product_item .product_thumb .product_imagebox .primary_img {
  transition: all 0.8s ease-in-out;
}
.product_view_list .product_item .product_thumb .product_imagebox .secondary_img {
  position: absolute;
  left: 50%;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%);
  transition: all 0.8s ease-in-out;
}
.product_view_list .product_item .product_thumb .product_imagebox [class*=badge] {
  position: absolute;
  top: 15px;
  left: 15px;
  border-radius: 0;
  padding: 0px 0px;
  width: 50px;
  line-height: 24px;
  z-index: 1;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0px;
}
.product_view_list .product_item .product_thumb .product_imagebox .badge-new {
  background-color: #ffffff;
  color: #000000;
}
.product_view_list .product_item .product_thumb .product_imagebox .badge-sale {
  background-color: #545454;
  color: #ffffff;
}
.product_view_list .product_item .product_thumb .product_item_inner {
  transition: all 0.8s ease-in-out;
  flex: 1;
}
.product_view_list .product_item .product_thumb .product_item_inner .label_text {
  transition: all 0.8s ease-in-out;
}
.product_view_list .product_item .product_thumb .product_item_inner .label_text .product_item_name {
  position: relative;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0px;
  color: #ffffff;
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
}
.product_view_list .product_item .product_thumb .product_item_inner .label_text .product_item_name a {
  color: #ffffff;
}
.product_view_list .product_item .product_thumb .product_item_inner .product_item_price {
  font-size: 1rem;
  font-weight: 500;
  color: #999999;
  padding-bottom: 0px;
  margin-left: 15px;
}
.product_view_list .product_item .product_thumb .product_item_inner .product_item_price del {
  color: #54595f;
  margin-right: 5px;
}
.product_view_list .product_item .product_thumb .product_item_inner p {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 22px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.product_view_list .product_item .product_thumb .product_item_inner .product_category {
  font-size: 0.75rem;
  color: #999999;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}
.product_view_list .product_item .product_thumb .product_item_inner .product_category a {
  color: #999999;
}
.product_view_list .product_item .product_thumb .product_item_inner .product_category a:hover {
  color: #ffffff;
}
.product_view_list .product_item .product_thumb .product_item_inner .rating {
  margin-bottom: 30px;
}
.product_view_list .product_item .product_thumb .product_item_inner .rating ul {
  display: block;
  padding: 0px 0px;
  margin: 0px 0px;
  line-height: 16px;
}
.product_view_list .product_item .product_thumb .product_item_inner .rating ul li {
  display: inline-block;
  font-size: 0.875rem;
  line-height: 14px;
  color: #a6a182;
  margin: 0px;
}
.product_view_list .product_item .cart_button {
  position: relative;
  z-index: 1;
}

/*----- Single Product -----*/
.product_left .product_zoom {
  display: flex;
  align-items: flex-start;
}
.product_left .product_zoom .product_zoom_button_group {
  padding: 0px;
  margin: 0px;
  list-style: none;
  max-height: 543px;
  overflow-y: scroll;
  display: inline-block;
  padding-right: 23px;
}
.product_left .product_zoom .product_zoom_button_group::-webkit-scrollbar {
  width: 0px;
  height: 4px;
}
.product_left .product_zoom .product_zoom_button_group::-webkit-scrollbar-track {
  background: #212121;
}
.product_left .product_zoom .product_zoom_button_group::-webkit-scrollbar-thumb {
  background: #a6a182;
}
.product_left .product_zoom .product_zoom_button_group li {
  text-align: left;
  margin: 20px 0px;
}
.product_left .product_zoom .product_zoom_button_group li:first-child {
  margin-top: 0px;
}
.product_left .product_zoom .product_zoom_button_group li:last-child {
  margin-bottom: 0px;
}
.product_left .product_zoom .product_zoom_button_group li a {
  position: relative;
  display: block;
  cursor: pointer;
  width: 145px;
  max-width: 100%;
  height: 175px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.product_left .product_zoom .product_zoom_button_group li a img {
  width: 100%;
}
.product_left .product_zoom .product_zoom_button_group li a.selected:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(33, 33, 33, 0.5);
}
.product_left .product_zoom .product_zoom_container {
  flex: 1;
  max-height: 560px;
  overflow: hidden;
}
.product_left .product_zoom .product_zoom_container .product_zoom_info {
  position: relative;
  display: none;
}
.product_left .product_zoom .product_zoom_container .product_zoom_info img {
  width: 100%;
}

.product_right .product_info .product_title h2, .product_right .product_info .product_title .h2 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.375rem;
  color: #ffffff;
}
.product_right .product_info .product_rating {
  font-size: 0.875rem;
  font-weight: 400;
}
.product_right .product_info .product_rating i {
  color: #a6a182;
}
.product_right .product_info .product_rating .product_rating_details {
  display: inline-block;
  margin-left: 10px;
  color: #999999;
}
.product_right .product_description {
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #b2b2b2;
  margin-bottom: 20px;
}
.product_right .product_price {
  font-size: 1.125rem;
  line-height: 1.875rem;
  font-weight: 400;
  color: #ffffff;
  margin-top: 15px;
  margin-bottom: 25px;
}
.product_right .product_view_bottom {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}
.product_right .product_view_bottom .product_quantity {
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 20px;
}
.product_right .product_view_bottom .product_quantity .pproduct_quantity_label {
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #ffffff;
}
.product_right .product_view_bottom .product_quantity .product_quantity_inner {
  display: flex;
  align-items: center;
  border: 1px solid #ffffff;
  margin-left: 20px;
  padding: 0px 20px;
}
.product_right .product_view_bottom .product_quantity .product_quantity_inner .product_quantity_subtract {
  color: #ffffff;
  cursor: pointer;
}
.product_right .product_view_bottom .product_quantity .product_quantity_inner .product_quantity_add {
  color: #ffffff;
  cursor: pointer;
}
.product_right .product_view_bottom .product_quantity .product_quantity_inner input {
  width: 80px;
  height: 42px;
  padding: 0;
  text-align: center;
  background-color: transparent;
  color: #ffffff;
  border: 0px solid #000;
  font-weight: 700;
}
.product_right .product_view_bottom .wish_button {
  margin-right: 15px;
}
.product_right .product_view_bottom .wish_button a,
.product_right .product_view_bottom .compare_button a {
  width: 44px;
  height: 44px;
  line-height: 44px;
  display: block;
  background: #F2F7F7;
  text-align: center;
  color: #212121;
}
.product_right .cart_button {
  margin-top: 20px;
}
.product_right .cart_button .button {
  width: 100%;
  font-size: 0.75rem;
  line-height: 1.375rem;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 25px;
  z-index: 1;
  color: #ffffff;
}
.product_right .cart_button .button:before {
  background: #a6a182;
}
.product_right .cart_button .button:after {
  background: #000000;
}
.product_right .product_view_bottom_credential {
  margin-top: 20px;
}
.product_right .product_view_bottom_credential ul {
  list-style: none;
}
.product_right .product_view_bottom_credential ul li {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.625rem;
  color: #999999;
  padding: 3px 0px;
}
.product_right .product_view_bottom_credential ul li span {
  font-weight: 500;
  color: #ffffff;
  margin-right: 5px;
}
.product_right .product_view_bottom_credential ul li a {
  position: relative;
  line-height: 20px;
  display: inline-block;
  color: #999999;
}
.product_right .product_view_bottom_credential ul li.tags {
  list-style-type: none;
  display: inline-block;
  overflow: hidden;
}
.product_right .product_view_bottom_credential ul li.tags a {
  position: relative;
  line-height: 20px;
  display: inline-block;
  color: var(--body-color);
  margin: 0px 5px;
}
.product_right .product_view_bottom_credential ul li.tags a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  width: 0%;
  height: 1px;
  background: #a6a182;
  transition: all 0.5s ease;
}
.product_right .product_view_bottom_credential ul li.tags a::after {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  width: 99%;
  height: 1px;
  background: #999999;
  transition: all 0.5s ease;
}
.product_right .product_view_bottom_credential ul li.tags a:hover {
  color: #a6a182;
}
.product_right .product_view_bottom_credential ul li.tags a:hover::before {
  width: 99%;
}
.product_right .product_view_bottom_credential ul li.tags a:hover::after {
  left: 101%;
  opacity: 0;
  visibility: hidden;
}
.product_right .product_social_share {
  margin-top: 20px;
}
.product_right .product_social_share ul {
  display: flex;
  flex-flow: row wrap;
  padding: 0px;
  margin-bottom: 0px;
  gap: 10px;
}
.product_right .product_social_share ul li {
  position: relative;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.625rem;
  color: #ffffff;
  list-style: none;
}
.product_right .product_social_share ul li a {
  position: relative;
  overflow: hidden;
  font-size: 0.75rem;
  text-align: center;
  color: #ffffff;
  background: #2e2e2e;
  display: block;
  outline: none;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 100%;
}
.product_right .product_social_share ul li a:hover {
  color: #a6a182;
}

/*-- Product Details --*/
.product_details_section {
  position: relative;
  z-index: 1;
  padding: 20px 0px;
}

.product_details_tab .nav {
  gap: 60px;
  border-bottom: 1px solid #54595f;
  padding-bottom: 0.625rem;
}
.product_details_tab .nav li a {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #ffffff;
}
.product_details_tab .nav li a.active {
  color: #a6a182;
}
.product_details_tab .tab-content {
  padding-top: 1.875rem;
}
.product_details_tab .tab-content p {
  line-height: 26px;
}
.product_details_tab .product_additional_info ul {
  list-style: none;
}
.product_details_tab .product_additional_info ul li {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.625rem;
  color: #999999;
  padding: 3px 0px;
}
.product_details_tab .product_additional_info ul li span {
  font-weight: 500;
  color: #ffffff;
  margin-right: 5px;
}
.product_details_tab .product_additional_info ul li a {
  position: relative;
  line-height: 20px;
  display: inline-block;
  color: #999999;
}

/*-------- Reviews ------------*/
.review_sec {
  margin-bottom: 60px;
}
.review_sec .review_title {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.625rem;
  margin-bottom: 15px;
  color: #ffffff;
}
.review_sec .review_title a {
  color: #ffffff;
  font-weight: 600;
}
.review_sec ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0px;
}
.review_sec ul ul {
  padding-left: 90px;
}
.review_sec ul.review_area .reviewer_div {
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
}
.review_sec ul.review_area .reviewer_div .reviewer {
  margin-right: 20px;
  min-width: 80px;
  max-width: 80px;
  max-height: 80px;
  border-radius: 100%;
  overflow: hidden;
}
.review_sec ul.review_area .reviewer_div .review_block .product_rating {
  color: #a6a182;
  line-height: 16px;
  margin-bottom: 10px;
}
.review_sec ul.review_area .reviewer_div .review_block .reviewer_name {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0px;
  color: #ffffff;
  text-transform: capitalize;
}
.review_sec ul.review_area .reviewer_div .review_block .reviewer_review {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625rem;
  color: #ffffff;
  margin-top: 5px;
  margin-bottom: 12px;
}
.review_sec ul.review_area .reviewer_div .review_block .review_date {
  font-size: 0.75rem;
  font-weight: 400;
  color: #ffffff;
  background-color: #545454;
  padding: 4px 10px;
  display: inline-block;
  border-radius: 5px;
}
.review_sec ul.review_area .reviewer_div .review_block .reply a {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-weight: 400;
  color: #a6a182;
}
.review_sec .reviews-pagination {
  margin-bottom: 0px;
  list-style-type: none;
  align-items: center;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0px;
}
.review_sec .reviews-pagination a, .review_sec .reviews-pagination span {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: block;
  background: #212121;
  color: #ffffff;
  border: 0px solid transparent;
  margin-right: 2px;
}
.review_sec .reviews-pagination a:hover, .review_sec .reviews-pagination a.current, .review_sec .reviews-pagination span:hover, .review_sec .reviews-pagination span.current {
  background: #a6a182;
  color: #ffffff;
}

.review_form .review_form_heading_wrapper .review_form_title {
  position: relative;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.875rem;
  color: #ffffff;
  margin-bottom: 7px;
}
.review_form .review_form_heading_wrapper .review_form_sub_title {
  font-size: 0.875rem;
  color: #6e7a7a;
  margin-bottom: 18px;
}
.review_form .review_form_heading_wrapper .give_rating {
  color: #999999;
}
.review_form .review_form_heading_wrapper .reviewer_rating {
  color: #a6a182;
}
.review_form select, .review_form input[type=text], .review_form input[type=email], .review_form input[type=url], .review_form input[type=password], .review_form input[type=search], .review_form input[type=number], .review_form input[type=tel], .review_form input[type=range], .review_form input[type=date], .review_form input[type=month], .review_form input[type=week], .review_form input[type=time], .review_form input[type=datetime], .review_form input[type=datetime-local], .review_form input[type=color] {
  height: 60px;
}
.review_form .button {
  font-size: 1rem;
}

.related_products {
  position: relative;
  z-index: 1;
}
.related_products .widget-title {
  color: #ffffff;
  text-transform: capitalize;
}

@media screen and (max-width: 991px) {
  .product_right {
    margin-top: 50px;
  }
}
@media screen and (max-width: 767px) {
  .product_right {
    padding-right: 0px;
  }
}
@media screen and (max-width: 500px) {
  .product_left .product_zoom {
    display: block;
  }
  .product_left .product_zoom .product_zoom_button_group {
    width: 100%;
    padding-right: 0px;
    overflow-x: scroll;
    display: flex;
    margin-bottom: 30px;
  }
  .product_left .product_zoom .product_zoom_button_group li {
    margin: 0px 5px;
  }
  .product_left .product_zoom .product_zoom_button_group li:first-child {
    margin-left: 0px;
  }
  .product_left .product_zoom .product_zoom_button_group li:last-child {
    margin-right: 0px;
  }
  .product_left .product_zoom .product_zoom_container {
    width: 100%;
  }
  .product_right .product_info .product_rating .product_rating_details {
    display: block;
    margin: 0px;
  }
  .product_right .product_info .product_rating .product_add_review {
    display: block;
    margin: 0px;
  }
  .product_right .product_view_bottom .product_quantity {
    margin-bottom: 20px;
  }
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #545454;
  height: 40px;
}

.select2-dropdown {
  background-color: #1b1b1b;
  border-color: #545454;
}

.select2-results__option {
  padding: 5px 5px;
  font-size: 0.875rem;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #2e2e2e;
  color: #ffffff;
}

.select2-container--default .select2-results__option--selected {
  background-color: #a6a182;
  color: #ffffff;
}

.cart_table {
  border-collapse: collapse;
  width: 100%;
  border: 0px;
  margin-bottom: 0px;
}
.cart_table td, .cart_table th {
  border-right: 0px;
  border-bottom: 1px solid #2e2e2e;
  text-align: left;
}
.cart_table .cart_header th {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375rem;
  text-transform: uppercase;
  color: #999999;
  padding: 15px 0px;
}
.cart_table .cart_header th.cart_header_image {
  width: 20%;
}
.cart_table .cart_header th.cart_header_title {
  width: 20%;
}
.cart_table .cart_header th.cart_header_price {
  width: 15%;
}
.cart_table .cart_header th.cart_header_quantity {
  width: 25%;
}
.cart_table .cart_content td {
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 15px 0px;
  color: #ffffff;
}
.cart_table .cart_content .cart_image img {
  max-width: 65px;
}
.cart_table .cart_content .cart_quantity .product_quantity_inner {
  display: inline-flex;
  align-items: center;
  border: 1px solid #545454;
  padding: 0px 20px;
  width: 146px;
}
.cart_table .cart_content .cart_quantity .product_quantity_inner .product_quantity_subtract {
  color: #ffffff;
  cursor: pointer;
}
.cart_table .cart_content .cart_quantity .product_quantity_inner .product_quantity_add {
  color: #ffffff;
  cursor: pointer;
}
.cart_table .cart_content .cart_quantity .product_quantity_inner input {
  width: 80px;
  height: 42px;
  padding: 0;
  text-align: center;
  background-color: transparent;
  color: #ffffff;
  border: 0px solid #000;
}
.cart_table .cart_content .cart_total {
  color: #a6a182;
}
.cart_table .cart_content .cart_removal {
  text-align: right;
}
.cart_table .cart_content .cart_removal a {
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 38px;
  text-align: center;
  border-radius: 100%;
  background: #999999;
}

.couponcart {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 35px 0px;
}
.couponcart .set_coupon {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.couponcart .form-group {
  margin-bottom: 0px;
  margin-right: 18px;
}
.couponcart .form-group input[type=text] {
  border: 1px solid #999999;
  text-align: left;
  width: 250px;
  height: 46px;
  padding: 10px 20px;
  border-radius: 0;
}
.couponcart ::-webkit-input-placeholder {
  color: #999999;
}
.couponcart ::-moz-placeholder {
  color: #999999;
}
.couponcart :-ms-input-placeholder {
  color: #999999;
}
.couponcart :-moz-placeholder {
  color: #999999;
}
.couponcart :placeholder {
  color: #999999;
}
.couponcart .button_group .button {
  font-size: 11px;
  font-weight: 600;
  line-height: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 15px 30px;
  min-width: auto;
}

.grand_total {
  margin-top: 60px;
  padding: 60px;
  background-color: #000000;
}
.grand_total .widget-title {
  color: #ffffff;
  text-transform: capitalize;
}
.grand_total ul {
  margin: 0px;
  padding: 0px;
}
.grand_total ul li {
  list-style: none;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #545454;
  color: #999999;
}
.grand_total ul li span.text {
  min-width: 220px;
  color: #ffffff;
}
.grand_total ul li.totalvalue {
  color: #ffffff;
  font-weight: 600;
}
.grand_total .btn_group {
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  .cart_table {
    width: 100%;
    display: block;
    overflow-x: scroll;
  }
  .cart_table .cart_header th:not(:last-child),
  .cart_table .cart_content td:not(:last-child) {
    padding-right: 30px;
  }
  .cart_table::-webkit-scrollbar {
    height: 5px;
  }
  .cart_table::-webkit-scrollbar-track {
    background: #2e2e2e;
    border-radius: 5px;
  }
  .cart_table::-webkit-scrollbar-thumb {
    background: #545454;
    border-radius: 5px;
  }
  .grand_total {
    padding: 30px;
  }
  .grand_total .button_group .button {
    min-width: auto;
    padding: 12px 20px;
  }
  .couponcart {
    display: block;
  }
  .couponcart .form-group {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .couponcart .form-group input[type=text] {
    width: 100%;
  }
  .couponcart .button_group {
    width: 100%;
  }
  .couponcart .button_group .button {
    width: 100%;
  }
  .couponcart .cartupdate {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #f2f7f7;
  }
}
/* Forms (shipping_form) */
.shipping_form {
  /* Coupon Code */
}
.shipping_form .widget-title {
  color: #ffffff;
  text-transform: capitalize;
}
.shipping_form .select2-container--default .select2-selection--single .select2-selection__arrow,
.shipping_form .select2-container .select2-selection--single,
.shipping_form .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 64px;
  line-height: 64px;
  right: 5px;
  border: none;
}
.shipping_form .select2-container--default .select2-selection--single .select2-selection__arrow b {
  position: relative;
  top: auto;
  left: auto;
  width: 0;
  height: 5px;
  display: inline-block;
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 0px 0px 0 0px;
  margin-left: 0;
  margin-top: 0;
}
.shipping_form .select2-container--default .select2-selection--single .select2-selection__arrow b::before {
  content: "\f282";
  font-family: "bootstrap-icons";
  font-size: 10px;
}
.shipping_form .select2-container--default .select2-selection--single .select2-selection__rendered,
.shipping_form .select2-container .select2-selection--single .select2-selection__rendered {
  padding: 0px;
  line-height: 64px;
}
.shipping_form .select2-container--default .select2-selection--multiple,
.shipping_form .select2-container--default .select2-selection--single {
  display: block;
  width: 100%;
  height: 64px;
  padding: 0px 40px;
  font-size: 0.875rem;
  line-height: 1.5;
  background-color: #000000;
  background-clip: padding-box;
  border: 1px solid #000000;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-backface-visibility: hidden;
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
  outline-offset: 0;
}
.shipping_form .select2-container--default .select2-selection--multiple:focus,
.shipping_form .select2-container--default .select2-selection--single:focus,
.shipping_form .select2-container--default.select2-container--focus .select2-selection--multiple,
.shipping_form .select2-container--default.select2-container--focus .select2-selection--single {
  border-color: #a6a182;
  outline: none;
  outline-offset: 0px;
}
.shipping_form label {
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-transform: capitalize;
  color: #ffffff;
  margin-top: 0px;
  margin-bottom: 10px;
}
.shipping_form .form-group {
  margin-top: 10px;
  margin-bottom: 15px;
  display: inline-block;
  width: 100%;
}
.shipping_form textarea.form-control {
  height: 90px;
}
.shipping_form .form-control:focus {
  background-color: #2e2e2e;
  border: 1px solid #a6a182;
  outline: 0;
  box-shadow: none;
}
.shipping_form .have_coupon {
  background: #2e2e2e;
  border-left: 7px solid #545454;
  padding: 30px 15px;
  margin-bottom: 55px;
}
.shipping_form .have_coupon .form-group {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin: 0px 0px;
}
.shipping_form .have_coupon .form-group label {
  font-size: 1.125rem;
  color: #ffffff;
  line-height: 26px;
  margin-right: 12px;
  margin-bottom: 0px;
}
.shipping_form .have_coupon .form-group input[type=text] {
  width: 250px;
  height: 45px;
}
.shipping_form .have_coupon ::-webkit-input-placeholder {
  font-weight: 400;
  color: #999999;
}
.shipping_form .have_coupon ::-moz-placeholder {
  font-weight: 400;
  color: #999999;
}
.shipping_form .have_coupon :-ms-input-placeholder {
  font-weight: 400;
  color: #999999;
}
.shipping_form .have_coupon :-moz-placeholder {
  font-weight: 400;
  color: #999999;
}
.shipping_form .have_coupon :placeholder {
  font-weight: 400;
  color: #999999;
}
.shipping_form .form-check-input {
  width: 12px;
  height: 12px;
  border: 1px solid #545454;
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
}
.shipping_form .checkbox {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 0px;
  color: #999999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.shipping_form .checkbox:not(:last-child) {
  margin-right: 15px;
}
.shipping_form .checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}
.shipping_form .checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  border: 1px solid #545454;
}
.shipping_form .checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  top: 3px;
  left: 3px;
  width: 7px;
  height: 7px;
  border-radius: 1000%;
  background: #ffffff;
}
.shipping_form .checkbox:hover input ~ .checkmark {
  border: 1px solid #a6a182;
}
.shipping_form .checkbox input:checked ~ .checkmark {
  border: 1px solid #a6a182;
}
.shipping_form .checkbox input:checked ~ .checkmark:after {
  display: block;
}

.place_order {
  /* Customize Radio Button */
}
.place_order ul {
  margin: 0px;
  padding: 0px;
}
.place_order ul li {
  list-style: none;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  padding: 14px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #545454;
  color: #999999;
}
.place_order ul li:first-child {
  padding-top: 0px;
}
.place_order ul li .form-group {
  width: auto;
  margin: 0px;
}
.place_order ul li span.text {
  min-width: 220px;
  color: #ffffff;
}
.place_order ul li.totalvalue {
  color: #ffffff;
  font-weight: 600;
}
.place_order ul li.totalvalue span.text {
  margin-right: 10px;
}
.place_order label.radio_circle {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 0px;
  color: #999999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.place_order label.radio_circle:not(:last-child) {
  margin-right: 15px;
}
.place_order label.radio_circle input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}
.place_order label.radio_circle .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  border: 1px solid #545454;
}
.place_order label.radio_circle .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  top: 3px;
  left: 3px;
  width: 7px;
  height: 7px;
  border-radius: 1000%;
  background: #ffffff;
}
.place_order label.radio_circle:hover input ~ .checkmark {
  border: 1px solid #a6a182;
}
.place_order label.radio_circle input:checked ~ .checkmark {
  border: 1px solid #a6a182;
}
.place_order label.radio_circle input:checked ~ .checkmark:after {
  display: block;
}
.place_order .paymentmethod {
  margin-top: 40px;
}
.place_order .paymentmethod label.radio_circle {
  display: block;
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
}
.place_order .paymentmethod label.radio_circle:not(:last-child) {
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.place_order .paymentmethod span.text {
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  color: #999999;
  margin-top: 10px;
}
.place_order .btn_group {
  margin-top: 25px;
}

body {
  font-family: "Karla", sans-serif;
}

.pricing-table-subtitle {
  margin-top: 68px;
  font-weight: normal;
}

.pricing-table-title {
  font-weight: bold;
  margin-bottom: 68px;
}

.pricing-card {
  border: none;
  border-radius: 10px;
  margin-bottom: 40px;
  text-align: center;
  transition: all 0.6s;
}
.pricing-card:hover {
  box-shadow: 0 2px 40px 0 rgba(205, 205, 205, 0.55);
}
.pricing-card.pricing-card-highlighted {
  box-shadow: 0 2px 40px 0 rgba(205, 205, 205, 0.55);
}
.pricing-card:hover {
  box-shadow: 0 2px 40px 0 rgba(205, 205, 205, 0.55);
  transform: translateY(-10px);
}
.pricing-card .card-body {
  padding-top: 55px;
  padding-bottom: 62px;
}

.pricing-plan-title {
  font-size: 20px;
  color: #000;
  margin-bottom: 11px;
  font-weight: normal;
}
.pricing-plan-cost {
  font-size: 30px;
  color: #000;
  font-weight: bold;
  margin-bottom: 5px;
}
.pricing-plan-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  font-size: 40px;
  line-height: 1;
  margin-bottom: 24px;
}
.pricing-plan-basic .pricing-plan-icon {
  color: #fe397a;
}
.pricing-plan-pro .pricing-plan-icon {
  color: #10bb87;
}
.pricing-plan-enterprise .pricing-plan-icon {
  color: #5d78ff;
}
.pricing-plan-features {
  list-style: none;
  padding-left: 0;
  font-size: 14px;
  line-height: 2.14;
  margin-bottom: 35px;
  color: #303132;
  text-align: left;
}
.pricing-plan-purchase-btn {
  color: #000;
  font-size: 16px;
  font-weight: bold;
  width: 145px;
  height: 45px;
  border-radius: 22.5px;
  transition: all 0.4s;
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.pricing-plan-basic .pricing-plan-purchase-btn {
  background-color: #fe397a;
  color: #fff;
}
.pricing-plan-basic .pricing-plan-purchase-btn:hover {
  box-shadow: 0 3px 0 0 #b7013d;
}
.pricing-plan-basic .pricing-plan-purchase-btn:active {
  transform: translateY(3px);
  box-shadow: none;
}
.pricing-plan-pro .pricing-plan-purchase-btn {
  background-color: #10bb87;
  color: #fff;
}
.pricing-plan-pro .pricing-plan-purchase-btn:hover {
  box-shadow: 0 3px 0 0 #0a7554;
}
.pricing-plan-pro .pricing-plan-purchase-btn:active {
  transform: translateY(3px);
  box-shadow: none;
}
.pricing-plan-enterprise .pricing-plan-purchase-btn {
  background-color: #5d78ff;
  color: #fff;
}
.pricing-plan-enterprise .pricing-plan-purchase-btn:hover {
  box-shadow: 0 3px 0 0 #1138ff;
}
.pricing-plan-enterprise .pricing-plan-purchase-btn:active {
  transform: translateY(3px);
  box-shadow: none;
}

.grid_lines {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  padding: 0rem calc(2.01875rem + 7.9071428571vw);
  margin: 0px;
  list-style-type: none;
}
@media (min-width: 1400px) {
  .grid_lines {
    padding: 0rem 8.9375rem;
  }
}
.grid_lines .grid_line {
  position: relative;
  width: 1px;
  height: 100%;
  display: inline-block;
  background-color: #2e2e2e;
  mix-blend-mode: difference;
}
.grid_lines .grid_line::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 60px;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(0deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
  animation: gridanim 25s linear infinite;
}
.grid_lines .grid_line:nth-child(2)::before {
  animation-delay: 1s;
}
.grid_lines .grid_line:nth-child(4)::before {
  animation-delay: 1s;
}
.grid_lines .grid_line:nth-child(6)::before {
  animation-delay: 1s;
}

@keyframes gridanim {
  0% {
    top: 0%;
  }
  100% {
    top: 100%;
  }
}
.bg-dark-200 .grid_lines .grid_line {
  background-color: rgba(153, 153, 153, 0.1);
}

.box_padding {
  padding-left: calc(1.5625rem + 3.2142857143vw);
  padding-right: calc(1.5625rem + 3.2142857143vw);
}
@media (min-width: 1400px) {
  .box_padding {
    padding-left: 4.375rem;
  }
}
@media (min-width: 1400px) {
  .box_padding {
    padding-right: 4.375rem;
  }
}

.page_header {
  position: relative;
  padding-top: 140px;
  z-index: 1;
}
.page_header .page_header_inner {
  position: relative;
  background-image: url(../img/fondo-servicio.desarrollo-pwa.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.page_header .page_header_inner::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
.page_header .page_header_inner .page_header_content {
  position: relative;
  margin: 0px;
  padding: 60px 0px 60px 0px;
  flex-flow: row wrap;
}
.page_header .page_header_inner .page_header_content > div {
  position: relative;
}
.page_header .page_header_inner .page_header_content .heading {
  position: relative;
  font-size: calc(1.425rem + 1.8vw);
  line-height: calc(1.5rem + 2.5714285714vw);
  font-weight: 500;
  letter-spacing: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #ffffff;
}
@media (min-width: 1400px) {
  .page_header .page_header_inner .page_header_content .heading {
    font-size: 3rem;
  }
}
@media (min-width: 1400px) {
  .page_header .page_header_inner .page_header_content .heading {
    line-height: 3.75rem;
  }
}
.page_header .page_header_inner .breadcrumb {
  padding: 0px 0px 0px 0px;
  margin-bottom: 0;
  list-style: none;
  display: block;
  background: transparent;
}
.page_header .page_header_inner .breadcrumb li {
  position: relative;
  display: inline-block;
  font-size: 1rem;
  line-height: calc(1.2875rem + 0.3857142857vw);
  font-weight: 400;
  color: #ffffff;
}
@media (min-width: 1400px) {
  .page_header .page_header_inner .breadcrumb li {
    line-height: 1.625rem;
  }
}
.page_header .page_header_inner .breadcrumb li:not(:last-child) {
  padding-right: 1.5625rem;
}
.page_header .page_header_inner .breadcrumb li a {
  position: relative;
  padding-right: 0.9375rem;
  transition: all 0.5s ease-in-out;
}
.page_header .page_header_inner .breadcrumb li a:after {
  content: "\f285";
  font-family: "bootstrap-icons";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  font-size: 0.75rem;
}
.page_header .page_header_inner .breadcrumb li a:hover {
  color: #a6a182;
}
.page_header .page_header_inner .breadcrumb li.active {
  color: #a6a182;
}

.section-header {
  margin-bottom: calc(1.625rem + 3.8571428571vw);
}
@media (min-width: 1400px) {
  .section-header {
    margin-bottom: 5rem;
  }
}
.section-header h2, .section-header .h2 {
  font-size: calc(1.425rem + 1.8vw);
  letter-spacing: 0.1875rem;
  text-transform: uppercase;
}
@media (min-width: 1400px) {
  .section-header h2, .section-header .h2 {
    font-size: 3rem;
  }
}
.section-header h6, .section-header .h6 {
  font-size: 0.875rem;
  line-height: calc(1.3rem + 0.5142857143vw);
  letter-spacing: 0.3125rem;
}
@media (min-width: 1400px) {
  .section-header h6, .section-header .h6 {
    line-height: 1.75rem;
  }
}
.section-header h3, .section-header .h3 {
  font-size: calc(1.325rem + 0.7714285714vw);
  line-height: calc(1.425rem + 1.8vw);
  letter-spacing: -0.2px;
}
@media (min-width: 1400px) {
  .section-header h3, .section-header .h3 {
    font-size: 2rem;
  }
}
@media (min-width: 1400px) {
  .section-header h3, .section-header .h3 {
    line-height: 3rem;
  }
}
.section-header.medium h2, .section-header.medium .h2 {
  font-size: calc(1.325rem + 0.7714285714vw);
  font-weight: 500;
  letter-spacing: 0rem;
  text-transform: capitalize;
  margin-bottom: 0.625rem;
}
@media (min-width: 1400px) {
  .section-header.medium h2, .section-header.medium .h2 {
    font-size: 2rem;
  }
}

.has_line {
  position: relative;
  z-index: 1;
}
.has_line::before {
  content: "";
  position: absolute;
  left: calc(50% - 1px);
  bottom: calc(100% + 25px);
  width: 1px;
  height: calc(1.5rem + 2.5714285714vw);
  background: #999999;
}
@media (min-width: 1400px) {
  .has_line::before {
    height: 3.75rem;
  }
}

.has_line_lg {
  position: relative;
  z-index: 1;
}
.has_line_lg::before {
  content: "";
  position: absolute;
  left: calc(50% - 1px);
  bottom: calc(100% - 60px);
  width: 1px;
  height: calc(1.875rem + 6.4285714286vw);
  background: #999999;
}
@media (min-width: 1400px) {
  .has_line_lg::before {
    height: 7.5rem;
  }
}

.widget-title {
  position: relative;
  display: inline-block;
  font-weight: 600;
  letter-spacing: 0;
  text-transform: uppercase;
  margin-bottom: calc(1.3125rem + 0.6428571429vw);
  padding-bottom: 10px;
  overflow: hidden;
}
@media (min-width: 1400px) {
  .widget-title {
    margin-bottom: 1.875rem;
  }
}

.title-line {
  width: 2.5rem;
  height: 0.0625rem;
  background: #a6a182;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
}
.title-line::before {
  content: "";
  position: absolute;
  left: 10px;
  top: 0;
  width: 4px;
  height: 1px;
  background-color: #212529;
  animation: lineAnim 1.5s linear infinite;
}

@keyframes lineAnim {
  0% {
    left: 0;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    left: 100%;
  }
}
.contact_us > div {
  position: relative;
  z-index: 2;
}

.home_contact input {
  margin-bottom: 1.5625rem;
  transition: all 0.5s ease;
}
.home_contact input:last-child {
  margin-bottom: 0;
}
.home_contact .btn_group {
  margin-top: 1.5625rem;
}

.gmapbox .map {
  width: 100%;
  height: calc(5.5rem + 43.7142857143vw);
  background-color: #e6e6e6;
}
@media (min-width: 1400px) {
  .gmapbox .map {
    height: 43.75rem;
  }
}

/*Partner*/
.partner_flex {
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-flow: row wrap;
  margin-top: calc(1.75rem + 5.1428571429vw);
}
@media (min-width: 1400px) {
  .partner_flex {
    margin-top: 6.25rem;
  }
}
.partner_flex .partner_content {
  position: relative;
  transition: all 0.5s ease-in-out;
}
.partner_flex .partner_content img {
  transition: all 0.5s ease;
}
.partner_flex .partner_content img:last-child {
  opacity: 0;
  position: absolute;
  left: 0;
}
.partner_flex .partner_content:hover img:first-child {
  opacity: 0;
}
.partner_flex .partner_content:hover img:last-child {
  opacity: 1;
}

/*Bootstrap nav tab*/
.boot_tab {
  margin-top: 40px;
}
.boot_tab .nav .nav-link.active {
  background: none;
  color: #a6a182;
}
.boot_tab .nav .nav-link {
  border-bottom: 1px dashed #54595f;
  color: #000000;
  font-weight: 600;
  font-size: 1rem;
  line-height: calc(1.2625rem + 0.1285714286vw);
  text-align: left;
  text-transform: uppercase;
  padding: 9px 15px 9px 0;
  border-radius: 0;
}
@media (min-width: 1400px) {
  .boot_tab .nav .nav-link {
    line-height: 1.375rem;
  }
}
.boot_tab .nav .nav-link:last-child {
  border-bottom: none;
}
.boot_tab .nav .nav-link:hover {
  color: #a6a182;
}
.boot_tab .service_inner_large_title {
  margin-bottom: calc(1.375rem + 1.2857142857vw);
}
@media (min-width: 1400px) {
  .boot_tab .service_inner_large_title {
    margin-bottom: 2.5rem;
  }
}
.boot_tab .service_inner_large_title h3, .boot_tab .service_inner_large_title .h3 {
  font-size: calc(1.325rem + 0.7714285714vw);
  line-height: calc(1.3875rem + 1.4142857143vw);
  margin-bottom: 0.9375rem;
  font-weight: 500;
}
@media (min-width: 1400px) {
  .boot_tab .service_inner_large_title h3, .boot_tab .service_inner_large_title .h3 {
    font-size: 2rem;
  }
}
@media (min-width: 1400px) {
  .boot_tab .service_inner_large_title h3, .boot_tab .service_inner_large_title .h3 {
    line-height: 2.625rem;
  }
}
.boot_tab .service_inner_small_title h6, .boot_tab .service_inner_small_title .h6 {
  position: relative;
  width: fit-content;
  margin-bottom: calc(1.3125rem + 0.6428571429vw);
}
@media (min-width: 1400px) {
  .boot_tab .service_inner_small_title h6, .boot_tab .service_inner_small_title .h6 {
    margin-bottom: 1.875rem;
  }
}
.boot_tab .service_inner_small_title h6::after, .boot_tab .service_inner_small_title .h6::after {
  position: absolute;
  content: "";
  background: #a6a182;
  width: 40px;
  height: 1px;
  left: 0;
  bottom: -8px;
}

.highlight_banner {
  padding-top: 150px;
  padding-bottom: 220px;
}

.pd-top-lg {
  padding-top: calc(4.4375rem + 32.7857142857vw);
}
@media (min-width: 1400px) {
  .pd-top-lg {
    padding-top: 33.125rem;
  }
}

.video-block {
  position: relative;
  z-index: 2;
  margin-bottom: calc(-3.75rem - 25.7142857143vw);
}
@media (min-width: 1400px) {
  .video-block {
    margin-bottom: -26.25rem;
  }
}

.video_post {
  display: inline-block;
  width: 100%;
}
.video_post .ytube_video {
  position: relative;
  overflow: hidden;
}
.video_post .ytube_video #ytvideo {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.video_post .ytube_video .ytplay_btn {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 210px;
  height: 210px;
  background: rgba(255, 255, 255, 0.25);
  text-align: center;
  font-size: 5rem;
  color: #ffffff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video_post .ytube_video.play #ytvideo {
  opacity: 1;
}
.video_post .ytube_video.play .post_content {
  opacity: 0;
  visibility: hidden;
}

iframe, object, embed, iframe, object, iframe, video {
  width: 100%;
  height: 100%;
  min-height: calc(5.5rem + 43.7142857143vw);
  border: 0px solid #ffb7aa;
}
@media (min-width: 1400px) {
  iframe, object, embed, iframe, object, iframe, video {
    min-height: 43.75rem;
  }
}

.error-page {
  padding-top: calc(2.75rem + 15.4285714286vw);
  padding-bottom: calc(2.125rem + 9vw);
}
@media (min-width: 1400px) {
  .error-page {
    padding-top: 16.25rem;
  }
}
@media (min-width: 1400px) {
  .error-page {
    padding-bottom: 10rem;
  }
}
.error-page .error-content .heading {
  font-size: calc(1.925rem + 6.9428571429vw);
  font-weight: 700;
  line-height: calc(1.825rem + 5.9142857143vw);
  color: #ffffff;
  text-shadow: 2px 5px #a6a182;
}
@media (min-width: 1400px) {
  .error-page .error-content .heading {
    font-size: 8rem;
  }
}
@media (min-width: 1400px) {
  .error-page .error-content .heading {
    line-height: 7rem;
  }
}
.error-page .error-content h1, .error-page .error-content .h1 {
  color: #a6a182;
  text-transform: uppercase;
  margin-top: 2.1875rem;
  margin-bottom: 0.625rem;
}
.error-page .error-content p {
  font-size: 1.125rem;
  color: #ffffff;
}
.error-page .error-content .search_form {
  position: relative;
  max-width: calc(5.625rem + 45vw);
  margin: 2.1875rem auto;
}
@media (min-width: 1400px) {
  .error-page .error-content .search_form {
    max-width: 45rem;
  }
}
.error-page .error-content .search_form .form-control {
  background-color: #2e2e2e;
  color: #ffffff;
}
.error-page .error-content .search_form .form-control-submit {
  position: absolute;
  right: 1.875rem;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: #b2b2b2;
  padding: 0rem;
  font-size: 1.875rem;
  transition: all 0.5s ease-in-out;
}
.error-page .error-content .search_form:hover .form-control-submit {
  right: 1.25rem;
}

.under-construction-page {
  padding-top: calc(2.75rem + 15.4285714286vw);
  padding-bottom: calc(2.125rem + 9vw);
}
@media (min-width: 1400px) {
  .under-construction-page {
    padding-top: 16.25rem;
  }
}
@media (min-width: 1400px) {
  .under-construction-page {
    padding-bottom: 10rem;
  }
}
.under-construction-page .under-construction-content .heading {
  font-size: calc(1.525rem + 2.8285714286vw);
  font-weight: 700;
  line-height: calc(1.575rem + 3.3428571429vw);
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 2px 5px #a6a182;
  margin-top: 0.625rem;
}
@media (min-width: 1400px) {
  .under-construction-page .under-construction-content .heading {
    font-size: 4rem;
  }
}
@media (min-width: 1400px) {
  .under-construction-page .under-construction-content .heading {
    line-height: 4.5rem;
  }
}
.under-construction-page .under-construction-content h1, .under-construction-page .under-construction-content .h1 {
  color: #a6a182;
  text-transform: uppercase;
  margin-top: 2.1875rem;
  margin-bottom: 0.625rem;
}
.under-construction-page .under-construction-content p {
  font-size: 1.125rem;
  text-transform: uppercase;
  color: #a6a182;
  margin-bottom: 0rem;
}
.under-construction-page .under-construction-content #countdown {
  margin: 2.5rem 0rem;
}
.under-construction-page .under-construction-content #countdown ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  padding: 0rem;
}
.under-construction-page .under-construction-content #countdown ul li {
  position: relative;
  display: inline-block;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.625rem;
  list-style-type: none;
  text-transform: uppercase;
  color: #a6a182;
}
.under-construction-page .under-construction-content #countdown ul li span {
  display: block;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.25rem;
  letter-spacing: 0.1875rem;
  color: #ffffff;
}
.under-construction-page .under-construction-content #countdown ul li:not(:last-child) {
  padding-right: 25px;
  margin-right: 25px;
}
.under-construction-page .under-construction-content #countdown ul li:not(:last-child)::before {
  content: ":";
  position: absolute;
  left: calc(100% - 10px);
  font-size: 2.5rem;
  line-height: 2.5rem;
  color: #ffffff;
  font-weight: 700;
}
.under-construction-page .under-construction-content .search_form {
  position: relative;
  max-width: calc(5.625rem + 45vw);
  margin: 0 auto 2.1875rem;
}
@media (min-width: 1400px) {
  .under-construction-page .under-construction-content .search_form {
    max-width: 45rem;
  }
}
.under-construction-page .under-construction-content .search_form .form-control {
  background-color: #2e2e2e;
  color: #ffffff;
}
.under-construction-page .under-construction-content .search_form .form-control-submit {
  position: absolute;
  right: 1.875rem;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: #b2b2b2;
  padding: 0rem;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.5s ease-in-out;
}
.under-construction-page .under-construction-content .search_form:hover .form-control-submit {
  right: 1.25rem;
}

/* Preloader */
#preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #1b1b1b;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  overflow: hidden;
  -moz-transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

#preloader.hide {
  opacity: 0;
  visibility: hidden;
  width: 0%;
}

#preloader .hide-loader {
  position: absolute;
  left: 15px;
  top: 15px;
  background-color: #2e2e2e;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  z-index: 3;
  -moz-transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

#preloader.hide .hide-loader {
  opacity: 0;
  visibility: hidden;
}

.preloader-inner {
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Spinner Loading */
.preloader-inner .spinner {
  animation: spinner 1s infinite linear;
  border-radius: 50%;
  border: 3px solid #2e2e2e;
  border-top-color: #ffffff;
  width: 60px;
  height: 60px;
  margin: 0 auto 3.5em auto;
}

/* Loading text */
.preloader-inner .loading-text {
  font-weight: 700;
  font-size: 60px;
  line-height: 60px;
  text-align: center;
  user-select: none;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

#preloader.hide .preloader-inner .loading-text {
  opacity: 0;
  visibility: hidden;
}

.preloader-inner .loading-text .characters {
  position: relative;
  display: inline-block;
  color: rgba(255, 255, 255, 0.2);
}

.preloader-inner .loading-text .characters:before {
  content: attr(data-preloader-text);
  position: absolute;
  left: 0;
  top: 0px;
  opacity: 0;
  transform: rotateY(-90deg);
  color: rgb(255, 255, 255);
  animation: characters 4s infinite;
}

.preloader-inner .loading-text .characters:nth-child(2):before {
  animation-delay: 0.2s;
}

.preloader-inner .loading-text .characters:nth-child(3):before {
  animation-delay: 0.4s;
}

.preloader-inner .loading-text .characters:nth-child(4):before {
  animation-delay: 0.6s;
}

.preloader-inner .loading-text .characters:nth-child(5):before {
  animation-delay: 0.8s;
}

.preloader-inner .loading-text .characters:nth-child(6):before {
  animation-delay: 1s;
}

.preloader-inner .loading-text .characters:nth-child(7):before {
  animation-delay: 1.2s;
}

/* Animation of the preloader */
@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}
/* Animation of letters loading from the preloader */
@keyframes characters {
  0%, 75%, 100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }
  25%, 50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}
/* Preloader End */
#mode_switcher {
  position: relative;
  margin-left: 1rem;
  cursor: pointer;
  z-index: 9999;
}
#mode_switcher span {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #ffffff;
  font-size: 18px;
  background-color: #000000;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: rotateSwitcher 5s infinite linear;
}

@keyframes rotateSwitcher {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@media screen and (min-width: 1600px) {
  .box_padding {
    padding-left: calc(1.96875rem + 7.3928571429vw);
    padding-right: calc(1.96875rem + 7.3928571429vw);
  }
}
@media screen and (min-width: 1600px) and (min-width: 1400px) {
  .box_padding {
    padding-left: 8.4375rem;
  }
}
@media screen and (min-width: 1600px) and (min-width: 1400px) {
  .box_padding {
    padding-right: 8.4375rem;
  }
}
@media screen and (min-width: 1600px) {
  .footer {
    padding-bottom: calc(1.96875rem + 7.3928571429vw);
  }
}
@media screen and (min-width: 1600px) and (min-width: 1400px) {
  .footer {
    padding-bottom: 8.4375rem;
  }
}
/*-- min-width: 1400px--*/
@media screen and (min-width: 1400px) {
  .footer .grid_lines {
    padding: 0rem 0.5rem;
  }
}
/*-- max-width: 1400px--*/
@media screen and (max-width: 1400px) {
  .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
    padding-top: calc(2.125rem + 9vw);
    margin-bottom: calc(1.6875rem + 4.5vw);
  }
}
@media screen and (max-width: 1400px) and (min-width: 1400px) {
  .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
    padding-top: 10rem;
  }
}
@media screen and (max-width: 1400px) and (min-width: 1400px) {
  .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
    margin-bottom: 5.625rem;
  }
}
@media screen and (max-width: 1400px) {
  .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4::before, .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4::before {
    top: calc(1.375rem + 1.2857142857vw);
    height: calc(1.75rem + 5.1428571429vw);
  }
}
@media screen and (max-width: 1400px) and (min-width: 1400px) {
  .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4::before, .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4::before {
    top: 2.5rem;
  }
}
@media screen and (max-width: 1400px) and (min-width: 1400px) {
  .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4::before, .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4::before {
    height: 6.25rem;
  }
}
@media screen and (max-width: 1400px) {
  .theme_slider_3 .swiper-button-prev, .theme_slider_3 .swiper-rtl .swiper-button-next {
    left: 50px;
  }
  .theme_slider_3 .swiper-button-next, .theme_slider_3 .swiper-rtl .swiper-button-prev {
    right: 50px;
  }
}
/*-- End max-width: 1400px--*/
/*-- max-width: 1199px--*/
/*-- End max-width: 1199px--*/
/*-- max-width: 991px--*/
@media screen and (max-width: 991px) {
  .icon_box {
    margin-bottom: 2.5rem;
  }
  .funfacts_inner {
    justify-content: flex-start;
    gap: 15px;
    text-align: left;
  }
  .funfacts_inner .fun_img {
    text-align: center;
  }
  .funfact .funfact_content {
    width: 50%;
    margin: 10px 0;
  }
  .about_text h1, .about_text .h1 {
    margin-top: 20px;
  }
  .partner_flex .partner_content {
    margin: 15px 5px;
  }
  .projects .section-desc .text-start,
  .projects .section-desc .text-end {
    text-align: center !important;
  }
}
/*-- End max-width: 991px--*/
/*-- max-width: 767px--*/
@media screen and (max-width: 767px) {
  .theme_slider_3 .swiper-slide .slider .slide_content {
    max-width: 100%;
  }
  .theme_slider_3 .swiper-slide .slider .slide_content .details_link a {
    display: block;
  }
  .theme_slider_3 .swiper-button-prev, .theme_slider_3 .swiper-rtl .swiper-button-next,
  .theme_slider_3 .swiper-button-next, .theme_slider_3 .swiper-rtl .swiper-button-prev {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
  }
  .theme_slider_3:hover .swiper-button-prev, .theme_slider_3:hover .swiper-rtl .swiper-button-next,
  .theme_slider_3:hover .swiper-button-next, .theme_slider_3:hover .swiper-rtl .swiper-button-prev {
    opacity: 1;
    visibility: visible;
    top: 70%;
  }
  .theme_slider_4 .swiper-slide .slider .slide_content .details_link a {
    display: block;
  }
  .page_header .page_header_inner .page_header_content .heading {
    margin-bottom: 1.25rem;
  }
}
/*-- max-width: 600px--*/
@media screen and (max-width: 600px) {
  .project-grid-list .grid-item img.item_image {
    width: 100%;
  }
  .running_projects .left_part .grid-item .works-info,
  .running_projects .right_part .grid-item .works-info {
    right: auto;
    left: 50%;
  }
  .running_projects .left_part .grid-item.active .works-info, .running_projects .left_part .grid-item:hover .works-info,
  .running_projects .right_part .grid-item.active .works-info,
  .running_projects .right_part .grid-item:hover .works-info {
    transform: translateX(-50%) translateY(-50%) perspective(400px) rotateY(0deg);
  }
  .running_projects .right_part .grid-item:first-child {
    margin: 30px 0px;
  }
}
@media screen and (max-width: 575px) {
  .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner {
    padding: 0rem;
  }
  .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .meta {
    font-size: 0.75rem;
    letter-spacing: 0rem;
  }
  .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4, .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 {
    font-size: 1.375rem;
  }
  .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4:before, .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4:before {
    top: 0.625rem;
  }
  .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .details_link {
    font-size: 0.75rem;
    text-transform: capitalize;
  }
  .theme_slider_2 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .details_link a .link_text {
    margin-right: 1.25rem;
  }
  .footer .footer_inner .section-header h2, .footer .footer_inner .section-header .h2 {
    writing-mode: unset;
    transform: rotate(0);
    margin: 0px 0px;
  }
  .footer .footer_inner .communication {
    margin: 1.875rem 0rem;
  }
  .footer .footer_inner .footer_elements.justify-content-end {
    justify-content: flex-start !important;
  }
}
/*-- max-width: 500px--*/
@media screen and (max-width: 500px) {
  .grid-item .label-text h5, .grid-item .label-text .h5,
  .packery .grid-item .label-text h5 {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
}
body.bg-dark .icomision img.light {
  opacity: 1;
  visibility: visible;
  width: 100%;
  height: 100%;
}
body.bg-dark .icomision img.dark {
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
}

body.bg-dark.bg-white .icomision img.light {
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
}
body.bg-dark.bg-white .icomision img.dark {
  opacity: 1;
  visibility: visible;
  width: 100%;
  height: 100%;
}

body.bg-dark.bg-white .bg-dark,
body.bg-dark.bg-white .theme_slider.bg-black, body.bg-white .bg-dark,
body.bg-white .theme_slider.bg-black {
  background: #f8f8f8 !important;
}
body.bg-dark.bg-white a, body.bg-white a {
  color: #1b1b1b;
}
body.bg-dark.bg-white .btn.gray,
body.bg-dark.bg-white .btn.black,
body.bg-dark.bg-white .btn.olive, body.bg-white .btn.gray,
body.bg-white .btn.black,
body.bg-white .btn.olive {
  color: #ffffff;
}
body.bg-dark.bg-white .btn.white, body.bg-white .btn.white {
  background-color: #2e2e2e;
  color: #ffffff;
}
body.bg-dark.bg-white .btn.white:before, body.bg-white .btn.white:before {
  background-image: url(../img/btn-texture-gray.png);
  border-color: #2e2e2e;
}
body.bg-dark.bg-white .btn.white:hover, body.bg-white .btn.white:hover {
  background-color: rgba(46, 46, 46, 0.6);
  color: #ffffff;
}
body.bg-dark.bg-white .grid_lines .grid_line, body.bg-white .grid_lines .grid_line {
  background-color: #e6e6e6;
}
body.bg-dark.bg-white .theme_slider_2 .slide_content_inner h4 a.text-white,
body.bg-dark.bg-white .theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4 a.text-white,
body.bg-dark.bg-white .theme_slider_4 .swiper-slide .slider .slide_content h4 .text-white,
body.bg-dark.bg-white .theme_slider_5 .slider .slide_content h4 .text-white,
body.bg-dark.bg-white .theme_slider_6 .slider .slide_content h4 .text-white,
body.bg-dark.bg-white .icon_box .text-white,
body.bg-dark.bg-white .about_text .text-white,
body.bg-dark.bg-white .icon_box_flex h6,
body.bg-dark.bg-white .section-header .text-white,
body.bg-dark.bg-white .blog .blog_post .blog_content .text-white,
body.bg-dark.bg-white .team-block .text-white,
body.bg-dark.bg-white .team-details .latest-projects .text-white, body.bg-white .theme_slider_2 .slide_content_inner h4 a.text-white, body.bg-white .theme_slider_2 .slide_content_inner .h4 a.text-white,
body.bg-white .theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner h4 a.text-white,
body.bg-white .theme_slider_3 .swiper-slide .slider .slide_content .slide_content_wrapper .slide_content_inner .h4 a.text-white,
body.bg-white .theme_slider_4 .swiper-slide .slider .slide_content h4 .text-white,
body.bg-white .theme_slider_4 .swiper-slide .slider .slide_content .h4 .text-white,
body.bg-white .theme_slider_5 .slider .slide_content h4 .text-white,
body.bg-white .theme_slider_5 .slider .slide_content .h4 .text-white,
body.bg-white .theme_slider_6 .slider .slide_content h4 .text-white,
body.bg-white .theme_slider_6 .slider .slide_content .h4 .text-white,
body.bg-white .icon_box .text-white,
body.bg-white .about_text .text-white,
body.bg-white .icon_box_flex h6,
body.bg-white .icon_box_flex .h6,
body.bg-white .section-header .text-white,
body.bg-white .blog .blog_post .blog_content .text-white,
body.bg-white .team-block .text-white,
body.bg-white .team-details .latest-projects .text-white {
  color: #000000 !important;
}
body.bg-dark.bg-white .icon_box.type-2:hover p,
body.bg-dark.bg-white .blog .blog_post:hover .blog_content .text-white, body.bg-white .icon_box.type-2:hover p,
body.bg-white .blog .blog_post:hover .blog_content .text-white {
  color: #ffffff !important;
}
body.bg-dark.bg-white .header .logo a.light_logo, body.bg-white .header .logo a.light_logo {
  opacity: 0;
  visibility: hidden;
}
body.bg-dark.bg-white .header .logo a.dark_logo, body.bg-white .header .logo a.dark_logo {
  opacity: 1;
  visibility: visible;
}
body.bg-dark.bg-white .header .mainnav ul.main_menu li a, body.bg-white .header .mainnav ul.main_menu li a {
  color: #000000;
}
body.bg-dark.bg-white .header .mainnav ul.main_menu li.current > a, body.bg-dark.bg-white .header .mainnav ul.main_menu li.active > a, body.bg-dark.bg-white .header .mainnav ul.main_menu li:hover > a, body.bg-white .header .mainnav ul.main_menu li.current > a, body.bg-white .header .mainnav ul.main_menu li.active > a, body.bg-white .header .mainnav ul.main_menu li:hover > a {
  color: #a6a182;
}
body.bg-dark.bg-white .header .mainnav ul.main_menu li ul, body.bg-white .header .mainnav ul.main_menu li ul {
  background: #ffffff;
}
body.bg-dark.bg-white .header .mainnav ul.main_menu > li.menu-item-has-children > a::after, body.bg-white .header .mainnav ul.main_menu > li.menu-item-has-children > a::after {
  color: #000000;
}
body.bg-dark.bg-white .header .mainnav ul.main_menu > li.menu-item-has-children.current a::after, body.bg-dark.bg-white .header .mainnav ul.main_menu > li.menu-item-has-children.active a::after, body.bg-dark.bg-white .header .mainnav ul.main_menu > li.menu-item-has-children:hover a::after, body.bg-white .header .mainnav ul.main_menu > li.menu-item-has-children.current a::after, body.bg-white .header .mainnav ul.main_menu > li.menu-item-has-children.active a::after, body.bg-white .header .mainnav ul.main_menu > li.menu-item-has-children:hover a::after {
  color: #a6a182;
}
body.bg-dark.bg-white .header .ma5menu__toggle, body.bg-white .header .ma5menu__toggle {
  color: #54595f;
}
body.bg-dark.bg-white .header .ma5menu__toggle:focus, body.bg-white .header .ma5menu__toggle:focus {
  outline: none;
}
body.bg-dark.bg-white .header .ma5menu__toggle:hover, body.bg-white .header .ma5menu__toggle:hover {
  color: #a6a182;
}
body.bg-dark.bg-white .header .header_search, body.bg-white .header .header_search {
  border-left-color: #54595f;
}
body.bg-dark.bg-white .header .header_search .form-control-submit, body.bg-white .header .header_search .form-control-submit {
  color: #54595f;
}
body.bg-dark.bg-white .header .open_search .search_form .form-control, body.bg-white .header .open_search .search_form .form-control {
  color: #54595f;
}
body.bg-dark.bg-white .header .open_search .search_form .form-control-submit, body.bg-white .header .open_search .search_form .form-control-submit {
  color: #54595f;
}
body.bg-dark.bg-white .aside_open .line, body.bg-white .aside_open .line {
  background-color: #54595f;
}
body.bg-dark.bg-white .aside_info_wrapper, body.bg-white .aside_info_wrapper {
  background: #f8f8f8;
  color: #54595f;
}
body.bg-dark.bg-white .aside_info_wrapper .aside_close, body.bg-white .aside_info_wrapper .aside_close {
  color: #54595f;
}
body.bg-dark.bg-white .aside_info_wrapper .aside_logo a img, body.bg-white .aside_info_wrapper .aside_logo a img {
  max-width: 160px;
  transition: all 0.5s ease-in-out;
}
body.bg-dark.bg-white .aside_info_wrapper .aside_logo a.light_logo, body.bg-white .aside_info_wrapper .aside_logo a.light_logo {
  opacity: 0;
  visibility: hidden;
}
body.bg-dark.bg-white .aside_info_wrapper .aside_logo a.dark_logo, body.bg-white .aside_info_wrapper .aside_logo a.dark_logo {
  opacity: 1;
  visibility: visible;
}
body.bg-dark.bg-white .aside_info_wrapper .aside_info_inner h5, body.bg-white .aside_info_wrapper .aside_info_inner h5, body.bg-white .aside_info_wrapper .aside_info_inner .h5 {
  color: #000000;
}
body.bg-dark.bg-white .aside_info_wrapper .aside_info_inner .social_sites ul li a, body.bg-white .aside_info_wrapper .aside_info_inner .social_sites ul li a {
  color: #ffffff;
  background-color: #2e2e2e;
}
body.bg-dark.bg-white .ma5menu__container, body.bg-white .ma5menu__container {
  background-color: #ffffff;
}
body.bg-dark.bg-white .ma5menu__logo, body.bg-white .ma5menu__logo {
  background-image: url(../../assets/img/logo-dark-lg.svg);
}
body.bg-dark.bg-white .ma5menu__btn--enter, body.bg-white .ma5menu__btn--enter {
  color: #000000;
}
body.bg-dark.bg-white .ma5menu__panel > ul li a, body.bg-dark.bg-white .ma5menu__panel > ul li .ma5menu__category, body.bg-white .ma5menu__panel > ul li a, body.bg-white .ma5menu__panel > ul li .ma5menu__category {
  border-bottom-color: #f8f8f8;
  color: #000000;
}
body.bg-dark.bg-white .ma5menu__leave, body.bg-white .ma5menu__leave {
  background-color: #f8f8f8;
  color: #000000;
}
body.bg-dark.bg-white .ma5menu__btn--leave, body.bg-white .ma5menu__btn--leave {
  color: #000000;
}
body.bg-dark.bg-white .theme_slider_2 .swiper-slide .slider .slide_content .bg-dark-100, body.bg-white .theme_slider_2 .swiper-slide .slider .slide_content .bg-dark-100 {
  background-color: #ffffff;
}
body.bg-dark.bg-white .theme_slider_2 .slide_content_inner h4 a.text-white:hover, body.bg-white .theme_slider_2 .slide_content_inner h4 a.text-white:hover, body.bg-white .theme_slider_2 .slide_content_inner .h4 a.text-white:hover {
  color: #a6a182 !important;
}
body.bg-dark.bg-white .theme_slider_3 .swiper-slide .slider::before, body.bg-white .theme_slider_3 .swiper-slide .slider::before {
  display: none;
}
body.bg-dark.bg-white .theme_slider_3 .swiper-slide .slider .slide_content .details_link a, body.bg-white .theme_slider_3 .swiper-slide .slider .slide_content .details_link a {
  color: #212121;
}
body.bg-dark.bg-white .theme_slider_3 .details_link.swiper-button-prev a .link_icon .line, body.bg-dark.bg-white .theme_slider_3 .details_link.swiper-button-next a .link_icon .line, body.bg-white .theme_slider_3 .details_link.swiper-button-prev a .link_icon .line, body.bg-white .theme_slider_3 .details_link.swiper-button-next a .link_icon .line {
  background-color: #000000;
}
body.bg-dark.bg-white .theme_slider_3 .details_link.swiper-button-prev a .link_icon .circle, body.bg-dark.bg-white .theme_slider_3 .details_link.swiper-button-next a .link_icon .circle, body.bg-white .theme_slider_3 .details_link.swiper-button-prev a .link_icon .circle, body.bg-white .theme_slider_3 .details_link.swiper-button-next a .link_icon .circle {
  border-color: #000000;
}
body.bg-dark.bg-white .theme_slider_3 .details_link.l-dir a .link_icon .dot, body.bg-white .theme_slider_3 .details_link.l-dir a .link_icon .dot {
  border-right: 18px solid #000000;
}
body.bg-dark.bg-white .theme_slider_3 .details_link.r-dir a .link_icon .dot, body.bg-white .theme_slider_3 .details_link.r-dir a .link_icon .dot {
  border-left: 18px solid #000000;
}
body.bg-dark.bg-white .theme_slider_4, body.bg-white .theme_slider_4 {
  background: #f8f8f8;
}
body.bg-dark.bg-white .theme_slider_4 .swiper-slide .slider::before, body.bg-white .theme_slider_4 .swiper-slide .slider::before {
  background-color: transparent;
}
body.bg-dark.bg-white .theme_slider_4 .swiper-slide .slider .slide_content .details_link a, body.bg-white .theme_slider_4 .swiper-slide .slider .slide_content .details_link a {
  color: #000000;
}
body.bg-dark.bg-white .theme_slider_5, body.bg-white .theme_slider_5 {
  background: #f8f8f8;
}
body.bg-dark.bg-white .theme_slider_5 .slider .slide_content .details_link a, body.bg-white .theme_slider_5 .slider .slide_content .details_link a {
  color: #000000;
}
body.bg-dark.bg-white .theme_slider_5 .slider .slide_content .shape .star1, body.bg-white .theme_slider_5 .slider .slide_content .shape .star1 {
  display: none;
}
body.bg-dark.bg-white .theme_slider_5 .slider .slide_content .shape .star1-dark, body.bg-white .theme_slider_5 .slider .slide_content .shape .star1-dark {
  display: block;
}
body.bg-dark.bg-white .theme_slider_5 .slider .slide_content .shape .star2, body.bg-white .theme_slider_5 .slider .slide_content .shape .star2 {
  display: none;
}
body.bg-dark.bg-white .theme_slider_5 .slider .slide_content .shape .star2-dark, body.bg-white .theme_slider_5 .slider .slide_content .shape .star2-dark {
  display: block;
}
body.bg-dark.bg-white .theme_slider_5 .slider .slide_content .shape .star3, body.bg-white .theme_slider_5 .slider .slide_content .shape .star3 {
  display: none;
}
body.bg-dark.bg-white .theme_slider_5 .slider .slide_content .shape .star3-dark, body.bg-white .theme_slider_5 .slider .slide_content .shape .star3-dark {
  display: block;
}
body.bg-dark.bg-white .theme_slider_6, body.bg-white .theme_slider_6 {
  background: #f8f8f8;
}
body.bg-dark.bg-white .theme_slider_6 .slider .slide_content .details_link a, body.bg-white .theme_slider_6 .slider .slide_content .details_link a {
  color: #000000;
}
body.bg-dark.bg-white .about.bg-dark-100,
body.bg-dark.bg-white .services.bg-dark-100,
body.bg-dark.bg-white .divider_bg.bg-dark-100, body.bg-white .about.bg-dark-100,
body.bg-white .services.bg-dark-100,
body.bg-white .divider_bg.bg-dark-100 {
  background-color: #ffffff;
}
body.bg-dark.bg-white .icon_box h6, body.bg-white .icon_box h6, body.bg-white .icon_box .h6 {
  color: #000000;
}
body.bg-dark.bg-white .icon_box img.dark, body.bg-white .icon_box img.dark {
  display: none;
}
body.bg-dark.bg-white .icon_box img.light, body.bg-white .icon_box img.light {
  display: block;
}
body.bg-dark.bg-white .icon_box .text-gray-600, body.bg-white .icon_box .text-gray-600 {
  color: #54595f;
}
body.bg-dark.bg-white .icon_box .arrow_effect a .crossline1, body.bg-white .icon_box .arrow_effect a .crossline1 {
  background-color: #2e2e2e;
}
body.bg-dark.bg-white .icon_box .arrow_effect a .crossline1::before, body.bg-dark.bg-white .icon_box .arrow_effect a .crossline1::after, body.bg-white .icon_box .arrow_effect a .crossline1::before, body.bg-white .icon_box .arrow_effect a .crossline1::after {
  background-color: #2e2e2e;
}
body.bg-dark.bg-white .icon_box.type-2:hover h4, body.bg-white .icon_box.type-2:hover h4, body.bg-white .icon_box.type-2:hover .h4 {
  color: #ffffff;
}
body.bg-dark.bg-white .icon_box.type-2:hover h4 a, body.bg-white .icon_box.type-2:hover h4 a, body.bg-white .icon_box.type-2:hover .h4 a {
  color: #ffffff;
}
body.bg-dark.bg-white .icon_box.type-2:hover .arrow_effect a .crossline1, body.bg-white .icon_box.type-2:hover .arrow_effect a .crossline1 {
  background-color: #ffffff;
}
body.bg-dark.bg-white .icon_box.type-2:hover .arrow_effect a .crossline1::before, body.bg-dark.bg-white .icon_box.type-2:hover .arrow_effect a .crossline1::after, body.bg-white .icon_box.type-2:hover .arrow_effect a .crossline1::before, body.bg-white .icon_box.type-2:hover .arrow_effect a .crossline1::after {
  background-color: #ffffff;
}
body.bg-dark.bg-white .large_font .text-dark-200, body.bg-white .large_font .text-dark-200 {
  color: #f2f2f2;
}
body.bg-dark.bg-white .icon_box_flex .icon_size, body.bg-white .icon_box_flex .icon_size {
  border-color: #e6e6e6;
}
body.bg-dark.bg-white .running_projects, body.bg-white .running_projects {
  background-color: #f8f8f8;
}
body.bg-dark.bg-white .running_projects .works-info h5 a, body.bg-white .running_projects .works-info h5 a, body.bg-white .running_projects .works-info .h5 a {
  color: #ffffff;
}
body.bg-dark.bg-white .running_projects .works-info h5 a:hover, body.bg-white .running_projects .works-info h5 a:hover, body.bg-white .running_projects .works-info .h5 a:hover {
  color: #a6a182;
}
body.bg-dark.bg-white .projects, body.bg-white .projects {
  background-color: #f8f8f8;
}
body.bg-dark.bg-white .portfolio-filters-content .filters-button-group, body.bg-white .portfolio-filters-content .filters-button-group {
  border-bottom-color: #e6e6e6;
}
body.bg-dark.bg-white .portfolio-filters-content .filters-button-group .button, body.bg-white .portfolio-filters-content .filters-button-group .button {
  color: #000000;
}
body.bg-dark.bg-white .portfolio-filters-content .filters-button-group .button.is-checked, body.bg-dark.bg-white .portfolio-filters-content .filters-button-group .button:hover, body.bg-white .portfolio-filters-content .filters-button-group .button.is-checked, body.bg-white .portfolio-filters-content .filters-button-group .button:hover {
  color: #a6a182;
  border-color: #a6a182;
}
body.bg-dark.bg-white .portfolio-filters-content .filters-button-group .button .filter-count, body.bg-white .portfolio-filters-content .filters-button-group .button .filter-count {
  color: #000000;
}
body.bg-dark.bg-white .packery .grid-item .works-info, body.bg-white .packery .grid-item .works-info {
  background-color: #f8f8f8;
}
body.bg-dark.bg-white .packery .grid-item .works-info .label-text h6 a, body.bg-white .packery .grid-item .works-info .label-text h6 a, body.bg-white .packery .grid-item .works-info .label-text .h6 a {
  color: #54595f;
}
body.bg-dark.bg-white .project-details.bg-dark-200, body.bg-white .project-details.bg-dark-200 {
  background: #ffffff;
}
body.bg-dark.bg-white .project-list-view.bg-dark-200, body.bg-white .project-list-view.bg-dark-200 {
  background: #ffffff;
}
body.bg-dark.bg-white .project-list-view .section-header h2, body.bg-white .project-list-view .section-header h2, body.bg-white .project-list-view .section-header .h2 {
  color: #000000;
}
body.bg-dark.bg-white .project-list-view .project-list li::before, body.bg-white .project-list-view .project-list li::before {
  color: #999999;
}
body.bg-dark.bg-white .project-list-view .project-list li a::before, body.bg-white .project-list-view .project-list li a::before {
  background-color: #999999;
}
body.bg-dark.bg-white .page_header .page_header_inner .breadcrumb li a, body.bg-white .page_header .page_header_inner .breadcrumb li a {
  color: #ffffff;
}
body.bg-dark.bg-white .page_header .page_header_inner .breadcrumb li a:hover, body.bg-white .page_header .page_header_inner .breadcrumb li a:hover {
  color: #a6a182;
}
body.bg-dark.bg-white .page_header .page_header_inner .breadcrumb li.active, body.bg-white .page_header .page_header_inner .breadcrumb li.active {
  color: #a6a182;
}
body.bg-dark.bg-white .testimonial.bg-black, body.bg-white .testimonial.bg-black {
  background-color: #f8f8f8 !important;
}
body.bg-dark.bg-white .blog .large_font .text-dark-200, body.bg-white .blog .large_font .text-dark-200 {
  color: #f2f2f2;
}
body.bg-dark.bg-white .blog.bg-dark-100, body.bg-dark.bg-white .blog.bg-black, body.bg-white .blog.bg-dark-100, body.bg-white .blog.bg-black {
  background-color: #f8f8f8 !important;
}
body.bg-dark.bg-white .blog .blog_post .blog_content .meta .text-olive, body.bg-white .blog .blog_post .blog_content .meta .text-olive {
  color: #54595f;
}
body.bg-dark.bg-white .blog .blog_post .blog_content p, body.bg-white .blog .blog_post .blog_content p {
  color: #000000;
}
body.bg-dark.bg-white .blog .blog_post:hover .blog_content .meta .text-olive, body.bg-white .blog .blog_post:hover .blog_content .meta .text-olive {
  color: #a6a182;
}
body.bg-dark.bg-white .blog .blog_post:hover .blog_content p, body.bg-white .blog .blog_post:hover .blog_content p {
  color: #ffffff;
}
body.bg-dark.bg-white .details_link a, body.bg-white .details_link a {
  color: #a6a182;
}
body.bg-dark.bg-white .contact_us.bg-dark-200, body.bg-white .contact_us.bg-dark-200 {
  background: #ffffff;
}
body.bg-dark.bg-white .mission_bottom_part .bg-dark-200, body.bg-white .mission_bottom_part .bg-dark-200 {
  background: #ffffff;
}
body.bg-dark.bg-white select, body.bg-dark.bg-white input[type=text], body.bg-dark.bg-white input[type=email], body.bg-dark.bg-white input[type=url], body.bg-dark.bg-white input[type=password], body.bg-dark.bg-white input[type=search], body.bg-dark.bg-white input[type=number], body.bg-dark.bg-white input[type=tel], body.bg-dark.bg-white input[type=range], body.bg-dark.bg-white input[type=date], body.bg-dark.bg-white input[type=month], body.bg-dark.bg-white input[type=week], body.bg-dark.bg-white input[type=time], body.bg-dark.bg-white input[type=datetime], body.bg-dark.bg-white input[type=datetime-local], body.bg-dark.bg-white input[type=color],
body.bg-dark.bg-white textarea.form-control, body.bg-white select, body.bg-white input[type=text], body.bg-white input[type=email], body.bg-white input[type=url], body.bg-white input[type=password], body.bg-white input[type=search], body.bg-white input[type=number], body.bg-white input[type=tel], body.bg-white input[type=range], body.bg-white input[type=date], body.bg-white input[type=month], body.bg-white input[type=week], body.bg-white input[type=time], body.bg-white input[type=datetime], body.bg-white input[type=datetime-local], body.bg-white input[type=color],
body.bg-white textarea.form-control {
  background-color: #f8f8f8;
  border: 1px solid #f2f2f2;
  color: #000000;
}
body.bg-dark.bg-white .gmap, body.bg-white .gmap {
  background-color: #f2f2f2;
}
body.bg-dark.bg-white .footer.bg-dark-100, body.bg-dark.bg-white .footer.bg-dark-200, body.bg-white .footer.bg-dark-100, body.bg-white .footer.bg-dark-200 {
  background: #ffffff;
}
body.bg-dark.bg-white .mission_top_part .section-header .text-gray-600, body.bg-white .mission_top_part .section-header .text-gray-600 {
  color: #212121;
}
body.bg-dark.bg-white .highlight_banner, body.bg-white .highlight_banner {
  background-color: #f8f8f8;
}
body.bg-dark.bg-white .team-block .text-olive, body.bg-white .team-block .text-olive {
  color: #54595f;
}
body.bg-dark.bg-white .project-details.bg-dark, body.bg-white .project-details.bg-dark {
  background-color: #ffffff !important;
}
body.bg-dark.bg-white .project-details .post-header .post-title,
body.bg-dark.bg-white .project-details .fulltext, body.bg-white .project-details .post-header .post-title,
body.bg-white .project-details .fulltext {
  color: #000000;
}
body.bg-dark.bg-white .project-details .post-footer, body.bg-white .project-details .post-footer {
  border-top-color: #e6e6e6;
}
body.bg-dark.bg-white .project-details .post-share ul.share-list li:first-child, body.bg-white .project-details .post-share ul.share-list li:first-child {
  color: #1b1b1b;
}
body.bg-dark.bg-white .title-line::before, body.bg-white .title-line::before {
  background-color: #ffffff;
}
body.bg-dark.bg-white .project-information, body.bg-white .project-information {
  color: #000000;
}
body.bg-dark.bg-white .project-information ul li .value, body.bg-white .project-information ul li .value {
  color: #1b1b1b;
}
body.bg-dark.bg-white .project-information .project-share ul li a, body.bg-white .project-information .project-share ul li a {
  background-color: #e6e6e6;
  color: #54595f;
}
body.bg-dark.bg-white .related-posts .widget-title, body.bg-white .related-posts .widget-title {
  color: #000000;
}
body.bg-dark.bg-white .related-posts .grid-item .label-text h5,
body.bg-dark.bg-white .related-posts .grid-item .label-text h5 a, body.bg-white .related-posts .grid-item .label-text h5, body.bg-white .related-posts .grid-item .label-text .h5,
body.bg-white .related-posts .grid-item .label-text h5 a,
body.bg-white .related-posts .grid-item .label-text .h5 a {
  color: #000000;
}
body.bg-dark.bg-white .project-details h6.widget-title, body.bg-dark.bg-white .project-details .widget-title.h6,
body.bg-dark.bg-white .comments-area .comments-title, body.bg-dark.bg-white .comment-respond .comment-reply-title,
body.bg-dark.bg-white .comments-area ul.comment-list .commenter-block .comment-content .comment-author-name, body.bg-white .project-details h6.widget-title, body.bg-white .project-details .widget-title.h6,
body.bg-white .comments-area .comments-title, body.bg-white .comment-respond .comment-reply-title,
body.bg-white .comments-area ul.comment-list .commenter-block .comment-content .comment-author-name {
  color: #000000;
}
body.bg-dark.bg-white .comment-respond textarea.form-control,
body.bg-dark.bg-white .comment-respond select, body.bg-dark.bg-white .comment-respond input[type=text], body.bg-dark.bg-white .comment-respond input[type=email], body.bg-dark.bg-white .comment-respond input[type=url], body.bg-dark.bg-white .comment-respond input[type=password], body.bg-dark.bg-white .comment-respond input[type=search], body.bg-dark.bg-white .comment-respond input[type=number], body.bg-dark.bg-white .comment-respond input[type=tel], body.bg-dark.bg-white .comment-respond input[type=range], body.bg-dark.bg-white .comment-respond input[type=date], body.bg-dark.bg-white .comment-respond input[type=month], body.bg-dark.bg-white .comment-respond input[type=week], body.bg-dark.bg-white .comment-respond input[type=time], body.bg-dark.bg-white .comment-respond input[type=datetime], body.bg-dark.bg-white .comment-respond input[type=datetime-local], body.bg-dark.bg-white .comment-respond input[type=color], body.bg-white .comment-respond textarea.form-control,
body.bg-white .comment-respond select, body.bg-white .comment-respond input[type=text], body.bg-white .comment-respond input[type=email], body.bg-white .comment-respond input[type=url], body.bg-white .comment-respond input[type=password], body.bg-white .comment-respond input[type=search], body.bg-white .comment-respond input[type=number], body.bg-white .comment-respond input[type=tel], body.bg-white .comment-respond input[type=range], body.bg-white .comment-respond input[type=date], body.bg-white .comment-respond input[type=month], body.bg-white .comment-respond input[type=week], body.bg-white .comment-respond input[type=time], body.bg-white .comment-respond input[type=datetime], body.bg-white .comment-respond input[type=datetime-local], body.bg-white .comment-respond input[type=color] {
  border-color: #e6e6e6;
  color: #000000;
}
body.bg-dark.bg-white .sidebar, body.bg-white .sidebar {
  background-color: #f8f8f8;
}
body.bg-dark.bg-white .sidebar a, body.bg-white .sidebar a {
  color: #000000;
}
body.bg-dark.bg-white .sidebar a:hover, body.bg-white .sidebar a:hover {
  color: #a6a182;
}
body.bg-dark.bg-white .sidebar .widget ul li, body.bg-dark.bg-white .sidebar .widget ol li, body.bg-white .sidebar .widget ul li, body.bg-white .sidebar .widget ol li {
  border-bottom-color: #e6e6e6;
}
body.bg-dark.bg-white .sidebar .widget_search .wp-block-search .wp-block-search__input, body.bg-white .sidebar .widget_search .wp-block-search .wp-block-search__input {
  border-color: #e6e6e6;
}
body.bg-dark.bg-white .widget h2, body.bg-dark.bg-white .widget .h2, body.bg-dark.bg-white .widget .widget-title, body.bg-white .widget h2, body.bg-white .widget .h2, body.bg-white .widget .widget-title {
  color: #000000;
}
body.bg-dark.bg-white .shop_page, body.bg-dark.bg-white .shopping_cart, body.bg-dark.bg-white .product_view, body.bg-dark.bg-white .product_details_section, body.bg-dark.bg-white .related_products, body.bg-white .shop_page, body.bg-white .shopping_cart, body.bg-white .product_view, body.bg-white .product_details_section, body.bg-white .related_products {
  background: #ffffff;
}
body.bg-dark.bg-white .shop_sidebar .sidebar_search .search_form .form-control, body.bg-white .shop_sidebar .sidebar_search .search_form .form-control {
  border-color: #e6e6e6;
  background-color: #ffffff;
}
body.bg-dark.bg-white .shop_sidebar .sidebar_search .search_form .form-control-submit, body.bg-white .shop_sidebar .sidebar_search .search_form .form-control-submit {
  background: #a6a182;
  border-color: #a6a182;
}
body.bg-dark.bg-white .shop_sidebar .sidebar_price_filter .range-bar .ui-slider-handle,
body.bg-dark.bg-white .shop_sidebar .sidebar_price_filter .range-bar .ui-slider-range, body.bg-white .shop_sidebar .sidebar_price_filter .range-bar .ui-slider-handle,
body.bg-white .shop_sidebar .sidebar_price_filter .range-bar .ui-slider-range {
  background: #1b1b1b;
}
body.bg-dark.bg-white .shop_sidebar .sidebar_price_filter .range-value input[type=text], body.bg-white .shop_sidebar .sidebar_price_filter .range-value input[type=text] {
  color: #1b1b1b;
}
body.bg-dark.bg-white .shop_sidebar .form-check label, body.bg-white .shop_sidebar .form-check label {
  color: #1b1b1b;
}
body.bg-dark.bg-white .shop_sidebar .sidebar_popular_products ul li, body.bg-white .shop_sidebar .sidebar_popular_products ul li {
  border-bottom: 1px solid #e6e6e6;
}
body.bg-dark.bg-white .shop_sidebar .sidebar_popular_products ul li .popular_product_content .product_title a, body.bg-white .shop_sidebar .sidebar_popular_products ul li .popular_product_content .product_title a {
  color: #1b1b1b;
}
body.bg-dark.bg-white .shop_sidebar .sidebar_popular_products ul li .popular_product_content .product_title a:hover, body.bg-white .shop_sidebar .sidebar_popular_products ul li .popular_product_content .product_title a:hover {
  color: #a6a182;
}
body.bg-dark.bg-white .shop_sidebar .sidebar_popular_products ul li .popular_product_content .product_price, body.bg-white .shop_sidebar .sidebar_popular_products ul li .popular_product_content .product_price {
  color: #2e2e2e;
}
body.bg-dark.bg-white .shop_filtering_method .view_type_wrapper .view_type a, body.bg-white .shop_filtering_method .view_type_wrapper .view_type a {
  color: #1b1b1b;
}
body.bg-dark.bg-white .shop_filtering_method .view_type_wrapper .view_type a.active, body.bg-white .shop_filtering_method .view_type_wrapper .view_type a.active {
  color: #a6a182;
}
body.bg-dark.bg-white .shop_filtering_method .select2-container .select2-selection--single, body.bg-white .shop_filtering_method .select2-container .select2-selection--single {
  border-color: #e6e6e6;
}
body.bg-dark.bg-white .product_view_grid .product_item .product_thumb .product_imagebox, body.bg-white .product_view_grid .product_item .product_thumb .product_imagebox {
  background: #f8f8f8;
}
body.bg-dark.bg-white .product_view_grid .product_item .product_thumb .product_item_inner .label_text .product_item_name a, body.bg-white .product_view_grid .product_item .product_thumb .product_item_inner .label_text .product_item_name a {
  color: #212529;
}
body.bg-dark.bg-white .product_view_grid .product_item .product_thumb .product_item_inner .label_text .product_item_name a:hover, body.bg-white .product_view_grid .product_item .product_thumb .product_item_inner .label_text .product_item_name a:hover {
  color: #a6a182;
}
body.bg-dark.bg-white .product_view_list .product_item, body.bg-white .product_view_list .product_item {
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
}
body.bg-dark.bg-white .product_view_list .product_item .product_thumb .product_imagebox, body.bg-white .product_view_list .product_item .product_thumb .product_imagebox {
  background: #f8f8f8;
}
body.bg-dark.bg-white .product_view_list .product_item .product_thumb .product_item_inner .label_text .product_item_name a, body.bg-white .product_view_list .product_item .product_thumb .product_item_inner .label_text .product_item_name a {
  color: #1b1b1b;
}
body.bg-dark.bg-white .product_view_list .product_item .product_thumb .product_item_inner .label_text .product_item_name a:hover, body.bg-white .product_view_list .product_item .product_thumb .product_item_inner .label_text .product_item_name a:hover {
  color: #a6a182;
}
body.bg-dark.bg-white .product_details_tab .nav li a, body.bg-white .product_details_tab .nav li a {
  color: #1b1b1b;
}
body.bg-dark.bg-white .product_details_tab .nav li a.active, body.bg-white .product_details_tab .nav li a.active {
  color: #a6a182;
}
body.bg-dark.bg-white .cart_button .button, body.bg-white .cart_button .button {
  color: #ffffff;
}
body.bg-dark.bg-white .cart_button .button:after, body.bg-white .cart_button .button:after {
  background: #1b1b1b;
}
body.bg-dark.bg-white .pagination li a:hover, body.bg-dark.bg-white .pagination li a.current, body.bg-white .pagination li a:hover, body.bg-white .pagination li a.current {
  background-color: #a6a182;
  color: #ffffff;
}
body.bg-dark.bg-white .shipping_form .have_coupon, body.bg-white .shipping_form .have_coupon {
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
}
body.bg-dark.bg-white .shipping_form .widget-title,
body.bg-dark.bg-white .shipping_form label, body.bg-white .shipping_form .widget-title,
body.bg-white .shipping_form label {
  color: #1b1b1b;
}
body.bg-dark.bg-white .shipping_form .select2-container--default .select2-selection--multiple, body.bg-dark.bg-white .shipping_form .select2-container--default .select2-selection--single, body.bg-white .shipping_form .select2-container--default .select2-selection--multiple, body.bg-white .shipping_form .select2-container--default .select2-selection--single {
  background: #f8f8f8;
  border-color: #f2f2f2;
}
body.bg-dark.bg-white .place_order label.radio_circle .checkmark:after, body.bg-white .place_order label.radio_circle .checkmark:after {
  background: #1b1b1b;
}
body.bg-dark.bg-white .place_order ul li, body.bg-white .place_order ul li {
  border-bottom: 1px solid #e6e6e6;
}
body.bg-dark.bg-white .place_order ul li span.text, body.bg-white .place_order ul li span.text {
  color: #1b1b1b;
}
body.bg-dark.bg-white .place_order ul li.totalvalue, body.bg-white .place_order ul li.totalvalue {
  color: #1b1b1b;
}
body.bg-dark.bg-white .place_order ul li .paymentmethod label.radio_circle, body.bg-white .place_order ul li .paymentmethod label.radio_circle {
  color: #1b1b1b;
}
body.bg-dark.bg-white .place_order ul li .paymentmethod span.text, body.bg-white .place_order ul li .paymentmethod span.text {
  color: #1b1b1b;
}
body.bg-dark.bg-white .place_order .btn.white, body.bg-white .place_order .btn.white {
  background: #000000;
  color: #ffffff;
}
body.bg-dark.bg-white .place_order .btn.white:hover, body.bg-white .place_order .btn.white:hover {
  background: #a6a182;
}
body.bg-dark.bg-white .cart_table .cart_content td, body.bg-white .cart_table .cart_content td {
  color: #1b1b1b;
}
body.bg-dark.bg-white .cart_table .cart_content .cart_quantity .product_quantity_inner, body.bg-white .cart_table .cart_content .cart_quantity .product_quantity_inner {
  border-color: #e6e6e6;
}
body.bg-dark.bg-white .cart_table .cart_content .cart_quantity .product_quantity_inner .product_quantity_subtract,
body.bg-dark.bg-white .cart_table .cart_content .cart_quantity .product_quantity_inner .product_quantity_add,
body.bg-dark.bg-white .cart_table .cart_content .cart_quantity .product_quantity_inner input, body.bg-white .cart_table .cart_content .cart_quantity .product_quantity_inner .product_quantity_subtract,
body.bg-white .cart_table .cart_content .cart_quantity .product_quantity_inner .product_quantity_add,
body.bg-white .cart_table .cart_content .cart_quantity .product_quantity_inner input {
  color: #1b1b1b;
}
body.bg-dark.bg-white .cart_table .cart_header th, body.bg-white .cart_table .cart_header th {
  color: #000000;
}
body.bg-dark.bg-white .cart_table td, body.bg-dark.bg-white .cart_table th, body.bg-white .cart_table td, body.bg-white .cart_table th {
  border-bottom-color: #e6e6e6;
}
body.bg-dark.bg-white .couponcart .btn.white, body.bg-white .couponcart .btn.white {
  background: #000000;
  color: #ffffff;
}
body.bg-dark.bg-white .couponcart .btn.white:hover, body.bg-white .couponcart .btn.white:hover {
  background: #a6a182;
}
body.bg-dark.bg-white .grand_total, body.bg-white .grand_total {
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
}
body.bg-dark.bg-white .grand_total ul li, body.bg-white .grand_total ul li {
  color: #1b1b1b;
  border-color: #e6e6e6;
}
body.bg-dark.bg-white .grand_total ul li span.text, body.bg-white .grand_total ul li span.text {
  color: #1b1b1b;
}
body.bg-dark.bg-white .grand_total .btn.white, body.bg-white .grand_total .btn.white {
  background: #000000;
  color: #ffffff;
}
body.bg-dark.bg-white .grand_total .btn.white:hover, body.bg-white .grand_total .btn.white:hover {
  background: #a6a182;
}
body.bg-dark.bg-white .product_right .product_info .product_title h2, body.bg-white .product_right .product_info .product_title h2, body.bg-white .product_right .product_info .product_title .h2 {
  color: #1b1b1b;
}
body.bg-dark.bg-white .product_right .product_price, body.bg-white .product_right .product_price {
  color: #1b1b1b;
}
body.bg-dark.bg-white .product_right .product_view_bottom .product_quantity .pproduct_quantity_label, body.bg-white .product_right .product_view_bottom .product_quantity .pproduct_quantity_label {
  color: #1b1b1b;
}
body.bg-dark.bg-white .product_right .product_view_bottom .product_quantity .product_quantity_inner, body.bg-white .product_right .product_view_bottom .product_quantity .product_quantity_inner {
  border-color: #e6e6e6;
}
body.bg-dark.bg-white .product_right .product_view_bottom .product_quantity .product_quantity_inner input,
body.bg-dark.bg-white .product_right .product_view_bottom .product_quantity .product_quantity_inner .product_quantity_subtract,
body.bg-dark.bg-white .product_right .product_view_bottom .product_quantity .product_quantity_inner .product_quantity_add, body.bg-white .product_right .product_view_bottom .product_quantity .product_quantity_inner input,
body.bg-white .product_right .product_view_bottom .product_quantity .product_quantity_inner .product_quantity_subtract,
body.bg-white .product_right .product_view_bottom .product_quantity .product_quantity_inner .product_quantity_add {
  color: #1b1b1b;
}
body.bg-dark.bg-white .product_right .product_view_bottom_credential ul li span, body.bg-white .product_right .product_view_bottom_credential ul li span {
  color: #1b1b1b;
}
body.bg-dark.bg-white .product_right .product_social_share ul li, body.bg-white .product_right .product_social_share ul li {
  color: #1b1b1b;
}
body.bg-dark.bg-white .product_right .product_social_share ul li a, body.bg-white .product_right .product_social_share ul li a {
  background: #f2f2f2;
  color: #1b1b1b;
}
body.bg-dark.bg-white .product_details_tab .nav, body.bg-white .product_details_tab .nav {
  border-bottom-color: #e6e6e6;
}
body.bg-dark.bg-white .product_details_tab .product_additional_info ul li span, body.bg-white .product_details_tab .product_additional_info ul li span {
  color: #1b1b1b;
}
body.bg-dark.bg-white .review_sec ul.review_area .reviewer_div .review_block .reviewer_name,
body.bg-dark.bg-white .review_sec ul.review_area .reviewer_div .review_block .reviewer_review, body.bg-white .review_sec ul.review_area .reviewer_div .review_block .reviewer_name,
body.bg-white .review_sec ul.review_area .reviewer_div .review_block .reviewer_review {
  color: #1b1b1b;
}
body.bg-dark.bg-white .review_form .review_form_heading_wrapper .review_form_title, body.bg-white .review_form .review_form_heading_wrapper .review_form_title {
  color: #1b1b1b;
}
body.bg-dark.bg-white a.page-numbers, body.bg-white a.page-numbers {
  color: #ffffff;
}
body.bg-dark.bg-white .related_products .widget-title, body.bg-white .related_products .widget-title {
  color: #1b1b1b;
}
body.bg-dark.bg-white .page_header.in-service .page_header_inner .page_header_content .heading, body.bg-white .page_header.in-service .page_header_inner .page_header_content .heading {
  color: #1b1b1b;
}
body.bg-dark.bg-white .header-layer-bg, body.bg-white .header-layer-bg {
  background-image: url("../img/bg/bg-2-light.jpg");
}
body.bg-dark.bg-white .testimonial.in-service, body.bg-white .testimonial.in-service {
  background: transparent;
}
body.bg-dark.bg-white .partners.in-service.bg-dark-200, body.bg-white .partners.in-service.bg-dark-200 {
  background-color: #ffffff;
}
body.bg-dark.bg-white .team-details .team-member-information .name, body.bg-white .team-details .team-member-information .name {
  color: #1b1b1b;
}
body.bg-dark.bg-white .team-details .team-member-information h6.widget-title, body.bg-white .team-details .team-member-information h6.widget-title, body.bg-white .team-details .team-member-information .widget-title.h6 {
  color: #1b1b1b;
}
body.bg-dark.bg-white .team-details .team-member-information ul.point_order li, body.bg-white .team-details .team-member-information ul.point_order li {
  color: #1b1b1b;
}
body.bg-dark.bg-white .error-page .error-content .heading, body.bg-white .error-page .error-content .heading {
  color: #000000;
}
body.bg-dark.bg-white .error-page .error-content p, body.bg-white .error-page .error-content p {
  color: #000000;
}
body.bg-dark.bg-white .error-page .error-content .search_form .form-control, body.bg-white .error-page .error-content .search_form .form-control {
  background-color: #e6e6e6;
  color: #000000;
}
body.bg-dark.bg-white .error-page .error-content .search_form .form-control-submit, body.bg-white .error-page .error-content .search_form .form-control-submit {
  color: #000000;
}
body.bg-dark.bg-white .under-construction-page .under-construction-content .heading, body.bg-white .under-construction-page .under-construction-content .heading {
  color: #000000;
}
body.bg-dark.bg-white .under-construction-page .under-construction-content p, body.bg-white .under-construction-page .under-construction-content p {
  color: #000000;
}
body.bg-dark.bg-white .under-construction-page .under-construction-content #countdown ul li span, body.bg-white .under-construction-page .under-construction-content #countdown ul li span {
  color: #000000;
}
body.bg-dark.bg-white .under-construction-page .under-construction-content #countdown ul li:not(:last-child)::before, body.bg-white .under-construction-page .under-construction-content #countdown ul li:not(:last-child)::before {
  color: #000000;
}
body.bg-dark.bg-white .under-construction-page .under-construction-content .search_form .form-control, body.bg-white .under-construction-page .under-construction-content .search_form .form-control {
  background-color: #e6e6e6;
  color: #000000;
}
body.bg-dark.bg-white .under-construction-page .under-construction-content .search_form .form-control-submit, body.bg-white .under-construction-page .under-construction-content .search_form .form-control-submit {
  color: #000000;
}